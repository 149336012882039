import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Notification.module.scss';
import { Notification as NotificationType, NotificationStatus, removeNotification } from '../../../store/notifications.ts';
import CloseSVG from '../../../public/media/close.svg';

type NotificationProps = Pick<NotificationType, 'text' | 'id' | 'status'>;
const Notification = (
  {
    status = NotificationStatus.ERROR,
    text: {
      title,
      body,
    },
    id,
  }: NotificationProps,
) => {
  const { t } = useTranslation();

  return (
    <div className={classNames(styles.notification, {
      [styles.error]: status === NotificationStatus.ERROR,
      [styles.success]: status === NotificationStatus.SUCCESS,
    })}
    >
      <p className={styles.notificationText}>
        <span className={styles.title}>{title}</span>
        <span className={styles.body}>{body}</span>
      </p>
      <button
        aria-label={t('Dismiss notification')}
        type='button'
        className={styles.closeButton}
        onClick={() => removeNotification(id)}
      >
        <svg>
          <use
            xlinkHref={`${CloseSVG}#closeSVG`}
            href={`${CloseSVG}#closeSVG`}
          />
        </svg>
      </button>
    </div>
  );
};

export default Notification;
