import { Checkbox, FormControlLabel } from '@mui/material';
import { ChangeEvent } from 'react';
import classNames from 'classnames';
import CheckCheckedSVG from '../../../public/media/check-checked.svg';
import CheckEmptySVG from '../../../public/media/check-empty.svg';
import CheckIndeterminateSVG from '../../../public/media/check-indeterminate.svg';

import styles from './CheckboxItem.module.scss';

type CheckboxItemProps = {
  iconSize?: number,
  fontSize?: number,
  value: boolean,
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void,
  label?: string,
  name?: string,
  indeterminate?: boolean,
  labelClassName?: string,
};
const CheckboxItem = ({
  value, onChange, label, iconSize = 16, fontSize = 14, name, indeterminate, labelClassName,
}: CheckboxItemProps) => (
  <FormControlLabel
    className={classNames(styles.checkbox, labelClassName)}
    sx={{
      fontSize,
    }}
    control={(
      <Checkbox
        checked={value}
        name={name}
        onChange={onChange}
        indeterminate={indeterminate}
        icon={(
          <svg
            height={iconSize}
            width={iconSize}
          >
            <use
              height={iconSize}
              width={iconSize}
              className={styles.icon}
              xlinkHref={`${CheckEmptySVG}#checkEmptySVG`}
              href={`${CheckEmptySVG}#checkEmptySVG`}
            />
          </svg>
        )}
        checkedIcon={(
          <svg
            height={iconSize}
            width={iconSize}
          >
            <use
              height={iconSize}
              width={iconSize}
              className={styles.icon}
              xlinkHref={`${CheckCheckedSVG}#checkCheckedSVG`}
              href={`${CheckCheckedSVG}#checkCheckedSVG`}
            />
          </svg>
        )}
        indeterminateIcon={(
          <svg
            height={iconSize}
            width={iconSize}
          >
            <use
              height={iconSize}
              width={iconSize}
              className={styles.icon}
              xlinkHref={`${CheckIndeterminateSVG}#checkIndeterminateSVG`}
              href={`${CheckIndeterminateSVG}#checkIndeterminateSVG`}
            />
          </svg>
      )}
      />
    )}
    label={label}
  />
);

export default CheckboxItem;
