import { useAtomValue } from 'jotai';
import { notificationsAtom } from '../../store/notifications.ts';
import Portal from '../Portal.tsx';
import Notification from '../UIKit/Notification/Notification.tsx';
import styles from './Notifications.module.scss';

const Notifications = () => {
  const { notifications } = useAtomValue(notificationsAtom);

  return (
    <Portal className={styles.notificationsPortalRoot}>
      {notifications.map((notification) => (
        <Notification
          {...notification}
          key={notification.id}
        />
      ))}
    </Portal>
  );
};

export default Notifications;
