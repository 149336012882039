import React, { useEffect, useState } from 'react';
import useSWR from 'swr';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useOutletContext, useParams } from 'react-router-dom';
import { TFunction } from 'i18next';
import { FormikValues } from 'formik';
import { useAtomValue } from 'jotai';

import ConstraintForm from './ConstraintForm';
import Drawer from '../../../UIKit/Drawer/Drawer';
import EntityBaseModal from '../../../EntityBaseModal/EntityBaseModal';
import Loader from '../../../Loader/Loader';
import PopoverOptions from '../../../UIKit/PopoverOptions/PopoverOptions';
import StatusLabel from '../../../UIKit/StatusLabel/StatusLabel';

import apiClient from '../../../../apiClient';
import {
  ConstraintResource, GateResource, QualityGateContextType,
} from '../types';
import { PopoverPlacement } from '../../../UIKit/Popover/Popover';
import { notify } from '../../../../store/notifications';
import { DEFAULT_DATE_FORMAT } from '../../../../constants';
import { userAtom } from '../../../../store/auth';
import { ProjectPermissions } from '../../Login/user.props';

import DeleteSVG from '../../../../public/media/delete-icon.svg';
import EditSVG from '../../../../public/media/edit-icon.svg';
import EmptyConstraintsSVG from '../../../../public/media/empty-constraints.svg';

import styles from './Constraints.module.scss';

const COLUMN_NAMES = ['#', 'Deliverable', 'Constraint', 'Impact', 'Mitigation actions', 'Responsible', 'Due date', 'Status'];

const createConstraint = async (projectId: string, gateId: string, body: Record<string, string>) => {
  try {
    const response = await apiClient
      .post<{ data: ConstraintResource, errors?: Record<string, string[]> }>(`projects/${projectId}/quality-gates/${gateId}/constraints`, {
      body: JSON.stringify(body),
    });
    return response;
  } catch (error) {
    console.error(error);
    notify();
  }
};

const deleteConstraint = async (projectId: string, gateId: string, constraintId: number) => {
  try {
    const response = await apiClient.delete<{ errors?: Record<string, string[]> }>(
      `projects/${projectId}/quality-gates/${gateId}/constraints/${constraintId}`,
    );
    return response;
  } catch (error) {
    console.error(error);
    notify();
  }
};

const updateConstraint = async (
  projectId: string,
  gateId: string,
  constraint: ConstraintResource,
  body: Record<string, string | number>,
) => {
  try {
    const response = await apiClient.put<{ data: ConstraintResource, errors?: Record<string, string[]> }>(
      `projects/${projectId}/quality-gates/${gateId}/constraints/${constraint.id}`,
      {
        body: JSON.stringify(body),
      },
    );
    return response;
  } catch (error) {
    console.error(error);
    notify();
  }
};

const contextMenuOptions = (t: TFunction<'translation', undefined>, onEdit: () => void, onDelete: () => void, isGateFinished: boolean) => [
  {
    id: 0,
    title: (
      <div className={styles.action__option}>
        <svg className='tableRowActionIcon'>
          <use
            xlinkHref={`${EditSVG}#editSVG`}
            href={`${EditSVG}#editSVG`}
          />
        </svg>
        <p>{t('Edit constraint')}</p>
      </div>
    ),
    handler: onEdit,
  },
  ...(!isGateFinished ? [{
    id: 1,
    title: (
      <div className={styles.action__option}>
        <svg className='tableRowActionIcon'>
          <use
            xlinkHref={`${DeleteSVG}#deleteSVG`}
            href={`${DeleteSVG}#deleteSVG`}
          />
        </svg>
        <p>{t('Delete')}</p>
      </div>
    ),
    handler: onDelete,
  }] : []),
];

const Constraints = () => {
  const { t, i18n } = useTranslation();
  const { projectId, gateId } = useParams();
  const { setActionButton } = useOutletContext<QualityGateContextType>();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [selectedConstraint, setSelectedConstraint] = useState<ConstraintResource | null>(null);
  const userData = useAtomValue(userAtom);
  const dateFormat = userData?.user.dateFormat ?? DEFAULT_DATE_FORMAT;

  const {
    data: constraints,
    isLoading,
    mutate,
    isValidating,
  } = useSWR(
    [`projects/${projectId}/quality-gates/${gateId}/constraints`, i18n.language, gateId, projectId],
    ([url]) => apiClient.get<{ data: ConstraintResource[] }>(url).then(({ response }) => response.data),
    {
      keepPreviousData: true,
      revalidateIfStale: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: true,
      revalidateOnMount: true,
    },
  );
  const { data: gateData } = useSWR(
    [`projects/${projectId}/quality-gates/${gateId}?with=deliverables`, i18n.language, gateId, projectId],
    ([url]) => apiClient
      .get<{ data: GateResource, permissions: string[] }>(url).then(({ response }) => response),
    {
      revalidateOnFocus: false,
      keepPreviousData: false,
    },
  );

  const isGateFinished = !!gateData?.data?.executed_date;
  const hasUpdatePermission = gateData?.permissions.includes(ProjectPermissions.EXECUTE);

  useEffect(() => {
    if (gateData && hasUpdatePermission) {
      setActionButton({ label: 'Add constraint', callback: () => setIsDrawerOpen(true) });
    } else {
      setActionButton(null);
    }
    return () => {
      setActionButton(null);
    };
  }, [gateData, hasUpdatePermission]);

  const onEditConstraintClick = (constraint: ConstraintResource) => {
    setSelectedConstraint(constraint);
    setIsDrawerOpen(true);
  };

  const onDeleteConstraintClick = (constraint?: ConstraintResource) => {
    setIsDrawerOpen(false);
    constraint && setSelectedConstraint(constraint);
    setIsDeleteModalVisible(true);
  };

  const onClosedDrawer = () => {
    setIsDrawerOpen(false);
    setSelectedConstraint(null);
  };

  const onCloseDeleteModal = () => {
    setIsDeleteModalVisible(false);
    setSelectedConstraint(null);
  };

  const onDeleteConstraint = async () => {
    if (projectId && gateId && selectedConstraint) {
      const response = await deleteConstraint(projectId, gateId, selectedConstraint?.id);
      if (response?.statusCode === 204) {
        mutate();
        onCloseDeleteModal();
      } else {
        response?.response?.errors && notify({ text: { body: Object.values(response.response.errors).join(', ') } });
      }
    }
  };

  const onCreateFormSubmit = async (formValue: FormikValues) => {
    if (projectId && gateId) {
      const response = await createConstraint(projectId, gateId, formValue);
      if (response?.statusCode === 201) {
        mutate();
        onClosedDrawer();
      } else {
        response?.response?.errors && notify({ text: { body: Object.values(response.response.errors).join(', ') } });
      }
    }
  };

  const onEditFormSubmit = async (formValue: Record<string, string>) => {
    if (projectId && gateId && selectedConstraint) {
      const response = await updateConstraint(projectId, gateId, selectedConstraint, formValue);
      if (response?.statusCode === 200) {
        mutate();
        onClosedDrawer();
      } else {
        response?.response?.errors && notify({ text: { body: Object.values(response.response.errors).join(', ') } });
      }
    }
  };

  return (
    <>
      <section className={classNames(styles.constraints, {
        [styles.constraints_revalidate]: isValidating && !isLoading,
        [styles.constraints_noContent]: isLoading,
      })}
      >
        {
        isLoading ? (<Loader className={styles.constraints__loader} />) : !constraints?.length ? (
          <div className={styles.placeholder}>
            <svg className={styles.placeholder__img}>
              <use
                xlinkHref={`${EmptyConstraintsSVG}#emptyConstraintsSVG`}
                href={`${EmptyConstraintsSVG}#emptyConstraintsSVG`}
              />
            </svg>
            <p className={styles.placeholder__title}>{t('Constraints are empty')}</p>
          </div>
        ) : (
          <div>
            <header className={classNames(styles.constraints__header, styles.constraints__row)}>
              {COLUMN_NAMES.map((name) => (
                <p key={name}>{t(name)}</p>
              ))}
            </header>
            {constraints?.map((constraint) => (
              <div
                key={constraint?.id}
                className={styles.constraints__row}
              >
                <p className={styles.greyText}>{constraint.id}</p>
                <p>{constraint.deliverable.caption}</p>
                <p>{constraint.caption}</p>
                <p>{constraint.impact}</p>
                <p>{constraint.action}</p>
                <p>{constraint.responsibility}</p>
                <p>{dayjs(constraint.due_date).format(dateFormat)}</p>
                <StatusLabel
                  status={constraint.status}
                  className={styles.statusLabel}
                />
                {hasUpdatePermission && (
                <PopoverOptions
                  buttonClassName={styles.list__actionBtn}
                  options={contextMenuOptions(
                    t,
                    () => onEditConstraintClick(constraint),
                    () => onDeleteConstraintClick(constraint),
                    isGateFinished,
                  )}
                  placement={PopoverPlacement.LEFT}
                />
                )}
              </div>
            ))}
          </div>
        )
      }
      </section>
      <Drawer
        isOpen={isDrawerOpen}
        setIsOpen={(isOpen) => !isOpen && onClosedDrawer()}
        title={selectedConstraint ? selectedConstraint.caption : t('Add new constraint')}
        subTitle={selectedConstraint ? t('Edit constraint') : undefined}
        className={styles.drawer}
      >
        {gateData?.data.deliverables ? (
          <ConstraintForm
            constraint={selectedConstraint}
            submitButtonText={selectedConstraint ? t('Edit constraint') : t('Add constraint')}
            submitForm={(values) => (selectedConstraint ? onEditFormSubmit(values) : onCreateFormSubmit(values))}
            deliverables={gateData?.data?.deliverables}
            onDelete={() => onDeleteConstraintClick()}
            isGateFinished={isGateFinished}
          />
        ) : <Loader />}
      </Drawer>
      {isDeleteModalVisible && (
        <EntityBaseModal
          onClose={() => onCloseDeleteModal()}
          onConfirmClick={onDeleteConstraint}
          heading={`${t('Are you sure you want to delete this constraint')}?`}
          confirmButtonText={t('Yes, delete')}
        />
      )}
    </>
  );
};

export default Constraints;
