import { TFunction } from 'i18next';

import SimpleSelect, { OptionWithElement } from '../../UIKit/SimpleSelect/SimpleSelect';

import { RoleResource } from '../Users/UsersList/types';
import { HIGHEST_ROLE_LEVEL } from '../../../constants';

import Avatar from '../../ColoredAvatar/ColoredAvatar';
import DeleteSVG from '../../../public/media/delete-icon.svg';
import EditSVG from '../../../public/media/edit-icon.svg';
import KeySVG from '../../../public/media/key.svg';
import ViewSVG from '../../../public/media/view.svg';
import DotsSVG from '../../../public/media/dots.svg';

import styles from './ProjectTeamManagment.module.scss';
import Button, { ButtonIconPosition, ButtonVariants } from '../../UIKit/Button/Button';

const icons: Record<string, { icon: string, id: string }> = {
  edit: {
    icon: EditSVG,
    id: 'editSVG',
  },
  view: {
    icon: ViewSVG,
    id: 'viewSVG',
  },
  key: {
    icon: KeySVG,
    id: 'keySVG',
  },
};

type ProjectTeamMemberProps = {
  id: number;
  name: string;
  manageRole?: RoleResource;
  onDelete: ({ id, name }: { id: number; name: string }) => void;
  roles: RoleResource[];
  onChangeRole: (role: RoleResource & OptionWithElement) => void;
  onOpenMobileOptions: ({ id, name }: { id: number; name: string }) => void;
  t: TFunction<'translation', undefined>
};

const SelectorOption = ({ role, t }: { role: RoleResource, t: TFunction<'translation', undefined> }) => (
  <div className={styles.selectorItem}>
    <svg className={styles.selectorItem_icon}>
      <use
        xlinkHref={`${role.icon ? icons[role.icon].icon : EditSVG}#${role.icon ? icons?.[role.icon]?.id : 'editSVG'}`}
        href={`${role.icon ? icons[role.icon].icon : EditSVG}#${role.icon ? icons?.[role.icon]?.id : 'editSVG'}`}
      />
    </svg>
    <span>{role?.caption || t('Please select')}</span>
  </div>
);

const SelectPalceholder = ({ t }: { t: TFunction<'translation', undefined> }) => (
  <div className={styles.selectorItem}>
    <svg className={styles.selectorItem_icon}>
      <use
        xlinkHref={`${EditSVG}#editSVG`}
        href={`${EditSVG}#editSVG`}
      />
    </svg>
    <span>{t('Please select')}</span>
  </div>
);

const ProjectTeamMember = ({
  name, manageRole, onDelete, roles, id, onChangeRole, t, onOpenMobileOptions,
}: ProjectTeamMemberProps) => (
  <div className={styles.member}>
    <Avatar
      className={styles.member__avatar}
      name={name}
    />
    <p className={styles.member__name}>
      {name}
      {manageRole && (
        <span className={styles.member__role}>
          <svg className={styles.member__role_icon}>
            <use
              xlinkHref={
                `${manageRole.icon ? icons[manageRole.icon].icon : EditSVG}#${manageRole.icon ? icons?.[manageRole.icon]?.id : 'editSVG'}`
              }
              href={
                `${manageRole.icon ? icons[manageRole.icon].icon : EditSVG}#${manageRole.icon ? icons?.[manageRole.icon]?.id : 'editSVG'}`
              }
            />
          </svg>
          <span>{manageRole?.caption || ''}</span>
        </span>
      )}
    </p>
    {manageRole?.level === HIGHEST_ROLE_LEVEL && (
    <p className={styles.member__pmLabel}>
      <svg className={styles.member__pmLabel_icon}>
        <use
          xlinkHref={`${KeySVG}#keySVG`}
          href={`${KeySVG}#keySVG`}
        />
      </svg>
      <span>{t('Project owner')}</span>
    </p>
    )}
    <SimpleSelect
      selected={
        manageRole
          ? {
            ...manageRole,
            element: <SelectorOption
              role={manageRole}
              t={t}
            />,
          }
          : undefined
      }
      placeholder={<SelectPalceholder t={t} />}
      options={roles.map((role) => ({
        ...role,
        element: <SelectorOption
          role={role}
          t={t}
        />,
      }))}
      onSelect={onChangeRole}
      className={styles.member__roleSelector}
    />
    <button
      className={styles.member__removeBtn}
      type='button'
      aria-label={t('remove member')}
      onClick={() => onDelete({ id, name })}
    >
      <svg className={styles.member__removeBtn_icon}>
        <use
          xlinkHref={`${DeleteSVG}#deleteSVG`}
          href={`${DeleteSVG}#deleteSVG`}
        />
      </svg>
    </button>

    <Button
      variant={ButtonVariants.SECONDARY}
      className={styles.member__optionsBtn}
      type='button'
      onClick={() => onOpenMobileOptions({ id, name })}
      icon={(
        <svg className={styles.member__optionsBtn_icon}>
          <use
            xlinkHref={`${DotsSVG}#dotsSVG`}
            href={`${DotsSVG}#dotsSVG`}
          />
        </svg>
        )}
      iconSize={{ width: 16, height: 16 }}
      iconPosition={ButtonIconPosition.CENTER}
    />
  </div>
);

export default ProjectTeamMember;
