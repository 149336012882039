import classNames from 'classnames';
import useSWR from 'swr';
import { useTranslation } from 'react-i18next';
import { useOutletContext, useParams } from 'react-router-dom';
import { useAtomValue } from 'jotai';

import EmptyList from '../../../../EmptyList/EmptyList';
import Loader from '../../../../Loader/Loader';

import apiClient from '../../../../../apiClient';
import { SkillResource } from '../types';
import { NewProjectContext } from '../../types';
import { StatusState } from '../../../../UIKit/StatusLabel/types';

import styles from './SummaryDashboard.module.scss';

const SummarySkills = () => {
  const { projectAtom } = useOutletContext<Pick<NewProjectContext, 'projectAtom'>>();
  const project = useAtomValue(projectAtom);
  const { t, i18n } = useTranslation();
  const { projectId } = useParams();
  const { data: skills, isLoading, isValidating } = useSWR(
    [`projects/${projectId}/skills?page=all&with=deliverables_count`, i18n.language, project?.status?.state],
    async ([url]) => (project?.status?.state !== StatusState.PENDING ? apiClient
      .get<{ data: SkillResource[] }>(url)
      .then(({ response }) => response.data.sort((a, b) => b.deliverables_count - a.deliverables_count)) : null),
    {
      keepPreviousData: false,
      revalidateOnFocus: false,
      revalidateOnMount: true,
      onError: (error) => {
        console.error(error);
      },
    },
  );

  const DEFAUT_WIDTH = 48;

  return (
    <section className={classNames(styles.section, styles.section_withList, styles.skills, {
      [styles.section_validating]: isValidating && !isLoading,
    })}
    >
      {isLoading ? (
        <Loader className={styles.loader} />
      ) : (
        <>
          <header className={styles.sectionHeader}>
            <h3 className={styles.sectionTitle}>{t('Skills needed')}</h3>
          </header>
          {skills?.length ? (
            <div className={classNames(styles.skills__list)}>
              <header
                className={classNames(
                  styles.list__row,
                  styles.list__header,
                  styles.list__header_nowrap,
                  styles.skills__list__row,
                  styles.skills__list__header,
                )}
              >
                <p className={styles.list__row_onLeft}>{t('Skill')}</p>
                <p className={classNames(styles.list__row_onRight)}>{t('Number of deliverables')}</p>
              </header>
              {skills?.map((item, i, arr) => {
                const width = DEFAUT_WIDTH * (item.deliverables_count / arr[0].deliverables_count);
                return (
                  <div
                    key={item.id}
                    className={classNames(styles.list__row, styles.skills__list__item)}
                  >
                    <p>{item.caption}</p>
                    <p
                      className={classNames(styles.list__row_onRight, styles.skills__list__deliverables)}
                    >
                      <span
                        style={{ width }}
                        className={styles.skills__list__deliverables__chart}
                      />
                      {item.deliverables_count}
                    </p>
                  </div>
                );
              })}
            </div>
          ) : (
            <EmptyList title={t('Skills not added')} />
          )}
        </>
      )}
    </section>
  );
};

export default SummarySkills;
