import { IGanttOptions } from 'devextreme-react/gantt';
import { atom } from 'jotai';

export const scaleAtom = atom<IGanttOptions['scaleType']>('weeks');

export enum TasksListSize {
  FULL = 'full',
  MINIMIZED = 'minimized',
}

export const taskListWidthAtom = atom<TasksListSize>(TasksListSize.FULL);

export const collapseLevelAtom = atom<number>(0);
export const maxCollapseLevelAtom = atom<number | null>(null);
