import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import styles from './QualityGateTabs.module.scss';
import GateSVG from '../../../../public/media/summary/quality-gates/gate.svg';
import VoteSVG from '../../../../public/media/summary/quality-gates/vote.svg';
import ConstraintsSVG from '../../../../public/media/summary/quality-gates/constraints.svg';
import HistorySVG from '../../../../public/media/summary/quality-gates/history.svg';

export const qualityGateTabsList = [
  {
    url: '',
    title: 'Q-Gate Deliverables',
    icon: { path: GateSVG, id: 'gateSVG' },
  },
  {
    url: 'voting',
    title: 'Voting',
    icon: { path: VoteSVG, id: 'voteSVG' },
  },
  {
    url: 'constraints',
    title: 'Constraints',
    icon: { path: ConstraintsSVG, id: 'constraintsSVG' },
  },
  {
    url: 'history',
    title: 'Change history',
    icon: { path: HistorySVG, id: 'historySVG' },
  },
];

const QualityGateTabs = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.tabs}>
      <div className={styles.links}>
        {qualityGateTabsList.map((tab) => (
          <NavLink
            end
            to={tab.url}
            key={tab.url}
            className={({ isActive }) => classNames(styles.links__item, {
              [styles.active]: isActive,
            })}
          >
            <svg>
              <use
                xlinkHref={`${tab.icon.path}#${tab.icon.id}`}
                href={`${tab.icon.path}#${tab.icon.id}`}
              />
            </svg>
            <p className={styles.tab__title}>{t(tab.title)}</p>
          </NavLink>
        ))}
      </div>
      {children}
    </div>
  );
};

export default QualityGateTabs;
