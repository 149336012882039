import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useAtomValue } from 'jotai';
import classNames from 'classnames';
import { useParams, useSearchParams } from 'react-router-dom';
import { KeyedMutator } from 'swr/_internal';
import { useMediaQuery } from '@mui/material';
import styles from './QualityGateDeliverable.module.scss';
import DeliverableOptionsDropdown from '../DeliverableOptionsDropdown/DeliverableOptionsDropdown.tsx';
import { Deliverable, DeliverableLink } from '../../../NewProject/NewProjectDeliverables/types.ts';
import AttachSVG from '../../../../../public/media/attach.svg';
import AngleDownSVG from '../../../../../public/media/angle-down.svg';

import { DEFAULT_DATE_FORMAT, MOBILE_MEDIA_QUERY } from '../../../../../constants.ts';
import { userAtom } from '../../../../../store/auth.ts';
import CheckboxItem from '../../../../UIKit/CheckboxItem/CheckboxItem.tsx';
import DeliverableLinks from '../DeliverableLinks/DeliverableLinks.tsx';
import WarningSVG from '../../../../../public/media/warning.svg';
import CheckedSVG from '../../../../../public/media/checked.svg';
import { ProjectPermissions } from '../../../Login/user.props.ts';
import DeleteDeliverableModal from '../DeleteDeliverableModal/DeleteDeliverableModal.tsx';
import apiClient from '../../../../../apiClient.ts';
import { notify } from '../../../../../store/notifications.ts';
import { GateResource } from '../../types.ts';

type QualityGateDeliverableProps = {
  deliverable: Deliverable,
  permissions: string[],
  mutate: KeyedMutator<{ data: GateResource, permissions: string[] }>
};

const QualityGateDeliverable = ({ deliverable, permissions, mutate }: QualityGateDeliverableProps) => {
  const { t } = useTranslation();
  const { projectId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const userData = useAtomValue(userAtom);

  const [isOpen, setIsOpen] = useState(false);
  const [allLinks, setAllLinks] = useState<DeliverableLink[]>([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const isMobileDevice = useMediaQuery(MOBILE_MEDIA_QUERY);

  useEffect(() => {
    setAllLinks(deliverable.links ?? []);
  }, []);

  useEffect(() => {
    const activeDeliverable = searchParams.get('active');

    if (!activeDeliverable) return;

    if (permissions.includes(ProjectPermissions.UPDATE)) {
      setIsOpen(+activeDeliverable === deliverable.id);
    }
    searchParams.delete('active');
    setSearchParams(searchParams, { replace: true });
  }, [searchParams.get('active')]);

  const updateDeliverable = async (values: Record<string, any>) => {
    try {
      const { statusCode } = await apiClient.put(`projects/${projectId}/deliverables/${deliverable.id}`, {
        body: JSON.stringify(values),
      });

      if (statusCode !== 200) throw new Error();
      mutate();
    } catch (e) {
      console.error(e);
      notify();
    } finally {
      setIsDeleteModalOpen(false);
    }
  };

  const renderDoneStatus = () => (
    permissions.includes(ProjectPermissions.UPDATE) ? (
      <div className={styles.deliverable__mark}>
        <CheckboxItem
          iconSize={18}
          fontSize={12}
          label={t('Mark as done')}
          value={deliverable.done}
          onChange={(e) => updateDeliverable({ done: +e.target.checked })}
        />
      </div>
    ) : deliverable.done ? (
      <div className={styles.deliverable__done}>
        <svg>
          <use
            xlinkHref={`${CheckedSVG}#checkedSVG`}
            href={`${CheckedSVG}#checkedSVG`}
          />
        </svg>
        {t('Done')}
      </div>
    ) : null
  );

  const renderRiskStatus = () => (
    deliverable?.task?.task_at_risk && (
      <div className={styles.deliverable__risk}>
        <svg>
          <use
            xlinkHref={`${WarningSVG}#warningSVG`}
            href={`${WarningSVG}#warningSVG`}
          />
        </svg>
        {t('Task at risk')}
      </div>
    )
  );

  return (
    <div className={styles.wrapper}>
      <div className={classNames(styles.deliverable, {
        [styles.isOpen]: isOpen,
      })}
      >
        <div>
          <DeliverableOptionsDropdown
            permissions={permissions}
            onDeleteClick={() => setIsDeleteModalOpen(true)}
          />
          <div className={styles.deliverable__inner}>
            <div className={styles.deliverable__info}>
              <span>{dayjs(deliverable.due_date).format(userData?.user.dateFormat ?? DEFAULT_DATE_FORMAT)}</span>
              {allLinks?.length ? (
                <>
                  <span className={styles.divider} />
                  <svg>
                    <use
                      xlinkHref={`${AttachSVG}#attachSVG`}
                      href={`${AttachSVG}#attachSVG`}
                    />
                  </svg>
                  <span>{`${allLinks.length} ${t('documents')}`}</span>
                </>
              ) : null}
            </div>
            <h6 className={styles.deliverable__title}>{deliverable.caption}</h6>
            {isMobileDevice && renderRiskStatus()}
            {isMobileDevice && renderDoneStatus()}
          </div>
        </div>
        <div>
          {!isMobileDevice && renderRiskStatus()}
          {!isMobileDevice && renderDoneStatus()}
          {permissions.includes(ProjectPermissions.UPDATE) ? (
            <button
              type='button'
              className={styles.deliverable__toggleButton}
              onClick={() => setIsOpen(!isOpen)}
            >
              <svg>
                <use
                  xlinkHref={`${AngleDownSVG}#angleDownSVG`}
                  href={`${AngleDownSVG}#angleDownSVG`}
                />
              </svg>
            </button>
          ) : null}
        </div>
      </div>
      {isOpen && (
        <DeliverableLinks
          links={allLinks}
          permissions={permissions}
          deliverable={{ id: deliverable.id, caption: deliverable.caption }}
          updateLinks={setAllLinks}
        />
      )}
      {isDeleteModalOpen && (
        <DeleteDeliverableModal
          closeModal={() => setIsDeleteModalOpen(false)}
          deleteDeliverable={() => updateDeliverable({ quality_gate: null })}
        />
      )}
    </div>
  );
};

export default QualityGateDeliverable;
