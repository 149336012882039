import { lazy, Suspense, useState } from 'react';
import useSWR from 'swr';
import { useTranslation } from 'react-i18next';

import { useParams } from 'react-router-dom';
import apiClient from '../../../apiClient';

import Button, { ButtonVariants } from '../../UIKit/Button/Button';
import Drawer from '../../UIKit/Drawer/Drawer.tsx';
import Loader from '../../Loader/Loader';
import AddCustomerForm from './AddCustomerForm/AddCustomerForm.tsx';

import { Question } from './types';

import styles from './Customers.module.scss';

const CustomersList = lazy(() => import('./CustomersList/CustomersList'));

const Customers = () => {
  const { id: clientId } = useParams();
  const { t, i18n } = useTranslation();
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  const { data } = useSWR(['questions/customer', i18n.language], ([url]) => apiClient
    .get<{ data: Question[] }>(url).then(response => response.response.data), {
    keepPreviousData: true,
    revalidateOnFocus: false,
  });

  return (
    <section className={styles.customers}>
      <div className={styles.heading}>
        <h5>{t('Customers')}</h5>
        <Button
          variant={ButtonVariants.SECONDARY}
          onClick={() => setIsDrawerOpen(true)}
          className={styles.button}
        >
          {t('Create new customer')}
        </Button>
      </div>
      <Suspense fallback={<Loader className={styles.loader} />}>
        <CustomersList customerQuestions={data} />
      </Suspense>
      <Drawer
        isOpen={isDrawerOpen}
        setIsOpen={setIsDrawerOpen}
        title={t('Add new customer')}
        className={styles.drawer}
      >
        <AddCustomerForm
          closeModal={() => setIsDrawerOpen(false)}
          clientId={clientId}
        />
      </Drawer>
    </section>
  );
};

export default Customers;
