import { atom } from 'jotai';
import { atomWithStore } from 'jotai-zustand';
import { createStore, StoreApi } from 'zustand/vanilla';

export const previousColorPalette = atom<{ [key: string]: string }>({});

export const clientPreviewId: StoreApi<number | null> = createStore(() => null);
export const clientPreviewIdAtom = atomWithStore(clientPreviewId);

export interface FileWithPreview extends File {
  preview: string;
}
export const previewLogoAtom = atom<{ logo: FileWithPreview, id?: number } | null>(null);

export const customLogoStore: StoreApi<{ logoUrl: string, id?: number } | null> = createStore(() => null);
export const customLogoAtom = atomWithStore(customLogoStore);
