import React, {
  Suspense, useEffect, useRef, useState,
} from 'react';
import { Link, useParams } from 'react-router-dom';
import classNames from 'classnames';
import dayjs, { ManipulateType } from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import de from 'dayjs/locale/de';
import en from 'dayjs/locale/en';
import isoWeek from 'dayjs/plugin/isoWeek';
import updateLocale from 'dayjs/plugin/updateLocale';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import Gantt, {
  Column, ScaleTypeRange, StripLine, Tasks,
} from 'devextreme-react/gantt';
import { TreeListTypes } from 'devextreme-react/tree-list';
import config from 'devextreme/core/config';
import { locale } from 'devextreme/localization';
import {
  ContentReadyEvent, GanttScaleType, GanttTaskTitlePosition, TaskContentTemplateData,
} from 'devextreme/ui/gantt';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { SelectChangeEvent, useMediaQuery } from '@mui/material';
import { FirstDayOfWeek } from 'devextreme/common';
import useSWR from 'swr';

import Loader from '../../Loader/Loader.tsx';
import PopoverOptions from '../../UIKit/PopoverOptions/PopoverOptions.tsx';
import Select from '../../UIKit/Select/Select.tsx';
import AutorebootErrorBoundary from '../../AutorebootErrorBoundary/AutorebootErrorBoundary.tsx';

import apiClient from '../../../apiClient.ts';
import { GANTT_API_KEY } from '../../../constants.ts';
import { PopoverPlacement } from '../../UIKit/Popover/Popover.tsx';
import {
  scaleAtom,
  taskListWidthAtom,
  TasksListSize,
  sortAtom,
  qgAtom,
  collapseLevelAtom,
  SortType,
  selectedPortfolioDomain,
} from './portfolioGantt.atom.ts';
import { userAtom } from '../../../store/auth.ts';

import AngleDownSVG from '../../../public/media/angle-down.svg';
import LevelsSVG from '../../../public/media/levels.svg';
import SearchSVG from '../../../public/media/search.svg';
import ListCloseSVG from '../../../public/media/list_close.svg';
import ListOpenSVG from '../../../public/media/list_open.svg';

import styles from './PortfolioGantt.module.scss';
import Input from '../../UIKit/Input/Input.tsx';
import { useDebounce } from '../../../useDebounce.ts';
import { ProjectProirityValue } from '../../pages/Projects/types.ts';

dayjs.extend(isoWeek);
dayjs.extend(advancedFormat);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);
dayjs.extend(customParseFormat);
dayjs.locale(de);
dayjs.locale(en);
dayjs.extend(updateLocale);
// gantt lib has different monthsShort format, so we need to update it
dayjs.updateLocale('de', {
  monthsShort: [
    'Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun',
    'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez',
  ],
});

config({ licenseKey: GANTT_API_KEY });

const MIN_TASK_WITH_TEXT_WIDTH = 40;

const dateNow = new Date(Date.now());

type ParralelDomainResourse = Record<string, number>;

export type PortfolioItem = {
  id: number;
  caption: string;
  start_date: Date;
  finish_date: Date;
  project_id?: string;
  duration?: number;
};

function calculateMilestonePosition(startDate: Date, endDate: Date, milestoneDate: Date) {
  // Convert dates to dayjs objects
  const start = dayjs(startDate);
  const end = dayjs(endDate);
  const milestone = dayjs(milestoneDate);

  // Calculate total duration from start to end in days
  const totalDuration = end.diff(start, 'day');

  // Calculate the duration from start to milestone in days
  const milestoneDuration = milestone.diff(start, 'day');

  // Calculate the position of the milestone as a percentage of the total duration
  const positionPercentage = (milestoneDuration / totalDuration) * 100;
  return positionPercentage; // This is the x-coordinate of the milestone in pixels
}

interface DateRange {
  startDate: string;
  endDate?: string;
}

function parseDateRange(scaleItemDate: string, scaleItemYear: string, scale: keyof typeof domainsScale, dateLocale: string): DateRange {
  if (!scaleItemDate || !scaleItemYear) {
    return { startDate: '', endDate: '' }; // Error handling for missing input
  }

  const SCALE_YEAR_INPUT_FORMAT: Partial<Record<typeof scale, string>> = {
    days: 'D MMMM YYYY',
    weeks: 'MMMM YYYY',
    months: 'YYYY',
  };

  let startDate = '';
  let endDate = '';

  const year = dayjs(scaleItemYear, SCALE_YEAR_INPUT_FORMAT[scale]).year();

  switch (scale) {
    case 'weeks':
      [startDate, endDate] = scaleItemDate.split(' - ').map((date) => dayjs(`${date}, ${year}`).format('YYYY-MM-DD'));
      break;
    case 'days':
      startDate = dayjs(`${scaleItemDate} ${year}`, 'ddd, DD MMM YYYY', dateLocale).format('YYYY-MM-DD');
      break;
    case 'months':
      startDate = dayjs(`${scaleItemDate} ${year}`, 'MMMM YYYY').format('YYYY-MM');
      break;
    default:
      break;
  }

  return { startDate, endDate };
}

const domainsScale: Partial<Record<GanttScaleType, ManipulateType>> = {
  days: 'day',
  weeks: 'week',
  months: 'month',
};

function createObject(num: number): { [key: number]: boolean } {
  const result: { [key: number]: boolean } = {};
  for (let i = 0; i < num; i++) {
    result[i] = false;
  }
  return result;
}

function formatDateToWeekFormat(dateString: string): string {
  const regexPattern = /(\d{4})-(\d{2})/;
  const formattedString = (dateString || '').replace(regexPattern, '$1-W$2');
  return formattedString;
}

function extractDate(inputString: string): string {
  const regexPattern = /(\d{4})-W(\d{2})/;
  const formattedString = (inputString || '').replace(regexPattern, '$1-$2');
  return formattedString;
}

function calculateDate(firstScaleItem: string, scale: keyof typeof domainsScale, dateOrderIndex: number) {
  const FORMATS: Partial<Record<ManipulateType, string>> = {
    day: 'YYYY-MM-DD',
    week: 'YYYY-WW',
    month: 'YYYY-MM',
  };
  let initDate = dayjs(firstScaleItem);
  // a trick to let dayjs know that we are working with weeks
  if (scale === 'weeks' && firstScaleItem?.split('-')?.length === 2) {
    initDate = dayjs()
      .year(Number(firstScaleItem.split('-')[0]))
      .week(Number(firstScaleItem.split('-')[1]));
  }
  const parsedScale = domainsScale[scale] || 'weeks';
  const date = dateOrderIndex < 0 ? initDate.subtract(Math.abs(dateOrderIndex), parsedScale) : initDate.add(dateOrderIndex, parsedScale);
  const formattedDate = dayjs(date).format(FORMATS[parsedScale]);
  return scale === 'weeks' ? formatDateToWeekFormat(formattedDate) : formattedDate;
}

function TaskItem({ taskData, taskSize }: TaskContentTemplateData) {
  const { clientId } = useParams();
  const qGates = useAtomValue(qgAtom);
  const content = (
    <div
      className={classNames(styles.task, {
        [styles.task_project]: !taskData.project_id,
      })}
      style={{
        width: `${taskSize.width}px`,
      }}
    >
      <div
        className={classNames(styles.task__content, {
          [styles.hidden]: taskSize.width < MIN_TASK_WITH_TEXT_WIDTH,
        })}
      >
        <p>{taskData.caption}</p>
        {!taskData.project_id
          && qGates[taskData.id]?.map((gate) => (
            <Link
              key={gate.id}
              to={`/d/client/${clientId}/project/${taskData.id}/gate/${gate.id}`}
              className={classNames(styles.qGate)}
              title={gate.caption}
              style={{ left: `${calculateMilestonePosition(taskData.start_date, taskData.finish_date, gate.due_date)}%` }}
            />
          ))}
      </div>
    </div>
  );
  return !taskData.project_id && taskData.id ? (
    <Link
      className={styles.taskWrap}
      to={`/d/client/${clientId}/project/${!taskData.project_id && taskData.id}/summary`}
      data-project-id={taskData.id}
    >
      {content}
    </Link>
  ) : (
    <div className={styles.taskWrap}>{content}</div>
  );
}

function TaskTooltip(task: PortfolioItem) {
  const { caption } = task;

  return (
    <div className={classNames(styles.tooltip, styles.nameTooltip)}>
      <h6 className={styles.nameTooltip__title}>{caption}</h6>
    </div>
  );
}

function TaskCell({ data }: TreeListTypes.ColumnCellTemplateData) {
  const {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    caption, project_id, priority, id,
  } = data.data;
  const { clientId } = useParams();

  // on the backedn the hiest priority is 3 and lowest is 1,
  // so we need to convert it to display it correctly
  const convertPriority = (value: ProjectProirityValue) => 4 - value;

  return !project_id ? (
    <>
      <div
        className={classNames(styles.projectPriority, {
          [styles.projectPriority_high]: priority?.value === ProjectProirityValue.HIGH,
          [styles.projectPriority_medium]: priority?.value === ProjectProirityValue.MEDIUM,
          [styles.projectPriority_low]: priority?.value === ProjectProirityValue.LOW,
        })}
      >
        {convertPriority(priority?.value)}
      </div>
      <Link
        to={`/d/client/${clientId}/project/${id}/summary`}
        className={styles.taskCell}
      >
        {caption}
      </Link>
    </>
  ) : (
    <p className={styles.taskCell}>{caption}</p>
  );
}

function DurationCell({ data }: TreeListTypes.ColumnCellTemplateData) {
  return <p className={styles.durationCell}>{data.displayValue}</p>;
}

function TaskHeaderCell() {
  const { t } = useTranslation('schedule');
  const taskListWidth = useAtomValue(taskListWidthAtom);

  return (
    <div
      className={classNames(styles.headerCell, {
        [styles.headerCell_hidden]: taskListWidth === TasksListSize.MINIMIZED,
      })}
    >
      {t('Projects')}
    </div>
  );
}

function DurationHeaderCell() {
  const { t } = useTranslation('schedule');
  const taskListWidth = useAtomValue(taskListWidthAtom);

  return (
    <div
      className={classNames(styles.headerCell, {
        [styles.headerCell_hidden]: taskListWidth === TasksListSize.MINIMIZED,
      })}
    >
      {t('Duration')}
    </div>
  );
}

const DESKTOP_BREAKPOINT = 1280;
const TABLET_BREAKPOINT = 768;
const SIDEBAR_DESKTOP_WIDTH = 457;
const SIDEBAR_TABLET_WIDTH = 340;
const MINIMIZED_SIDEBAR_WIDTH = 52;

function TasksHeader() {
  const { t } = useTranslation();
  const [scaleType, setScaleType] = useAtom(scaleAtom);
  const [sort, setSort] = useAtom(sortAtom);
  const setCollapseLevel = useSetAtom(collapseLevelAtom);

  const virtualCollapseButton: HTMLElement | null = document?.querySelector('#collapseButton');

  const updateScale = (value: 'days' | 'weeks' | 'months') => {
    setScaleType(value);
  };

  const updateSort = (value: SortType) => {
    setSort(value);
  };

  const updateCollapse = async (value: 0 | 1) => {
    await setCollapseLevel(value);
    virtualCollapseButton?.click();
  };

  const scaleTypes = [
    {
      id: 0,
      title: t('days'),
      handler: () => updateScale('days'),
    },
    {
      id: 1,
      title: t('weeks'),
      handler: () => updateScale('weeks'),
    },
    {
      id: 2,
      title: t('months'),
      handler: () => updateScale('months'),
    },
  ];

  const sortOptions = [
    {
      id: 0,
      title: t('Priority'),
      value: 'priority',
      handler: () => updateSort(SortType.PRIORITY),
    },
    {
      id: 1,
      title: t('Start date'),
      value: 'begin',
      handler: () => updateSort(SortType.START_DATE),
    },
    {
      id: 2,
      title: t('End date'),
      value: 'end',
      handler: () => updateSort(SortType.END_DATE),
    },
    {
      id: 3,
      title: t('Name'),
      value: 'caption',
      handler: () => updateSort(SortType.NAME),
    },
  ];

  const collapseOptions = [
    {
      id: 0,
      title: t('Collapse all'),
      value: 0,
      handler: async () => updateCollapse(0),
    },
    {
      id: 1,
      title: t('Expand all'),
      value: 1,
      handler: async () => updateCollapse(1),
    },
  ];

  const [taskListWidth, setTaskListSize] = useAtom(taskListWidthAtom);

  const toggleSidebar = () => {
    setTaskListSize(taskListWidth === TasksListSize.FULL ? TasksListSize.MINIMIZED : TasksListSize.FULL);
  };

  return (
    <div
      className={classNames(styles.tasksHeader, {
        [styles.minimized]: taskListWidth === TasksListSize.MINIMIZED,
      })}
    >
      <button
        type='button'
        onClick={toggleSidebar}
        className={classNames(styles.sidebarButton, { [styles.sidebarButton_full]: taskListWidth === TasksListSize.FULL })}
      >
        {taskListWidth === TasksListSize.FULL ? (
          <svg>
            <use
              xlinkHref={`${ListCloseSVG}#listCloseSVG`}
              href={`${ListCloseSVG}#listCloseSVG`}
            />
          </svg>
        ) : (
          <svg>
            <use
              xlinkHref={`${ListOpenSVG}#listOpenSVG`}
              href={`${ListOpenSVG}#listOpenSVG`}
            />
          </svg>
        )}
      </button>
      {taskListWidth === TasksListSize.FULL && (
        <div className={styles.tasksHeader__controls}>
          <PopoverOptions
            placement={PopoverPlacement.CONTEXT_MENU_LEFT}
            customButton={(
              <div className={styles.dropdownButton}>
                <span>
                  {sortOptions.find((sortOption) => sortOption.value === sort)?.title
                    || (sort && t(sort))
                    || t('Sort projects by')}
                </span>
                <svg className={styles.angleSVG}>
                  <use
                    xlinkHref={`${AngleDownSVG}#angleDownSVG`}
                    href={`${AngleDownSVG}#angleDownSVG`}
                  />
                </svg>
              </div>
            )}
            className={styles.sortButton}
            paperClassName={styles.sortPaper}
            options={sortOptions}
          />
          <PopoverOptions
            placement={PopoverPlacement.CONTEXT_MENU}
            customButton={(
              <div className={styles.dropdownButton}>
                {!!scaleType && <span>{t(scaleType)}</span>}
                <svg className={styles.angleSVG}>
                  <use
                    xlinkHref={`${AngleDownSVG}#angleDownSVG`}
                    href={`${AngleDownSVG}#angleDownSVG`}
                  />
                </svg>
              </div>
            )}
            options={scaleTypes}
            className={styles.scaleButton}
            paperClassName={styles.scalePaper}
          />
          <PopoverOptions
            placement={PopoverPlacement.CONTEXT_MENU}
            customButton={(
              <div className={styles.dropdownButton}>
                <svg className={styles.levelsSVG}>
                  <use
                    xlinkHref={`${LevelsSVG}#levelsSVG`}
                    href={`${LevelsSVG}#levelsSVG`}
                  />
                </svg>
              </div>
            )}
            options={collapseOptions}
            paperClassName={styles.scalePaper}
          />
        </div>
      )}
    </div>
  );
}

type GanttConfig = {
  taskTitlePosition: GanttTaskTitlePosition;
  showResources: boolean;
  showDependencies: boolean;
  showCustomTaskTooltip: boolean;
  taskContentRender: (data: TaskContentTemplateData) => React.ReactNode;
  allowSelection: boolean;
  showRowLines: boolean;
  taskTooltipContentRender: (data: PortfolioItem) => React.ReactNode;
  rowAlternationEnabled: boolean;
  firstDayOfWeek: FirstDayOfWeek;
};
const ganttConfig: GanttConfig = {
  taskTitlePosition: 'inside' as GanttTaskTitlePosition,
  showResources: false,
  showDependencies: false,
  showCustomTaskTooltip: true,
  taskContentRender: TaskItem,
  allowSelection: false,
  showRowLines: false,
  taskTooltipContentRender: TaskTooltip,
  rowAlternationEnabled: false,
  firstDayOfWeek: 1,
};

const PortfolioGanttContent = () => {
  const { clientId } = useParams();
  const { t, i18n } = useTranslation('schedule');
  const ganttRef = useRef<Gantt>(null);

  const sortType = useAtomValue(sortAtom);
  const collapseLevel = useAtomValue(collapseLevelAtom);

  const [searchValue, setSearchValue] = useState('');
  const debouncedValue = useDebounce<string>(searchValue, 300);

  const scaleType = useAtomValue(scaleAtom);
  const renderedDomainRanges = useRef<Record<number, boolean>>({});
  const [selectedDomain, setSelectedDomain] = useAtom(selectedPortfolioDomain);

  const isTablet = useMediaQuery(`(max-width: ${DESKTOP_BREAKPOINT - 1}px)`);
  const isMobile = useMediaQuery(`(max-width: ${TABLET_BREAKPOINT - 1}px)`);
  const isMobileDevice = isTablet || isMobile;

  /* Language switching */
  locale(i18n.language);

  const [isLanguageSwitching, setIsLanguageSwitching] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

  useEffect(() => {
    dayjs.locale(i18n.language);
    // Devextreme can't update gantt component language without reload
    if (currentLanguage !== i18n.language) {
      setIsLanguageSwitching(true);
      setTimeout(() => {
        setIsLanguageSwitching(false);
      }, 1);
      setCurrentLanguage(i18n.language);
    }
  }, [i18n.language]);
  /* END Language switching */

  const { data: domainOptions } = useSWR(
    [`clients/${clientId}/unique-domains`, i18n.language, clientId],
    ([url]) => apiClient
      .get<{ data: Record<number, string> }>(url)
      .then(({ response }) => Object.values(response.data).map((domain, i) => ({ caption: domain, value: domain, id: i }))),
    {
      keepPreviousData: false,
      revalidateOnFocus: false,
    },
  );

  const { data: parallelDomains } = useSWR(
    [`clients/${clientId}/count-parallel-domains`, i18n.language, selectedDomain, scaleType],
    ([url]) => (scaleType && selectedDomain
      ? apiClient
        .get<{ data: ParralelDomainResourse }>(
        `${url}?scale=${domainsScale?.[scaleType || 'weeks']}&search=${encodeURIComponent(selectedDomain)}`,
      )
        .then(({ response }) => response.data)
      : undefined),
    {
      keepPreviousData: false,
      revalidateOnFocus: false,
    },
  );

  const {
    data: loadedProjects,
    isLoading: isProjectsLoading,
    isValidating: isProjectsValidating,
  } = useSWR(
    [`clients/${clientId}/portfolio-gantt`, i18n.language, 'portfolio', sortType, debouncedValue],
    async ([url]) => {
      const params = new URLSearchParams({
        // backend has reversed priority values, so we need to reverse them back
        ...(sortType ? { order_by: sortType, ...(sortType === SortType.PRIORITY ? { direction: 'desc' } : {}) } : {}),
        ...(debouncedValue ? { filters: JSON.stringify({ caption: debouncedValue }) } : {}),
      }).toString();
      return apiClient.get<{ data: PortfolioItem[] }>(`${url}${params ? `?${params}` : ''}`).then(({ response }) => response.data);
    },
    {
      keepPreviousData: false,
      revalidateOnFocus: false,
    },
  );

  const [projects, setProjects] = useState<null | PortfolioItem[]>(null);

  useEffect(() => {
    if (!isProjectsLoading && !isProjectsValidating && loadedProjects) {
      setProjects(loadedProjects);
    }
  }, [loadedProjects, isProjectsLoading, isProjectsValidating]);

  const { data: loadedQGates, isLoading: isQGLoading } = useSWR(
    [`clients/${clientId}/quality-gates-portfolio-gantt`, i18n.language, 'schedule'],
    ([url]) => apiClient.get<{ data: any[] }>(url).then(({ response }) => response.data),
    {
      keepPreviousData: false,
      revalidateOnFocus: false,
    },
  );

  const setQGates = useSetAtom(qgAtom);

  useEffect(() => {
    loadedQGates
      && setQGates(loadedQGates.reduce((acc, gate) => ({ ...acc, [gate.project_id]: [...(acc?.[gate.project_id] || []), gate] }), {}));
  }, [loadedQGates]);

  const userData = useAtom(userAtom);
  const user = userData[0]?.user;

  const getDuration = (rowData: PortfolioItem) => {
    const format = user?.dateFormat || 'DD.MM.YYYY';
    const startDate = dayjs(rowData.start_date).format(format);
    const endDate = dayjs(rowData.finish_date).format(format);
    return `${startDate} - ${endDate}`;
  };

  const taskListWidth = useAtomValue(taskListWidthAtom);

  const ganttNode = document?.getElementsByClassName('dx-gantt-tsa');
  const ganttSidebarNode = document?.getElementsByClassName('dx-treelist');
  const ganttFooter = useRef<HTMLElement>(null);
  const domainsList = useRef<HTMLDivElement>(null);
  const [ganttWidth, setGanttWidth] = useState<null | number>(null);

  const todayMark = document?.getElementsByClassName(styles.todayMark);
  const footerTodayMark = useRef<HTMLDivElement>(null);
  const [footerTodayMarkPosition, setFooterTodayMarkPosition] = useState<number | null>(null);
  /* Calculate quantity of columns */

  const updateTodayMarkPosition = () => {
    setFooterTodayMarkPosition(parseFloat((todayMark?.[0] as HTMLElement)?.style?.left));
  };

  const [parallelDomainsQuantity, setParallelDomainsQuantity] = useState<number | null>(null);
  const [footerDomainWidth, setFooterDomainWidth] = useState<number | null>(null);
  const [firstTimeScaleItem, setFirstTimeScaleItem] = useState<string>();
  const [isScaleCalculated, setIsScaleCalculated] = useState(false);

  const getNewScale = () => {
    if (scaleType && footerDomainWidth) {
      const yearsSectionWrapper = document?.getElementsByClassName('dx-gantt-tsa')[0];
      const scaleItemsWrapper = document?.getElementsByClassName('dx-gantt-tsa')[1];
      const firstScaleItem = parseDateRange(
        scaleItemsWrapper?.childNodes?.[0]?.textContent!,
        yearsSectionWrapper?.childNodes?.[0]?.textContent!,
        scaleType,
        i18n.language,
      );
      const itemOrder = parseFloat((scaleItemsWrapper?.childNodes?.[0] as HTMLElement)?.style.left)
        ? -(parseFloat((scaleItemsWrapper?.childNodes?.[0] as HTMLElement)?.style.left) / footerDomainWidth)
        : 0;
      renderedDomainRanges.current = Object.fromEntries(Object.entries(renderedDomainRanges.current).map(([key]) => [key, false]));
      setFirstTimeScaleItem(calculateDate(firstScaleItem?.startDate, scaleType, itemOrder));
      setIsScaleCalculated(false);
    }
  };

  const calculateDomainsList = () => {
    const fullTrack = document?.querySelector('.dx-gantt-header .dx-gantt-tsa');
    const scaleItem = (document?.querySelector('.dx-gantt-header .dx-gantt-tsa:nth-child(2)')?.childNodes?.[0] as HTMLElement)?.style.width;
    const scaleItemWidth = parseFloat(scaleItem);
    setFooterDomainWidth(scaleItemWidth);
    const columnsQuantity = fullTrack && scaleItemWidth ? Math.round(fullTrack.clientWidth / scaleItemWidth) : null;
    setParallelDomainsQuantity(columnsQuantity);
    columnsQuantity && (renderedDomainRanges.current = createObject(columnsQuantity));
  };

  const updateGantt = () => {
    todayMark?.[0] && updateTodayMarkPosition();
    calculateDomainsList();
  };

  useEffect(() => {
    setIsScaleCalculated(true);
    updateGantt();
  }, [scaleType]);

  useEffect(() => {
    getNewScale();
    const timeout = setTimeout(() => {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      renderDomainColumns(parallelDomains);
    }, 3000);

    return () => {
      clearTimeout(timeout);
    };
  }, [footerDomainWidth]);

  const onGanttInit = (e: ContentReadyEvent) => {
    setTimeout(() => {
      // scroll to today mark with offset
      ganttRef?.current?.instance.scrollToDate(dayjs().subtract(4, 'months').toDate());
      // fix to prevent gantt chart zooming on ctrl+mousewheel scroll or touchpad pinching
      // @ts-ignore
      if (e.component?._ganttView) {
        // @ts-ignore
        e.component._ganttView._ganttViewCore.renderHelper._taskAreaManager.stateController.onMouseWheel = () => {};
      }
      // @ts-ignore
      e.component._treeList.option('allowColumnResizing', false);
      calculateDomainsList();
      getNewScale();
      setTimeout(() => {
        // @ts-ignore
        e?.component?._ganttView._ganttViewCore.renderHelper._ganttView.collapseAll();
      }, 0);
    }, 0);
    setGanttWidth(parseFloat((ganttNode[0] as HTMLElement).style.width) + parseFloat((ganttSidebarNode[0] as HTMLElement).style.width));
    updateTodayMarkPosition();
  };

  const ganttZone = document?.querySelector('.dx-gantt-view .dx-scrollable-container');
  const [footerScrollLeft, setFooterScrollLeft] = useState<number | undefined>(0);

  const updateFooterScrollPosition = () => {
    ganttZone && setFooterScrollLeft(ganttZone.scrollLeft);
  };
  useEffect(() => {
    ganttZone?.addEventListener('scroll', updateFooterScrollPosition);

    return () => ganttZone?.removeEventListener('scroll', updateFooterScrollPosition);
  }, [ganttZone]);

  useEffect(() => {
    if (ganttFooter.current && typeof footerScrollLeft === 'number') {
      ganttFooter.current.scrollLeft = footerScrollLeft;
    }
  }, [footerScrollLeft]);

  const gantHeader = document?.querySelectorAll('.dx-gantt-header .dx-gantt-tsa')?.[1];
  const scrollTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const renderDomainColumns = (parralels?: ParralelDomainResourse) => {
    if (isScaleCalculated || !parralels || !firstTimeScaleItem || !scaleType) return;

    const scaleItemsWrapper = document?.getElementsByClassName('dx-gantt-tsa')[1];
    scaleItemsWrapper
      && scaleItemsWrapper.childNodes.forEach((scaleItem) => {
        const domainContainer = scaleItem.cloneNode(false) as HTMLElement;
        const scaleItemText = scaleItem?.textContent;
        // scaleItemsWrapper contains not only scale items, but also other elements
        // so we need to check if the element is a scale item
        if (scaleItemText) {
          const dateOrderIndex = parseFloat((scaleItem as HTMLElement).style.left)
            ? Math.round(parseFloat((scaleItem as HTMLElement).style.left) / (footerDomainWidth || 0))
            : 0;
          const date = calculateDate(extractDate(firstTimeScaleItem), scaleType, dateOrderIndex);

          const domainsNumber = parralels?.[date];
          const maxParallelDomains = Math.max(...Object.values(parralels || {}));
          const MAX_PARALLEL_HEIGHT = 62;
          const PARALLEL_BASE_HEIGHT = 39;
          if (domainsNumber) {
            domainContainer.innerHTML = `<span style="height: ${
              Math.round(((MAX_PARALLEL_HEIGHT - PARALLEL_BASE_HEIGHT) / maxParallelDomains) * domainsNumber) + PARALLEL_BASE_HEIGHT
            }px; max-height: ${MAX_PARALLEL_HEIGHT}px;${
              domainsNumber !== maxParallelDomains ? 'background-color: #F8D57F' : ''
            }">${domainsNumber}</span>`;
          }
          const isColumnRendered = renderedDomainRanges?.current?.[dateOrderIndex];
          if (!isColumnRendered) {
            domainsList?.current?.appendChild(domainContainer);
            renderedDomainRanges.current[dateOrderIndex] = true;
          }
        }
      });
  };
  const handleScroll = (domains?: ParralelDomainResourse) => {
    scrollTimeoutRef.current && clearTimeout(scrollTimeoutRef.current);
    scrollTimeoutRef.current = setTimeout(() => {
      renderDomainColumns(domains);
    }, 100);
  };

  useEffect(() => {
    if (document && parallelDomains && footerDomainWidth && firstTimeScaleItem) {
      domainsList.current && (domainsList.current.innerHTML = '');
      renderedDomainRanges.current = Object.fromEntries(Object.entries(renderedDomainRanges.current).map(([key]) => [key, false]));
      renderDomainColumns(parallelDomains);
    }
  }, [document, parallelDomains, selectedDomain, footerDomainWidth, firstTimeScaleItem]);

  useEffect(() => {
    gantHeader && parallelDomains && renderDomainColumns(parallelDomains);
    ganttZone?.addEventListener('scroll', () => handleScroll(parallelDomains));

    return () => {
      ganttZone?.removeEventListener('scroll', () => handleScroll(parallelDomains));
      scrollTimeoutRef.current && clearTimeout(scrollTimeoutRef.current);
    };
  }, [gantHeader, parallelDomains, firstTimeScaleItem]);

  if (isProjectsLoading || isQGLoading) {
    return (
      <div className={styles.preloader}>
        <Loader />
      </div>
    );
  } else {
    return (
      <div className={classNames(styles.schedule, { [styles.schedule_isLoading]: isScaleCalculated }, 'dx-viewport')}>
        <button
          type='button'
          id='collapseButton'
          className={styles.collapseHelperButton}
          onClick={() => {
            collapseLevel ? ganttRef?.current?.instance?.expandAllToLevel(1) : ganttRef?.current?.instance?.collapseAll();
          }}
        />
        {projects
        && (isLanguageSwitching ? (
          <div className={styles.preloader}>
            <Loader />
          </div>
        ) : (
          <div>
            {/* Placing the search input here became necessary due to constant re-rendering of TasksHeader,
            which caused the input to lose focus after each key press */}
            {taskListWidth === TasksListSize.FULL && (
              <div className={styles.search}>
                <svg className={styles.search__icon}>
                  <use
                    xlinkHref={`${SearchSVG}#searchSVG`}
                    href={`${SearchSVG}#searchSVG`}
                  />
                </svg>
                <Input
                  id='portfoli_search'
                  value={searchValue}
                  setValue={(e) => setSearchValue(e.target.value)}
                  size='small'
                  className={styles.searchInput}
                  placeholder={t('Search projects')}
                />
              </div>
            )}
            <Gantt
              {...ganttConfig}
              ref={ganttRef}
              scaleType={scaleType}
              taskListWidth={
                taskListWidth === TasksListSize.FULL
                  ? (isMobileDevice ? SIDEBAR_TABLET_WIDTH : SIDEBAR_DESKTOP_WIDTH) : MINIMIZED_SIDEBAR_WIDTH
              }
              onContentReady={onGanttInit}
              firstDayOfWeek={1}
            >
              <StripLine
                start={dateNow}
                title={t('Current Time')}
                cssClass={styles.todayMark}
              />

              <Tasks
                dataSource={projects}
                keyExpr='id'
                parentIdExpr='project_id'
                titleExpr='caption'
                startExpr='start_date'
                endExpr='finish_date'
              />

              {selectedDomain && (
                <ScaleTypeRange
                  min={scaleType}
                  max={scaleType}
                />
              )}

              <Column
                headerCellComponent={TasksHeader}
                cssClass={styles.selectCell}
              >
                <Column
                  dataField='name'
                  width={taskListWidth === TasksListSize.MINIMIZED ? 0 : isMobileDevice ? 168 : 254}
                  cellComponent={TaskCell}
                  headerCellComponent={TaskHeaderCell}
                  allowSorting={false}
                  cssClass={classNames(styles.cell, {
                    [styles.cell_hidden]: taskListWidth === TasksListSize.MINIMIZED,
                  })}
                />
                <Column
                  width={taskListWidth === TasksListSize.MINIMIZED ? 0 : 170}
                  caption={t('Duration')}
                  calculateCellValue={getDuration}
                  cellComponent={DurationCell}
                  headerCellComponent={DurationHeaderCell}
                  cssClass={classNames(styles.cell, {
                    [styles.cell_hidden]: taskListWidth === TasksListSize.MINIMIZED,
                  })}
                />
              </Column>
            </Gantt>
            <footer
              className={styles.footer}
              ref={ganttFooter}
            >
              <div
                className={styles.footer__sidebar}
                style={{
                  width:
                    taskListWidth === TasksListSize.FULL
                      ? window.innerWidth >= DESKTOP_BREAKPOINT
                        ? SIDEBAR_DESKTOP_WIDTH
                        : SIDEBAR_TABLET_WIDTH
                      : MINIMIZED_SIDEBAR_WIDTH,
                }}
              >
                {taskListWidth === TasksListSize.FULL && (
                  <>
                    <p className={styles.footer__sidebar__title}>{t('Project domains running in parallel')}</p>
                    <Select
                      className={styles.footer__sidebar__select}
                      paperClassName={styles.footer__sidebar__selectPaper}
                      options={domainOptions || []}
                      value={selectedDomain}
                      name='selectedDomain'
                      labelId='selectedDomain'
                      setValue={(e) => setSelectedDomain((e as SelectChangeEvent).target.value)}
                      type='tile'
                      label={t('Select domain')}
                      // it's a trick to prevent the selected option from disappearing when the language is changed
                      renderValue={() => <span>{selectedDomain}</span>}
                    />
                  </>
                )}
              </div>
              <div
                style={{ width: ganttWidth || 0 }}
                className={styles.footer__track}
              >
                {!!(parallelDomainsQuantity && footerDomainWidth && selectedDomain) && (
                  <div
                    className={classNames(styles.footer__domains, 'domains')}
                    style={{ width: parallelDomainsQuantity * footerDomainWidth }}
                    ref={domainsList}
                  />
                )}
                {!!footerTodayMarkPosition && (
                  <div
                    className={styles.footer__todayMark}
                    style={{ left: footerTodayMarkPosition }}
                    ref={footerTodayMark}
                  />
                )}
              </div>
            </footer>
          </div>
        ))}
      </div>
    );
  }
};

const PortfolioGantt = () => (
  <AutorebootErrorBoundary fallback={<div className={styles.preloader}><Loader /></div>}>
    <Suspense
      fallback={(
        <div className={styles.preloader}>
          <Loader />
        </div>
    )}
    >
      <PortfolioGanttContent />
    </Suspense>
  </AutorebootErrorBoundary>
);

export default PortfolioGantt;
