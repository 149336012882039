import React from 'react';
import SummaryDeliverables from './SummaryDeliverables.tsx';
import SummaryAddons from './SummaryAddons.tsx';
import SummaryParameters from './SummaryParameters.tsx';
import SummaryRiskTasks from './SummaryRiskTasks.tsx';
import SummaryScore from './SummaryScore.tsx';
import SummarySkills from './SummarySkills.tsx';
import SummaryStakeholders from './SummaryStakeholders.tsx';
import styles from './SummaryDashboard.module.scss';

const SummaryDashboard = () => (
  <div className={styles.content}>
    <SummaryParameters />
    <SummaryAddons />
    <SummaryScore />
    <SummaryRiskTasks />
    <SummaryDeliverables />
    <SummarySkills />
    <SummaryStakeholders />
  </div>
);

export default SummaryDashboard;
