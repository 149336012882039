import { redirect } from 'react-router-dom';
import { getAuthorizeToken } from '../utils';
import apiClient from '../apiClient.ts';
import { Permissions } from '../components/pages/Login/user.props.ts';
import { ClientProps } from '../components/AdminSidebar/client.props.ts';
import { AuthStorageKey } from '../store/auth.ts';
import { Redirect } from '../components/pages/NewProject/types.ts';

export const GENERAL_FEATURE_FLAG_KEY = 'feature-flag';
export const DEV_FEATURE_FLAG_KEY = 'feature-flag-dev';

export const isUserHasFeatureAccess = (featureLevel: typeof GENERAL_FEATURE_FLAG_KEY | typeof DEV_FEATURE_FLAG_KEY) => {
  const userHasAccess = localStorage.getItem(featureLevel);
  return !!userHasAccess;
};

export async function getClients() {
  try {
    const { response: { data }, statusCode } = await apiClient.get<{ data: ClientProps[] }>('clients');

    if (statusCode !== 200) {
      return [];
    }

    return data;
  } catch (e) {
    console.error(e);
    return [];
  }
}

const getClient = async (id: number) => {
  try {
    const { response: { permissions } } = await apiClient.get<{ data: ClientProps, permissions: string[] }>(`clients/${id}`);
    return permissions;
  } catch (e) {
    console.error(e);
    return [];
  }
};

export async function mainLoader() {
  const authorizeToken = getAuthorizeToken();

  if (!authorizeToken) {
    return redirect('/login');
  }

  const clients = await getClients();
  if (!clients.length) return redirect('/not-found?logout=true');

  const client = clients[0];
  const permissions = await getClient(client.id);
  let hasAdminPermission = false;

  if (permissions.length) {
    localStorage.setItem(AuthStorageKey.CLIENT_PERMISSIONS, JSON.stringify(permissions));
    hasAdminPermission = permissions.includes(Permissions.ADMIN) ?? false;
  }

  localStorage.setItem(AuthStorageKey.USER_CLIENT, JSON.stringify(client));

  return redirect(hasAdminPermission ? Redirect.MANAGE : Redirect.DASHBOARD);
}
