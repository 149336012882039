import React from 'react';
import useSWR from 'swr';
import classNames from 'classnames';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advanced from 'dayjs/plugin/advancedFormat';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAtomValue } from 'jotai';

import Loader from '../../../Loader/Loader';

import apiClient from '../../../../apiClient';
import { QualityGateHistoryResource } from '../types';
import { DEFAULT_DATE_FORMAT } from '../../../../constants';
import { userAtom } from '../../../../store/auth';

import EmptyHistorySVG from '../../../../public/media/empty-history.svg';

import styles from './QualityGateHistory.module.scss';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advanced);

type HistoryGroup = {
  [key: string]: { history: QualityGateHistoryResource[]; date: string };
};

const formatHistory = (history: QualityGateHistoryResource[]) => history.reverse().reduce((acc, curr) => {
  const date = new Date(curr.created_at).toLocaleDateString();
  if (acc[date]) {
    acc[date].history.push(curr);
  } else {
    acc[date] = { history: [curr], date: curr.created_at };
  }
  return acc;
}, {} as HistoryGroup);

const QualityGateHistory = () => {
  const { t, i18n } = useTranslation();
  const { projectId, gateId } = useParams();
  const userData = useAtomValue(userAtom);

  const {
    data: gateHistory,
    isLoading,
    isValidating,
  } = useSWR(
    [`projects/${projectId}/quality-gates/${gateId}/histories`, i18n.language, gateId, projectId],
    ([url]) => apiClient.get<{ data: QualityGateHistoryResource[] }>(url).then(({ response }) => {
      const formattedHistory = formatHistory(response.data);
      return formattedHistory;
    }),
    {
      keepPreviousData: true,
      revalidateIfStale: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: true,
      revalidateOnMount: true,
    },
  );

  return (
    <section className={classNames(styles.history, {
      [styles.history_noContent]: !gateHistory || !Object.values(gateHistory).length,
      [styles.history_revalidating]: isValidating,
    })}
    >
      {isLoading ? (
        <Loader />
      ) : gateHistory && !Object.values(gateHistory).length ? (
        <div className={styles.placeholder}>
          <svg className={styles.placeholder__img}>
            <use
              xlinkHref={`${EmptyHistorySVG}#emptyHistorySVG`}
              href={`${EmptyHistorySVG}#emptyHistorySVG`}
            />
          </svg>
          <p className={styles.placeholder__title}>{t('History is empty')}</p>
        </div>
      ) : (
        gateHistory
        && Object.values(gateHistory).map((group) => (
          <div
            className={styles.history__group}
            key={group.date}
          >
            <div className={styles.history__group__label}>
              <p className={styles.history__group__date}>{dayjs(group.date).format(userData?.user?.dateFormat ?? DEFAULT_DATE_FORMAT)}</p>
              <div className={styles.history__group__line} />
            </div>
            <ul className={styles.history__group__list}>
              {group.history.map((history) => (
                <li
                  key={history.id}
                  className={styles.history__group__item}
                >
                  <p className={styles.history__group__item__time}>
                    {dayjs(`${history.created_at} UTC`).format('HH:mm z')}
                    :
                  </p>
                  <p className={styles.history__group__item__text}>{history.data}</p>
                </li>
              ))}
            </ul>
          </div>
        ))
      )}
    </section>
  );
};

export default QualityGateHistory;
