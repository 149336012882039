import { useTranslation } from 'react-i18next';
import {
  Outlet, useLoaderData,
} from 'react-router-dom';
import classNames from 'classnames';

import AdminSidebar from '../../AdminSidebar/AdminSidebar';
import Header from '../../Header/Header';
import { ClientProps } from '../../AdminSidebar/client.props.ts';

import styles from './Archive.module.scss';
import SubHeader from '../../SubHeader/SubHeader.tsx';

const Archive = () => {
  const { isGlobalAdmin, clients } = useLoaderData() as { isGlobalAdmin: boolean; clients: ClientProps[] };
  const { t } = useTranslation();

  return (
    <div className={styles.page}>
      {isGlobalAdmin && <AdminSidebar clients={clients} />}
      <main className={styles.wrapper}>
        <Header showLogo={!isGlobalAdmin} />
        <div className={classNames(styles.inner, styles.noPadding)}>
          <SubHeader title={t('Project archive')} />
          <div className={styles.block}>
            <div className={styles.content}>
              <Outlet />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Archive;
