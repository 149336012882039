import { UserResource } from '../Users/UsersList/types';

export type UserProps = {
  token: string;
  permissions?: string[];
  user: UserResource;
};

export enum Permissions {
  ADMIN = 'admin',
  TRANSLATIONS = 'translations',
  USER_MANAGE = 'u-manage',
  USER_VIEW = 'u-view',
  USER_UPDATE = 'u-update',
  USER_CREATE = 'u-create',
  USER_DELETE = 'u-delete',
  CLIENT_VIEW = 'c-view',
  CLIENT_UPDATE = 'c-update',
  CLIENT_CREATE = 'c-create',
  CLIENT_DELETE = 'c-delete',
  CLIENT_MANAGE = 'c-manage',
  PROJECT_VIEW = 'p-view',
  PROJECT_UPDATE = 'p-update',
  PROJECT_CREATE = 'p-create',
  PROJECT_DELETE = 'p-delete',
  PROJECT_MANAGE = 'p-manage',
  PROJECT_ARCHIVE = 'p-archive',
  CUSTOMER_VIEW = 'cu-view',
  CUSTOMER_UPDATE = 'cu-update',
  CUSTOMER_CREATE = 'cu-create',
  CUSTOMER_DELETE = 'cu-delete',
  TEMPLATE_VIEW = 't-view',
  SALEFORCES = 'salesforce',
  QUALITY_GATE_CREATE = 'qg-create',
  QUALITY_GATE_UPDATE = 'qg-update',
  QUALITY_GATE_DELETE = 'qg-delete',
  QUALITY_GATE_EXECUTE = 'qg-execute',
  PORTFOLIO = 'pf-view',
  INTEGRATION_VIEW = 'tool-integration-view',
  INTEGRATION_SAVE = 'tool-integration-save',
  JIRA_PROJECT_MANAGE = 'jira-manage',
  DELIVERABLE_CREATE = 'deliverable-create',
  DELIVERABLE_UPDATE = 'deliverable-update',
  DELIVERABLE_DELETE = 'deliverable-delete',
}

export enum ProjectPermissions {
  VIEW = 'view',
  UPDATE = 'update',
  ARCHIVE = 'archive',
  MANAGE = 'manage',
  DELETE = 'delete',
  RESTORE = 'restore',
  CREATE = 'create',
  EXECUTE = 'execute',
}
