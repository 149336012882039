import classNames from 'classnames';
import { FormikValues, useFormik } from 'formik';
import { useAtomValue } from 'jotai/index';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import apiClient from '../../../../../apiClient.ts';
import { NotificationStatus, notify } from '../../../../../store/notifications.ts';
import Button from '../../../../UIKit/Button/Button.tsx';
import Input from '../../../../UIKit/Input/Input.tsx';
import { Deliverable } from '../manageDeliverables.types';
import { activeTask } from '../manageDeliverables.atom.ts';
import styles from './AddDeliverableForm.module.scss';

type CreateDeliverableData = {
  caption: {
    en: string,
    de: string,
  },
  task_id: number,
  order: number,
};
type CreateDeliverableProps = {
  projectId: string,
  data: CreateDeliverableData
};

const createDeliverable = async ({ projectId, data }: CreateDeliverableProps) => {
  try {
    const { statusCode, response } = await apiClient.post<{ data: Deliverable }>(`projects/${projectId}/deliverables`, {
      body: JSON.stringify(data),
    });
    return { statusCode, response };
  } catch (e) {
    throw new Error(e);
  }
};

type AddDeliverableFormProps = {
  closeForm: () => void,
  addDeliverable: (deliverable: Deliverable) => void,
  lastOrder: number,
};

const AddDeliverableForm = ({ closeForm, addDeliverable, lastOrder }: AddDeliverableFormProps) => {
  const { t } = useTranslation();
  const { projectId } = useParams();
  const currentTask = useAtomValue(activeTask);

  const {
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    errors,
    isSubmitting,
  }: FormikValues = useFormik({
    initialValues: {
      titleEn: '',
      titleDe: '',
    },
    validationSchema: Yup.object({
      titleEn: Yup.string()
        .trim()
        .max(100, t('Must be 100 characters at most'))
        .required(t('English title is required')),
      titleDe: Yup.string()
        .trim()
        .max(100, t('Must be 100 characters at most'))
        .required(t('German title is required')),
    }),
    onSubmit: async (submitValues) => {
      try {
        const { statusCode, response } = await createDeliverable({
          projectId: projectId!,
          data: {
            caption: {
              en: submitValues.titleEn,
              de: submitValues.titleDe,
            },
            task_id: currentTask!.id,
            order: lastOrder + 1,
          },
        });
        if (statusCode === 201) {
          notify({ status: NotificationStatus.SUCCESS, text: { title: t('Success!'), body: t('Deliverable added') } });
          closeForm();
          addDeliverable({ ...response.data, status: { value: 2, caption: t('Internal') } });
        } else {
          throw new Error();
        }
      } catch (e) {
        notify(e?.message ? { text: { body: e.message } } : {});
        console.error(e.message);
      }
    },
  });

  return (
    <form
      onSubmit={handleSubmit}
      className={styles.form}
    >
      <div className={styles.group}>
        <div className={classNames(styles.group__content, styles.form__block)}>
          <div>
            <p className={styles.group__subtitle}>{t('Enter name')}</p>
            <p className={styles.group__text}>{t('Indicate the name of deliverables in 2 languages')}</p>
          </div>
          <Input
            value={values.titleEn}
            setValue={handleChange}
            onBlur={handleBlur}
            id='titleEn'
            label={t('Enter deliverable name in English')}
            name='titleEn'
            error={!!(touched.titleEn && errors.titleEn)}
            errorMessage={errors.titleEn}
            className={classNames(styles.form__input_en, styles.form__input)}
          />
          <Input
            value={values.titleDe}
            setValue={handleChange}
            onBlur={handleBlur}
            id='titleDe'
            label={t('Enter deliverable name in German')}
            name='titleDe'
            error={!!(touched.titleDe && errors.titleDe)}
            errorMessage={errors.titleDe}
            className={classNames(styles.form__input_de, styles.form__input)}
          />
        </div>
      </div>
      <div className={styles.form__footer}>
        <Button
          type='submit'
          loading={isSubmitting}
          disabled={isSubmitting}
        >
          {t('Confirm')}
        </Button>
      </div>
    </form>
  );
};

export default AddDeliverableForm;
