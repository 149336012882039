import { Outlet, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Header from '../../Header/Header.tsx';
import styles from './Dashboard.module.scss';
import SubHeader from '../../SubHeader/SubHeader.tsx';

const Dashboard = () => {
  const { pathname } = useLocation();
  const { t } = useTranslation();

  return (
    <>
      <Header />
      {pathname.includes('archive') && <SubHeader title={t('Project archive')} />}
      <main className={styles.page}>
        <Outlet />
      </main>
    </>
  );
};

export default Dashboard;
