import { IGanttOptions } from 'devextreme-react/gantt';
import { atom } from 'jotai';
import { StatusState } from '../../UIKit/StatusLabel/types';

type PortfolioQualityGateResourse = {
  id: number;
  caption: string;
  due_date: Date;
  status: {
    value: number;
    caption: string;
    state: StatusState;
  };
  project_id: number;
};

export const scaleAtom = atom<IGanttOptions['scaleType']>('months');

export enum TasksListSize {
  FULL = 'full',
  MINIMIZED = 'minimized',
}

export enum SortType {
  PRIORITY = 'priority',
  START_DATE = 'begin',
  END_DATE = 'end',
  NAME = 'caption',
}
export const sortAtom = atom<SortType>(SortType.PRIORITY);
export const qgAtom = atom<Record<number, PortfolioQualityGateResourse[]>>({});
export const collapseLevelAtom = atom<number>(0);
export const selectedPortfolioDomain = atom<string>('');

export const taskListWidthAtom = atom<TasksListSize>(TasksListSize.FULL);
