import React from 'react';
import classNames from 'classnames';
import useSWR, { mutate } from 'swr';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useAtomValue, useSetAtom } from 'jotai';
import * as Yup from 'yup';

import Input from '../../UIKit/Input/Input';
import Button from '../../UIKit/Button/Button';

import apiClient from '../../../apiClient';
import { notify } from '../../../store/notifications';
import { twoFARecoveryCode, userAtom } from '../../../store/auth';

import ErrorSVG from '../../../public/media/error.svg';

import styles from './TwoFactorAuthForm.module.scss';

const TwoFactorAuthForm = () => {
  const { t } = useTranslation();
  const userData = useAtomValue(userAtom);
  const setRecoveryCode = useSetAtom(twoFARecoveryCode);

  const { data: qrCode, mutate: updateQRCode } = useSWR(['user/2fa/enable'], ([url]) => apiClient
    .get<{
    secret: 'string',
    qrCode: 'string'
  } >(url).then(({ response }) => response), {
    keepPreviousData: false,
    revalidateOnMount: true,
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const enable2FA = async (values: { password: string, code: string }) => {
    try {
      const { response, statusCode } = await apiClient
        .post<{ message?: string, secret_codes: string[] }>('user/2fa/confirm', { body: JSON.stringify(values) });
      if (statusCode === 200) {
        mutate((key: any[]) => key.includes('user'), { ...userData?.user, '2fa': true }, { revalidate: false });
        setRecoveryCode(response.secret_codes);
        return response;
      } if (statusCode === 423) {
        updateQRCode();
        throw new Error(t(`${response.message} Scan code and try again`));
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      notify({ text: { body: t(error.message) } });
      throw new Error(error);
    }
  };

  const {
    handleSubmit, values, handleChange, handleBlur, touched, errors, isSubmitting,
  } = useFormik({
    initialValues: {
      password: '',
      code: '',
    },
    validationSchema: Yup.object({
      password: Yup.string().required(),
      code: Yup.string().required(),
    }),
    onSubmit: (fromikValues) => enable2FA(fromikValues),
  });

  return (
    <section className={styles.twoFactorAuth}>
      <p className={styles.twoFactorAuth__subtitle}>
        {t('Use a one-time password authenticator on your mobile device or computer to enable two-factor authentication (2FA).')}
      </p>
      <div className={styles.twoFactorAuth__tilesSection}>
        <div className={classNames(styles.twoFactorAuth__block, styles.twoFactorAuth__block_qrCode)}>
          <div className={classNames(styles.twoFactorAuth__textContent)}>
            <p className={styles.twoFactorAuth__block__paragraph}>
              {t('We recommended using cloud-based authenticator applications that can restore access if you lose your hardware device.')}
            </p>
            <div>
              <p className={styles.twoFactorAuth__block__subtitle}>{t('Example of applications:')}</p>
              <p className={styles.twoFactorAuth__block__paragraph}>{t('Duo, Authy, Microsoft or Google Authenticator')}</p>
            </div>
          </div>
          {qrCode?.qrCode && (
            <div
              className={styles.twoFactorAuth__code}
              dangerouslySetInnerHTML={{ __html: qrCode?.qrCode }}
            />
          )}
        </div>
        <div className={styles.twoFactorAuth__block}>
          <div className={styles.twoFactorAuth__block__title}>
            <svg>
              <use
                xlinkHref={`${ErrorSVG}#errorSVG`}
                href={`${ErrorSVG}#errorSVG`}
              />
            </svg>
            <p>{t('Can`t scan the code?')}</p>
          </div>
          <p className={styles.twoFactorAuth__block__paragraph}>
            {t('To add the entry manually, provide the following details to the application on your phone.')}
          </p>
          <div>
            <div className={styles.twoFactorAuth__block__prop}>
              <p className={styles.twoFactorAuth__block__paragraph}>
                {t('Account')}
                :
              </p>
              <p className={styles.twoFactorAuth__block__paragraph}>{userData?.user.email}</p>
            </div>
            <div className={styles.twoFactorAuth__block__prop}>
              <p className={styles.twoFactorAuth__block__paragraph}>
                {t('Key')}
                :
              </p>
              <p className={styles.twoFactorAuth__block__paragraph}>{qrCode?.secret}</p>
            </div>
          </div>
        </div>
      </div>
      <form
        className={styles.twoFactorAuth__form}
      >
        <h3 className={styles.twoFactorAuth__form__title}>{t('Confirmation of authenticator registration')}</h3>
        <div>
          <Input
            value={values.password}
            setValue={handleChange}
            onBlur={handleBlur}
            id='password'
            label={t('Current password')}
            name='password'
            error={!!(touched.password && errors.password)}
            errorMessage={errors.password}
            type='password'
          />
          <p className={styles.twoFactorAuth__form__clue}>
            {t('Your current password is required to register a two-factor authenticator')}
          </p>
        </div>
        <Input
          value={values.code}
          setValue={handleChange}
          onBlur={handleBlur}
          id='code'
          label={t('Verification code')}
          name='code'
          error={!!(touched.code && errors.code)}
          errorMessage={errors.code}
        />
      </form>
      <div className={styles.twoFactorAuth__footer}>
        <Button
          onClick={() => handleSubmit()}
          loading={isSubmitting}
          disabled={isSubmitting}
          className={styles.twoFactorAuth__form__btn}
        >
          {t('Register with two-factor app')}
        </Button>
      </div>
    </section>
  );
};

export default TwoFactorAuthForm;
