import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import Popover, { PopoverPlacement } from '../../../../UIKit/Popover/Popover.tsx';
import styles from './DeliverableOptionsDropdown.module.scss';
import DotsSVG from '../../../../../public/media/dots.svg';
import DeleteSVG from '../../../../../public/media/delete-icon.svg';
import { ProjectPermissions } from '../../../Login/user.props.ts';

type DeliverableOptionsDropdownProps = {
  permissions: string[]
  onDeleteClick: () => void,
};

const DeliverableOptionsDropdown = ({ permissions, onDeleteClick }: DeliverableOptionsDropdownProps) => {
  const { t } = useTranslation();
  const [closePopover, setClosePopover] = useState<() => void>(() => {
  });

  const closeCallback = (func: () => void) => {
    setClosePopover(() => func);
  };

  if (!permissions.includes(ProjectPermissions.DELETE) || !permissions.includes(ProjectPermissions.UPDATE)) return null;

  return (
    <Popover
      placement={PopoverPlacement.RIGHT}
      paperClassName={styles.paper}
      triggerButton={(
        <div className={styles.trigger}>
          <svg>
            <use
              xlinkHref={`${DotsSVG}#dotsSVG`}
              href={`${DotsSVG}#dotsSVG`}
            />
          </svg>
        </div>
      )}
      closeCallback={closeCallback}
    >
      <div className={styles.menu}>
        {permissions.includes(ProjectPermissions.DELETE) && (
          <button
            type='button'
            onClick={() => {
              onDeleteClick();
              closePopover();
            }}
          >
            <svg>
              <use
                xlinkHref={`${DeleteSVG}#deleteSVG`}
                href={`${DeleteSVG}#deleteSVG`}
              />
            </svg>
            {t('Remove')}
          </button>
        )}
      </div>
    </Popover>
  );
};

export default DeliverableOptionsDropdown;
