import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Tooltip } from '@mui/material';
import classNames from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import apiClient from '../../../../../apiClient.ts';
import DeleteSVG from '../../../../../public/media/delete-icon.svg';
import CancelSVG from '../../../../../public/media/cancel.svg';
import DragSVG from '../../../../../public/media/drag.svg';
import EditSVG from '../../../../../public/media/edit-icon.svg';
import InfoSVG from '../../../../../public/media/info.svg';
import { NotificationStatus, notify } from '../../../../../store/notifications.ts';
import CheckboxItem from '../../../../UIKit/CheckboxItem/CheckboxItem.tsx';
import PopoverOptions from '../../../../UIKit/PopoverOptions/PopoverOptions.tsx';
import ToggleButtonGroup from '../../../../UIKit/ToggleButtonGroup/ToggleButtonGroup.tsx';
import { DeliverableStatus } from '../../NewProjectDeliverables/types.ts';
import { Deliverable } from '../manageDeliverables.types';
import styles from './SortableDeliverable.module.scss';

type DeleteDeliverableProps = {
  projectId: number,
  deliverableId: number,
};

const deleteDeliverable = async ({ projectId, deliverableId }: DeleteDeliverableProps) => {
  try {
    const { statusCode } = await apiClient.delete(`projects/${projectId}/deliverables/${deliverableId}`);
    return statusCode;
  } catch (e) {
    throw new Error(e);
  }
};

type UpdateDeliverableProps = {
  projectId: number,
  id: number,
  status: DeliverableStatus,
};

const updateDeliverable = async ({ projectId, id, status }: UpdateDeliverableProps) => {
  try {
    const { statusCode } = await apiClient.put<{
      url: string
    }>(`projects/${projectId}/deliverables/${id}`, { body: JSON.stringify({ status }) });
    return statusCode;
  } catch (e) {
    notify(e?.message ? { text: { body: e.message } } : {});
    console.error(e);
  }
};

type SortableDeliverableProps = {
  deliverable: Deliverable,
  draggingElement?: boolean,
  removeDeliverable?: (id: number) => void,
  openEditForm?: (deliverable: Deliverable) => void,
  custom?: boolean,
};
const SortableDeliverable = ({
  deliverable, draggingElement, removeDeliverable, openEditForm, custom,
}: SortableDeliverableProps) => {
  const [status, setStatus] = useState<DeliverableStatus>(deliverable.status.value);
  const { projectId } = useParams();

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: deliverable.id,
    data: { deliverable: { ...deliverable, status: { value: status } } },
    disabled: !deliverable.custom,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const { t } = useTranslation();

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const closeDeleteConfirmation = () => {
    setTimeout(() => {
      setShowDeleteConfirmation(false);
    }, 300);
  };
  const options = [
    {
      id: 0,
      title: (
        <div className={styles.option}>
          <svg>
            <use
              xlinkHref={`${EditSVG}#editSVG`}
              href={`${EditSVG}#editSVG`}
            />
          </svg>
          <p>{t('Edit')}</p>
        </div>
      ),
      handler: () => {
        openEditForm && openEditForm(deliverable);
        closeDeleteConfirmation();
      },
    },
    {
      id: 1,
      title: (
        <div className={classNames(styles.option, { [styles.option_delete]: !showDeleteConfirmation })}>
          <svg>
            <use
              xlinkHref={`${showDeleteConfirmation ? CancelSVG : DeleteSVG}#${showDeleteConfirmation ? 'cancelSVG' : 'deleteSVG'}`}
              href={`${showDeleteConfirmation ? CancelSVG : DeleteSVG}#${showDeleteConfirmation ? 'cancelSVG' : 'deleteSVG'}`}
            />
          </svg>
          <p>{t(showDeleteConfirmation ? 'Cancel deleting' : 'Delete')}</p>
        </div>
      ),
      handler: () => {
        setShowDeleteConfirmation(prev => !prev);
      },
      preventClose: true,
    },
    {
      id: 2,
      title: (
        <div className={classNames(styles.option, styles.option_confirmDelete)}>
          {t('Yes, delete deliverable')}
        </div>
      ),
      handler: async () => {
        try {
          const statusCode = await deleteDeliverable({ projectId: +projectId!, deliverableId: deliverable.id });
          if (statusCode === 204) {
            notify({ status: NotificationStatus.SUCCESS, text: { title: t('Success!'), body: t('Deliverable deleted') } });
            removeDeliverable && removeDeliverable(deliverable.id);
          }
        } catch (e) {
          notify(e?.message ? { text: { body: e.message } } : {});
          console.error(e.message);
        }
        // closeDeleteConfirmation();
      },
      hidden: !showDeleteConfirmation,
    },
  ];

  const draggableAttributes = deliverable.custom ? attributes : {};
  const draggableListeners = deliverable.custom ? listeners : {};

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...draggableAttributes}
      className={classNames(styles.deliverable, {
        [styles.isDragging]: isDragging,
        [styles.draggingElement]: draggingElement,
        [styles.custom]: custom,
      })}
    >
      {custom && (
      <button
        disabled={!deliverable.custom}
        type='button'
        className={classNames(styles.dragButton, {
          [styles.isGrabbing]: draggingElement,
          [styles.disabled]: !deliverable.custom,
        })}
        {...draggableListeners}
      >
        <svg>
          <use
            xlinkHref={`${DragSVG}#dragSVG`}
            href={`${DragSVG}#dragSVG`}
          />
        </svg>
      </button>
      )}
      <CheckboxItem
        labelClassName={styles.checkbox}
        value={status !== DeliverableStatus.DISABLED}
        onChange={() => {
          const newStatus = status === DeliverableStatus.DISABLED
            ? DeliverableStatus.INTERNAL : DeliverableStatus.DISABLED;
          setStatus(() => newStatus);
          updateDeliverable({ projectId: +projectId!, id: deliverable.id, status: newStatus });
        }}
      />
      <p className={styles.deliverable__caption}>{deliverable.caption}</p>
      <div className={styles.deliverable__controls}>
        <ToggleButtonGroup
          className={styles.deliverable__status}
          disabled={status === DeliverableStatus.DISABLED}
          options={[t('Internal'), t('External')]}
          value={status === DeliverableStatus.DISABLED
            ? null : status === DeliverableStatus.INTERNAL
              ? t('Internal') : t('External')}
          setValue={(buttonValue) => {
            const newStatus = buttonValue === t('Internal')
              ? DeliverableStatus.INTERNAL : DeliverableStatus.EXTERNAL;
            setStatus(newStatus);
            updateDeliverable({ projectId: +projectId!, id: deliverable.id, status: newStatus });
          }}
        />
        {custom && (
        <div className={classNames(styles.deliverable__options, {
          [styles.disabled]: !deliverable.custom,
        })}
        >
          <PopoverOptions
            options={options}
            buttonClassName={styles.deliverable__optionsButton}
            paperClassName={styles.deliverable__optionsPaper}
            onClose={closeDeleteConfirmation}
          />
        </div>
        )}
        {!deliverable.custom && (
          <Tooltip
            classes={{
              tooltip: styles.tooltip,

            }}
            arrow
            title={(
            deliverable.custom
              ? <p>{t('A deliverable tag is needed for requesting a vendor quote for this deliverable')}</p>
              : deliverable.description?.length > 0 ? (
                <div dangerouslySetInnerHTML={{ __html: deliverable.description }} />
              ) : <p>{t('Description is not available')}</p>
          )}
            placement='bottom-start'
          >
            <svg className={styles.deliverable__infoIcon}>
              <use
                xlinkHref={`${InfoSVG}#infoSVG`}
                href={`${InfoSVG}#infoSVG`}
              />
            </svg>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default SortableDeliverable;
