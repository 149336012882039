/* eslint-disable react/destructuring-assignment */
import React, { Component, ReactNode } from 'react';
import Loader from '../Loader/Loader';
import styles from './AutorebootErrorBoundary.module.scss';

interface AutorebootErrorBoundaryProps {
  children: ReactNode;
  fallback?: ReactNode;
}

interface AutorebootErrorBoundaryState {
  hasError: boolean;
  errorKey: number;
}

class AutorebootErrorBoundary extends Component<AutorebootErrorBoundaryProps, AutorebootErrorBoundaryState> {
  constructor(props: AutorebootErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
      errorKey: 0,
    };
  }

  static getDerivedStateFromError(): Partial<AutorebootErrorBoundaryState> {
    return { hasError: true };
  }

  componentDidCatch(): void {
    setTimeout(() => {
      this.resetError();
    }, 500);
  }

  resetError = (): void => {
    this.setState(prevState => ({
      hasError: false,
      errorKey: prevState.errorKey + 1, // Increment key to remount component
    }));
  };

  render() {
    if (this.state.hasError) {
      return this.props.fallback
        || (
        <div className={styles.preloader}>
          <Loader />
        </div>
        );
    }

    return (
      // eslint-disable-next-line react/destructuring-assignment
      <div key={this.state.errorKey}>
        {this.props.children}
      </div>
    );
  }
}

export default AutorebootErrorBoundary;
