import { useAtomValue } from 'jotai/index';
import { useTranslation } from 'react-i18next';
import { userClientAtom } from '../../../../store/auth.ts';
import { ClientProps } from '../../../AdminSidebar/client.props.ts';
import ClientsHeaderDropdown from '../ClientsHeaderDropdown/ClientsHeaderDropdown.tsx';
import styles from './ClientsHeader.module.scss';

type ClientsHeaderProps = {
  isGlobalAdmin: boolean,
  activeClient?: ClientProps,
};
const ClientsHeader = ({ isGlobalAdmin, activeClient }: ClientsHeaderProps) => {
  const { t } = useTranslation();
  const currentClient = useAtomValue(userClientAtom);

  return (
    <header className={styles.header}>
      <div className={styles.titleBlock}>
        {isGlobalAdmin && activeClient && <p className={styles.name}>{currentClient.name}</p>}
        <h1 className={styles.title}>{t(isGlobalAdmin ? 'Dashboard' : 'My Dashboard')}</h1>
      </div>
      {activeClient && <ClientsHeaderDropdown {...{ activeClient, isGlobalAdmin }} />}
    </header>
  );
};

export default ClientsHeader;
