import { debounce, useMediaQuery } from '@mui/material';
import classNames from 'classnames';
import dayjs from 'dayjs';
import Gantt, {
  Column, ScaleTypeRange, StripLine, Tasks,
} from 'devextreme-react/gantt';
import { TreeListTypes } from 'devextreme-react/tree-list';
import { FirstDayOfWeek } from 'devextreme/common';
import config from 'devextreme/core/config';
import { locale } from 'devextreme/localization';
import {
  ContentReadyEvent, GanttTaskTitlePosition, TaskContentTemplateData,
} from 'devextreme/ui/gantt';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import React, {
  Suspense, useEffect, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import apiClient from '../../../../../apiClient.ts';
import { GANTT_API_KEY } from '../../../../../constants.ts';
import AngleDownSVG from '../../../../../public/media/angle-down.svg';
import SettingsSvg from '../../../../../public/media/client-settings.svg';
import LevelsSVG from '../../../../../public/media/levels.svg';
import SprintSVG from '../../../../../public/media/sprint.svg';
import ListOpenSVG from '../../../../../public/media/list_open.svg';
import ListCloseSVG from '../../../../../public/media/list_close.svg';
import RiskWhiteSVG from '../../../../../public/media/risk-white.svg';
import RiskRedSVG from '../../../../../public/media/risk.svg';
import { userAtom } from '../../../../../store/auth.ts';
import Loader from '../../../../Loader/Loader.tsx';
import Button, { ButtonVariants } from '../../../../UIKit/Button/Button.tsx';
import { PopoverPlacement } from '../../../../UIKit/Popover/Popover.tsx';
import PopoverOptions from '../../../../UIKit/PopoverOptions/PopoverOptions.tsx';
import { GateResource } from '../../../QualityGate/types.ts';
import { activeTask } from '../../ManageDeliverables/manageDeliverables.atom.ts';
import {
  collapseLevelAtom, maxCollapseLevelAtom, scaleAtom, taskListWidthAtom, TasksListSize,
} from './schedule.atom.ts';
import styles from './SummarySchedule.module.scss';
import 'devexpress-gantt/dist/dx-gantt.min.css';
import 'devextreme/dist/css/dx.light.css';
import AutorebootErrorBoundary from '../../../../AutorebootErrorBoundary/AutorebootErrorBoundary.tsx';
import { Permissions } from '../../../Login/user.props.ts';
import { usePermissions } from '../../../../../usePermission.ts';

config({ licenseKey: GANTT_API_KEY });

type Task = {
  start_date: Date,
  finish_date: Date,
  id: number,
  name: string,
  parent_id: string,
  task_at_risk: boolean,
  duration: number,
  min_duration: number,
};

const MIN_TASK_WITH_TEXT_WIDTH = 40;

function TaskItem({
  taskData, taskSize,
}: TaskContentTemplateData) {
  const sprintsView = taskData.sprints_count > 0;
  const { t } = useTranslation('schedule');

  const sprintArray = new Array(taskData.sprints_count).fill(null);
  const sprintItemWidth = 46;
  const sprintsGap = 4;

  const isSprintViewTruncated = (sprintItemWidth * taskData.sprints_count)
    + (sprintsGap * (taskData.sprints_count - 1))
    > taskSize.width - sprintsGap * 2;
  const maximumSprintsQuantity = Math.floor((taskSize.width - sprintsGap * 2) / (sprintItemWidth + sprintsGap));

  const sprintsLength = isSprintViewTruncated ? sprintArray.slice(0, maximumSprintsQuantity - 1) : sprintArray;

  const bgColorWeight = (taskData.level >= 0 && taskData.level <= 1) ? 800 - taskData.level * 200
    : (taskData.level >= 2 && taskData.level <= 6) ? 600 - taskData.level * 100 : 500;

  return (
    <div className={styles.taskWrap}>
      <div
        className={classNames(styles.task, {
          [styles.risk]: taskData.task_at_risk,
          [styles.sprint]: sprintsView,
        })}
        style={{
          width: `${taskSize.width}px`,
          background: getComputedStyle(document.documentElement).getPropertyValue(`--primary-${bgColorWeight}`),
        }}
      >

        <div className={classNames(styles.task__content, {
          [styles.hidden]: taskSize.width < MIN_TASK_WITH_TEXT_WIDTH,
        })}
        >
          {taskData.task_at_risk && (
          <img
            className={styles.riskIcon}
            src={RiskWhiteSVG}
            alt=''
          />
          )}
          {sprintsView ? (
            sprintsLength.map((item, index, arr) => ( // eslint-disable-next-line react/no-array-index-key
              <React.Fragment key={index}>
                {sprintsLength.length < sprintArray.length && index === arr.length - 1 && <div>...</div>}
                <div
                  className={styles.task__sprint}
                  style={{
                    background: getComputedStyle(document.documentElement).getPropertyValue(`--primary-${bgColorWeight - 100}`),
                  }}
                >
                  <svg>
                    <use
                      xlinkHref={`${SprintSVG}#sprintSVG`}
                      href={`${SprintSVG}#sprintSVG`}
                    />
                  </svg>
                  <p className={styles.task__sprint__counter}>
                    {index === arr.length - 1 && isSprintViewTruncated ? sprintArray.length : index + 1}
                  </p>
                </div>
              </React.Fragment>
            ))
          )
            : <p>{taskData.name}</p>}
        </div>
      </div>
      {sprintsView && (
        <div className={styles.task__outsideTitle}>
          <p className={styles.task__outsideTitle__title}>{taskData.name}</p>
          <p className={styles.task__outsideTitle__sprints}>
            {`(${taskData.sprints_count} ${t('sprints')})`}
          </p>
        </div>
      )}
    </div>
  );
}

function TaskTooltip(task: Task) {
  const { // eslint-disable-next-line @typescript-eslint/naming-convention
    task_at_risk, name, min_duration, duration,
  } = task;
  const { t } = useTranslation('schedule');

  return task_at_risk ? (
    <div className={styles.tooltip}>
      <header className={styles.tooltip__header}>
        <img
          className={styles.riskIcon}
          src={RiskRedSVG}
          alt=''
        />
        <p>{t('Task at risk')}</p>
      </header>
      <div className={styles.tooltip__content}>
        <h6 className={styles.tooltip__title}>{name}</h6>
        <div className={styles.tooltip__block}>
          <p className={styles.tooltip__text}>{t('Recommended minimum duration:')}</p>
          <p
            className={classNames(styles.tooltip__duration, styles.tooltip__duration_min)}
          >
            {`${min_duration} ${min_duration > 1 ? t('days') : t('day')}`}
          </p>
        </div>
        <div className={styles.tooltip__block}>
          <p className={styles.tooltip__text}>{t('Calculated duration based on the selected project period:')}</p>
          <p className={styles.tooltip__duration}>{`${duration} ${duration > 1 ? t('days') : t('day')}`}</p>
        </div>
      </div>
    </div>
  ) : (
    <div className={classNames(styles.tooltip, styles.nameTooltip)}>
      <h6 className={styles.nameTooltip__title}>{name}</h6>
    </div>
  );
}

function TaskCell({ data }: TreeListTypes.ColumnCellTemplateData) {
  const title = data.data.name;
  return (
    <p
      className={styles.taskCell}
      title={title}
    >
      {title}
    </p>
  );
}

function DurationCell({ data }: TreeListTypes.ColumnCellTemplateData) {
  const { t } = useTranslation();
  const setCurrentTask = useSetAtom(activeTask);
  const { projectId, clientId } = useParams();
  const navigate = useNavigate();
  const { hasPermission } = usePermissions();

  return (
    <div className={styles.durationBlock}>
      <p className={styles.durationCell}>{data.displayValue}</p>
      {!data.data.has_children && hasPermission(Permissions.DELIVERABLE_UPDATE) && (
      <div className={styles.manageDeliverableButton}>
        <PopoverOptions
          options={[{
            id: 0,
            title: t('Manage deliverables'),
            handler: () => {
              setCurrentTask({ id: data.data.id, name: data.data.name, level: data.data.level });
              navigate(`/d/client/${clientId}/project/${projectId}/manage-deliverables`);
            },
          }]}
          paperClassName={styles.manageDeliverablePaper}
        />
      </div>
      )}
    </div>
  );
}

function TaskHeaderCell() {
  const { t } = useTranslation('schedule');
  const taskListSize = useAtomValue(taskListWidthAtom);

  return (
    <div className={classNames(styles.headerCell, {
      [styles.headerCell_hidden]: taskListSize === TasksListSize.MINIMIZED,
    })}
    >
      {t('Task')}
    </div>
  );
}

function DurationHeaderCell() {
  const { t } = useTranslation('schedule');
  const taskListSize = useAtomValue(taskListWidthAtom);

  return (
    <div className={classNames(styles.headerCell, {
      [styles.headerCell_hidden]: taskListSize === TasksListSize.MINIMIZED,
    })}
    >
      {t('Duration')}
    </div>

  );
}

const DESKTOP_BREAKPOINT = 1280;
const TABLET_BREAKPOINT = 768;
const SIDEBAR_DESKTOP_WIDTH = 457;
const SIDEBAR_TABLET_WIDTH = 371;
const MINIMIZED_SIDEBAR_WIDTH = 52;

type CollapseLevel = {
  id: number,
  title: string,
  handler: () => void,
};

function TasksHeader() {
  const { t } = useTranslation('schedule');
  const [scaleType, setScaleType] = useAtom(scaleAtom);
  const [collapseLevel, setCollapseLevel] = useAtom(collapseLevelAtom);
  const maxCollapseLevel = useAtomValue(maxCollapseLevelAtom);
  const { projectId, clientId } = useParams();
  const setCurrentTask = useSetAtom(activeTask);
  const navigate = useNavigate();
  const { hasPermission } = usePermissions();

  const isBiggerThanLandscapeTablet = useMediaQuery('(min-width: 1280px)');

  const updateScale = (value: 'days' | 'weeks' | 'months') => {
    setScaleType(value);
  };

  const virtualCollapseButton: HTMLElement | null = document?.querySelector('#collapseButton');
  const [collapseLevels, setCollapseLevels] = useState<CollapseLevel[] | null>(null);

  useEffect(() => {
    if (maxCollapseLevel) {
      let types: CollapseLevel[] = [];
      for (let i = 0; i <= maxCollapseLevel; i++) {
        types.push({
          id: i,
          title: i === 0 ? t('Collapse all')
            : i === 1 ? t('With tasks')
              : i === 2 ? t('With subtasks')
                : i === maxCollapseLevel ? t('Expand all')
                  : `${maxCollapseLevel} ${t('level list')}`,
          handler: async () => {
            await setCollapseLevel(i);
            virtualCollapseButton?.click();
          },
        });
      }
      setCollapseLevels(types);
    }
  }, [collapseLevel]);

  const scaleTypes = [
    {
      id: 0,
      title: t('days'),
      handler: () => updateScale('days'),
    },
    {
      id: 1,
      title: t('weeks'),
      handler: () => updateScale('weeks'),
    },
    {
      id: 2,
      title: t('months'),
      handler: () => updateScale('months'),
    },
  ];

  const [taskListSize, setTaskListSize] = useAtom(taskListWidthAtom);

  const toggleSidebar = () => {
    setTaskListSize(taskListSize === TasksListSize.FULL ? TasksListSize.MINIMIZED : TasksListSize.FULL);
  };

  return (
    <div className={classNames(styles.tasksHeader, {
      [styles.minimized]: taskListSize === TasksListSize.MINIMIZED,
    })}
    >
      <button
        type='button'
        onClick={toggleSidebar}
        className={classNames(styles.sidebarButton, {
          [styles.sidebarButton_opened]: taskListSize === TasksListSize.FULL,
        })}
      >
        {taskListSize === TasksListSize.FULL ? (
          <svg>
            <use
              xlinkHref={`${ListCloseSVG}#listCloseSVG`}
              href={`${ListCloseSVG}#listCloseSVG`}
            />
          </svg>
        ) : (
          <svg>
            <use
              xlinkHref={`${ListOpenSVG}#listOpenSVG`}
              href={`${ListOpenSVG}#listOpenSVG`}
            />
          </svg>
        )}
      </button>
      {taskListSize === TasksListSize.FULL && (
        <>
          {hasPermission(Permissions.DELIVERABLE_UPDATE) && (
          <Button
            className={styles.manageButton}
            onClick={() => {
              setCurrentTask(null);
              navigate(`/d/client/${clientId}/project/${projectId}/manage-deliverables`);
            }}
            variant={ButtonVariants.SECONDARY}
            iconSize={{ width: 12, height: 12 }}
            icon={(
              <svg>
                <use
                  xlinkHref={`${SettingsSvg}#clientSettingsSVG`}
                  href={`${SettingsSvg}#clientSettingsSVG`}
                />
              </svg>
)}
          >
            <span>{t('Manage deliverables')}</span>
          </Button>
          )}
          <div className={styles.tasksHeader__buttons}>
            <PopoverOptions
              placement={PopoverPlacement.CONTEXT_MENU}
              customButton={(
                <div className={styles.scaleButton}>
                  {scaleType && t(scaleType)}
                  <svg className={styles.angleSVG}>
                    <use
                      xlinkHref={`${AngleDownSVG}#angleDownSVG`}
                      href={`${AngleDownSVG}#angleDownSVG`}
                    />
                  </svg>
                </div>
          )}
              options={scaleTypes}
              paperClassName={styles.scalePaper}
            />

            <PopoverOptions
              placement={PopoverPlacement.CONTEXT_MENU}
              customButton={(
                <div className={styles.scaleButton}>
                  <svg className={styles.scaleButton__icon}>
                    <use
                      xlinkHref={`${LevelsSVG}#levelsSVG`}
                      href={`${LevelsSVG}#levelsSVG`}
                    />
                  </svg>
                  {isBiggerThanLandscapeTablet && t('Display levels')}
                  <svg className={styles.angleSVG}>
                    <use
                      xlinkHref={`${AngleDownSVG}#angleDownSVG`}
                      href={`${AngleDownSVG}#angleDownSVG`}
                    />
                  </svg>
                </div>
            )}
              options={collapseLevels || []}
              paperClassName={styles.levelPaper}
            />
          </div>
        </>
      )}
    </div>
  );
}

type GanttConfig = {
  taskTitlePosition: GanttTaskTitlePosition,
  showResources: boolean,
  showDependencies: boolean,
  showCustomTaskTooltip: boolean,
  taskContentRender: (data: TaskContentTemplateData) => React.ReactNode,
  allowSelection: boolean,
  showRowLines: boolean,
  taskTooltipContentRender: (data: Task) => React.ReactNode,
  rowAlternationEnabled: boolean,
  firstDayOfWeek: FirstDayOfWeek,
};
const ganttConfig: GanttConfig = {
  taskTitlePosition: 'inside' as GanttTaskTitlePosition,
  showResources: false,
  showDependencies: false,
  showCustomTaskTooltip: true,
  taskContentRender: TaskItem,
  allowSelection: false,
  showRowLines: false,
  taskTooltipContentRender: TaskTooltip,
  rowAlternationEnabled: false,
  firstDayOfWeek: 1,
};

const dateNow = new Date(Date.now());

const SummaryScheduleContent = () => {
  const { projectId } = useParams();
  const taskListSize = useAtomValue(taskListWidthAtom);

  const isTablet = useMediaQuery(`(max-width: ${DESKTOP_BREAKPOINT - 1}px)`);
  const isMobile = useMediaQuery(`(max-width: ${TABLET_BREAKPOINT - 1}px)`);
  const isMobileDevice = isTablet || isMobile;

  /* Language switching */
  const { t, i18n } = useTranslation('schedule');
  locale(i18n.language);

  const [isLanguageSwitching, setIsLanguageSwitching] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

  useEffect(() => { // Devextreme can't update gantt component language without reload
    if (currentLanguage !== i18n.language) {
      setIsLanguageSwitching(true);
      setTimeout(() => {
        setIsLanguageSwitching(false);
      }, 1);
      setCurrentLanguage(i18n.language);
    }
  }, [i18n.language]);
  /* END Language switching */

  const {
    data: loadedTasks,
  } = useSWR([`projects/${projectId}/tasks?page=all`, i18n.language, 'schedule'], ([url]) => apiClient
    .get<{ data: Task[], max_level: number }>(url).then(({ response }) => response), {
    keepPreviousData: false,
    revalidateOnFocus: false,
  });

  const collapseLevel = useAtomValue(collapseLevelAtom);
  const setMaxCollapseLevel = useSetAtom(maxCollapseLevelAtom);

  const [tasks, setTasks] = useState<null | Task[]>(null);
  useEffect(() => {
    loadedTasks && setTasks(loadedTasks.data);
    loadedTasks?.max_level && setMaxCollapseLevel(loadedTasks.max_level);
  }, [loadedTasks]);

  const ganttRef = useRef<Gantt>(null);

  const repaintGanttChart = debounce(() => {
    ganttRef?.current?.instance?.refresh();
  }, 300);

  useEffect(() => {
    ganttRef?.current?.instance?.refresh();
  }, [tasks]);

  useEffect(() => {
    window.addEventListener('resize', repaintGanttChart);

    return () => window.removeEventListener('resize', repaintGanttChart);
  }, []);

  const {
    data: loadedQGates,
  } = useSWR([`projects/${projectId}/quality-gates?page=all`, i18n.language, 'schedule'], ([url]) => apiClient
    .get<{ data: GateResource[] }>(url).then(({ response }) => response.data), {
    keepPreviousData: false,
    revalidateOnFocus: false,
  });
  const [qGates, setQGates] = useState<null | GateResource[]>(null);
  useEffect(() => {
    loadedQGates && setQGates(loadedQGates);
  }, [loadedQGates]);

  const userData = useAtom(userAtom);
  const user = userData[0]?.user;

  const scaleType = useAtomValue(scaleAtom);
  const taskListWidth = useAtomValue(taskListWidthAtom);

  const getDuration = (rowData: Task) => {
    const format = user?.dateFormat || 'DD.MM.YYYY';
    const startDate = dayjs(rowData.start_date).format(format);
    const endDate = dayjs(rowData.finish_date).format(format);
    return `${startDate} - ${endDate}`;
  };

  const qGateNodes = document.getElementsByClassName(styles.qGate);
  const ganttNode = document.getElementsByClassName('dx-gantt-tsa');
  const ganttSidebarNode = document.getElementsByClassName('dx-treelist');
  const [markPositions, setMarkPositions] = useState<null | {
    [key: string]: {
      id: number,
      value: number,
    }
  }>(null);
  const [ganttWidth, setGanttWidth] = useState<null | number>(null);

  const ganttZone = document.querySelector('.dx-gantt-view .dx-scrollable-container');
  const ganttFooter = useRef<HTMLElement>(null);
  const [footerScrollLeft, setFooterScrollLeft] = useState<number | undefined>(0);

  const updateQGatesPosition = () => {
    qGateNodes.length > 0 && Array.from(qGateNodes).forEach(qGateNode => {
      const qGateClassId = (Array.from(qGateNode.classList).find(className => className.startsWith('id')));
      const matchResult = qGateClassId && qGateClassId.match(/\d+/);
      const qGateId = matchResult && parseInt(matchResult[0], 10);
      qGateId && setMarkPositions(prev => ({
        ...prev,
        [qGateId]: {
          id: qGateId,
          value: parseFloat((qGateNode as HTMLElement).style.left),
        },
      }));
    });
  };
  const onGanttInit = (e: ContentReadyEvent) => {
    setTimeout(() => { // fix to prevent gantt chart zooming on ctrl+mousewheel scroll or touchpad pinching
      // @ts-ignore
      e.component._ganttView._ganttViewCore.renderHelper._taskAreaManager.stateController.onMouseWheel = () => {};
      // @ts-ignore
      e.component._treeList.option('allowColumnResizing', false);
    }, 0);
    setGanttWidth(parseFloat((ganttNode[0] as HTMLElement).style.width) + parseFloat((ganttSidebarNode[0] as HTMLElement).style.width));
    updateQGatesPosition();
  };

  useEffect(() => {
    if (ganttFooter.current && typeof footerScrollLeft === 'number') {
      ganttFooter.current.scrollLeft = footerScrollLeft;
    }
  }, [footerScrollLeft]);

  const updateFooterScrollPosition = () => {
    ganttZone && setFooterScrollLeft(ganttZone.scrollLeft);
  };
  useEffect(() => {
    ganttZone?.addEventListener('scroll', updateFooterScrollPosition);

    return () => ganttZone?.removeEventListener('scroll', updateFooterScrollPosition);
  }, [ganttZone]);

  const repaintFooter = () => {
    updateFooterScrollPosition();
    updateQGatesPosition();
  };

  useEffect(() => {
    window.addEventListener('resize', repaintFooter);

    return () => window.removeEventListener('resize', repaintFooter);
  }, []);

  const updateGantt = () => {
    setGanttWidth(parseFloat((ganttNode[0] as HTMLElement)?.style.width) + parseFloat((ganttSidebarNode[0] as HTMLElement)?.style.width));
    updateQGatesPosition();
  };

  useEffect(() => {
    updateFooterScrollPosition();

    const timeout = setTimeout(updateGantt, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [scaleType]);

  useEffect(() => {
    let vw = document.body.clientWidth * 0.01;
    document.documentElement.style.setProperty('--vw', `${vw}px`);
  }, [document.body.clientWidth]);

  return (
    <div className={classNames(styles.schedule, 'dx-viewport')}>
      <button
        type='button'
        id='collapseButton'
        className={styles.collapseHelperButton}
        onClick={() => {
          collapseLevel ? ganttRef?.current?.instance?.expandAllToLevel(collapseLevel) : ganttRef?.current?.instance?.collapseAll();
        }}
      />
      {tasks && (
        isLanguageSwitching ? <div className={styles.preloader}><Loader /></div> : (
          <div>
            <Gantt
              ref={ganttRef}
              {...ganttConfig}
              scaleType={scaleType}
              taskListWidth={taskListWidth === TasksListSize.FULL
                ? (!isMobileDevice ? SIDEBAR_DESKTOP_WIDTH : SIDEBAR_TABLET_WIDTH)
                : MINIMIZED_SIDEBAR_WIDTH}
              onContentReady={onGanttInit}
            >
              {/* Custom data for the tasks */}
              <Column
                dataField='task_at_risk'
                visible={false}
              />
              <Column
                dataField='duration'
                visible={false}
              />
              <Column
                dataField='min_duration'
                visible={false}
              />
              <Column
                dataField='sprints_count'
                visible={false}
              />
              <Column
                dataField='level'
                visible={false}
              />

              <StripLine
                start={dateNow}
                title={t('Current Time')}
                cssClass={styles.todayMark}
              />

              {qGates && qGates.map(qGate => (
                <StripLine
                  key={qGate.id}
                  start={`${qGate.due_date} 23:59`}
                  title={qGate.caption}
                  cssClass={classNames(styles.qGate, `id${qGate.id}`)}
                />
              ))}

              <Tasks
                dataSource={tasks}
                keyExpr='id'
                parentIdExpr='parent_id'
                titleExpr='name'
                startExpr='start_date'
                endExpr='finish_date'
              />

              <ScaleTypeRange
                min={scaleType}
                max={scaleType}
              />

              <Column
                headerCellComponent={TasksHeader}
                cssClass={styles.selectCell}
              >
                <Column
                  dataField='name'
                  width={taskListWidth === TasksListSize.MINIMIZED ? 0 : isMobileDevice ? 168 : 238}
                  cellComponent={TaskCell}
                  headerCellComponent={TaskHeaderCell}
                  allowSorting={false}
                  cssClass={classNames(styles.cell, {
                    [styles.cell_hidden]: taskListSize === TasksListSize.MINIMIZED,
                  })}
                />
                <Column
                  width={taskListSize === TasksListSize.MINIMIZED ? 0 : 186}
                  caption={t('Duration')}
                  calculateCellValue={getDuration}
                  cellComponent={DurationCell}
                  headerCellComponent={DurationHeaderCell}
                  cssClass={classNames(styles.cell, {
                    [styles.cell_hidden]: taskListSize === TasksListSize.MINIMIZED,
                  })}
                />
              </Column>
            </Gantt>
            <footer
              className={styles.footer}
              ref={ganttFooter}
            >
              <div
                className={styles.footer__sidebar}
                style={{
                  width: taskListWidth === TasksListSize.FULL
                    ? (!isMobileDevice ? SIDEBAR_DESKTOP_WIDTH : SIDEBAR_TABLET_WIDTH)
                    : MINIMIZED_SIDEBAR_WIDTH,
                }}
              />
              <div
                style={{ width: ganttWidth || 0 }}
                className={styles.footer__track}
              >
                {qGates?.map(qGate => (
                  markPositions?.[qGate.id]?.value ? (
                    <div
                      key={qGate.id}
                      className={styles.qGateItem}
                      style={{ left: markPositions?.[qGate.id].value }}
                    >
                      <div
                        className={styles.qGateItem__content}
                      >
                        {`${qGate.caption} (${dayjs(qGate.due_date).format(user?.dateFormat || 'DD.MM.YYYY')})`}
                      </div>
                    </div>
                  ) : null
                ))}
              </div>
            </footer>
          </div>
        ))}
    </div>
  );
};

const SummarySchedule = () => (
  <AutorebootErrorBoundary fallback={<div className={styles.preloader}><Loader /></div>}>
    <Suspense fallback={<div className={styles.preloader}><Loader /></div>}><SummaryScheduleContent /></Suspense>
  </AutorebootErrorBoundary>
);

export default SummarySchedule;
