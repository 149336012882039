import { useTranslation } from 'react-i18next';
import { FormikValues, useFormik } from 'formik';
import * as Yup from 'yup';
import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useAtomValue } from 'jotai';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import styles from './QualityGate.module.scss';
import Input from '../../../UIKit/Input/Input.tsx';
import Button from '../../../UIKit/Button/Button.tsx';
import DatePicker from '../../../UIKit/DatePicker/DatePicker.tsx';
import { DEFAULT_DATE_FORMAT } from '../../../../constants.ts';
import { userAtom } from '../../../../store/auth.ts';
import { notify } from '../../../../store/notifications.ts';
import apiClient from '../../../../apiClient.ts';
import { GateResource } from '../types.ts';

type QualityGateFormProps = {
  submitButtonText?: string,
  submit: (values: FormikValues) => void,
  activeGateId?: number,
  projectTimeline: { from: string, to: string }
};

const QualityGateForm = ({
  submitButtonText, submit, activeGateId, projectTimeline,
}: QualityGateFormProps) => {
  const { projectId } = useParams();
  const { t } = useTranslation();
  const userData = useAtomValue(userAtom);

  const {
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    errors,
    isSubmitting,
    setFieldValue,
    setValues,
  }: FormikValues = useFormik({
    initialValues: {
      titleEn: '',
      titleDe: '',
      dueDate: null,
    },
    validationSchema: Yup.object({
      titleEn: Yup.string()
        .trim()
        .max(100, t('Must be 100 characters at most'))
        .required(t('English title is required')),
      titleDe: Yup.string()
        .max(100, t('Must be 100 characters at most'))
        .trim()
        .required(t('German title is required')),
      dueDate: Yup.date().nullable().transform((value) => {
        if (dayjs(value).isValid()) {
          return value;
        }
      })
        .min(
          dayjs(projectTimeline?.from).toDate(),
          `${t(
            'It\'s not possible to terminate q-gates outside of the project timeline. The date cannot be set earlier than ',
          )} ${dayjs(projectTimeline?.from)
            .format(userData?.user.dateFormat ?? DEFAULT_DATE_FORMAT)}`,
        )
        .max(
          dayjs(projectTimeline?.to).toDate(),
          `${t(
            'It\'s not possible to terminate q-gates outside of the project timeline. The date cannot be set later than ',
          )} ${dayjs(projectTimeline?.to)
            .format(userData?.user.dateFormat ?? DEFAULT_DATE_FORMAT)}`,
        )
        .required(t('Due date is required')),
    }),
    onSubmit: (submitValues) => {
      submit({
        caption: {
          en: submitValues.titleEn,
          de: submitValues.titleDe,
        },
        due_date: dayjs(submitValues.dueDate).format('YYYY-MM-DD'),
      });
    },
  });

  const fetchGate = async (id: number) => {
    try {
      const { statusCode, response } = await apiClient.get<{
        data: GateResource,
        localized: {
          caption: {
            en: string,
            de: string
          }
        }
      }>(`projects/${projectId}/quality-gates/${id}`);

      if (statusCode === 200) {
        const { data: { due_date: dueDate }, localized: { caption } } = response;
        setValues({ titleEn: caption.en, titleDe: caption.de, dueDate: dayjs(dueDate) });
      } else {
        throw new Error();
      }
    } catch (e) {
      notify();
    }
  };

  useEffect(() => {
    if (!activeGateId) return;

    fetchGate(activeGateId);
  }, [activeGateId]);

  return (
    <form
      onSubmit={handleSubmit}
      className={styles.form}
    >
      <div className={styles.form__block}>
        <Input
          value={values.titleEn}
          setValue={handleChange}
          onBlur={handleBlur}
          id='titleEn'
          label={t('Quality gate title')}
          name='titleEn'
          error={!!(touched.titleEn && errors.titleEn)}
          errorMessage={errors.titleEn}
          className={classNames(styles.form__input_en, styles.form__input)}
        />
        <Input
          value={values.titleDe}
          setValue={handleChange}
          onBlur={handleBlur}
          id='titleDe'
          label={t('Quality gate title')}
          name='titleDe'
          error={!!(touched.titleDe && errors.titleDe)}
          errorMessage={errors.titleDe}
          className={classNames(styles.form__input_de, styles.form__input)}
        />
        <DatePicker
          className={styles.form__block_datepicker}
          label={t('Select due date')}
          format={userData?.user.dateFormat ?? DEFAULT_DATE_FORMAT}
          value={values.dueDate}
          setValue={date => setFieldValue('dueDate', dayjs(date).startOf('day'))}
          error={!!(touched.dueDate && errors.dueDate)}
          errorMessage={errors.dueDate}
        />
      </div>
      <div className={styles.form__footer}>
        <Button
          type='submit'
          loading={isSubmitting}
          disabled={isSubmitting}
        >
          {submitButtonText ?? t('Add quality gate')}
        </Button>
      </div>
    </form>
  );
};

export default QualityGateForm;
