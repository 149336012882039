import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAtomValue } from 'jotai';

import Button, { ButtonVariants } from '../../UIKit/Button/Button';
import Drawer from '../../UIKit/Drawer/Drawer';
import styles from './Templates.module.scss';
import TemplateForm from './TemplateForm/TemplateForm';
import TemplatesList from './TemplatesList/TemplatesList';

import { userAtom } from '../../../store/auth';

const Templates = () => {
  const { t } = useTranslation();
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const userData = useAtomValue(userAtom);
  const dateFormat = userData?.user?.dateFormat || 'DD.MM.YYYY';

  return (
    <div className={styles.projects}>
      <div className={styles.heading}>
        <h5>{t('Project templates')}</h5>
        <Button
          variant={ButtonVariants.SECONDARY}
          onClick={() => setIsDrawerOpen(true)}
          className={styles.button}
        >
          {t('Add project template')}
        </Button>
      </div>
      <Drawer
        isOpen={isDrawerOpen}
        setIsOpen={setIsDrawerOpen}
        title={t('Add project template')}
      >
        <TemplateForm
          closeDrawer={() => setIsDrawerOpen(false)}
          dateFormat={dateFormat}
        />
      </Drawer>
      <TemplatesList
        openCreateTemplateForm={() => setIsDrawerOpen(true)}
        dateFormat={dateFormat}
      />
    </div>
  );
};

export default Templates;
