import classNames from 'classnames';
import { TFunction } from 'i18next';
import { FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';

import styles from '../NewProjectSummary.module.scss';

export interface ITab {
  to: string,
  isEnd?: boolean,
  icon: string,
  title: string
}

interface TabLinkProps {
  tab: ITab,
  isPopoverOption?: boolean,
  t: TFunction<'translation', undefined>
}

const TabLink: FunctionComponent<TabLinkProps> = ({ tab, isPopoverOption, t }) => (
  <NavLink
    to={tab.to}
    end={tab.isEnd}
    className={({ isActive }) => classNames(styles.navigation__link, {
      [styles.active]: isActive,
      [styles.popoverOption]: isPopoverOption,
    })}
  >
    <svg className={styles.deliverable__infoIcon}>
      <use
        xlinkHref={tab.icon}
        href={tab.icon}
      />
    </svg>
    {t(tab.title)}
  </NavLink>
);

export default TabLink;
