import { useAtomValue } from 'jotai';
import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { userClientAtom } from '../../../store/auth.ts';
import { previewLogoAtom, clientPreviewIdAtom, customLogoAtom } from '../../../store/clientCI.ts';
import { useOutsideClick } from '../../../useOutsideClick.ts';
import LogoSvg from '../../../public/media/logo.svg';
import { ReactComponent as AngleDownSVG } from '../../../public/media/angle-down.svg';
import { ReactComponent as ManageClientsSVG } from '../../../public/media/manage-clients.svg';
import CloseSVG from '../../../public/media/close.svg';
import styles from './AdminSidebarHeader.module.scss';

const AdminSidebarHeader = ({
  isMenuVisible,
  setIsMenuVisible,
  closeMenu,
}: {
  isMenuVisible: boolean;
  setIsMenuVisible: (value: boolean) => void;
  closeMenu?: () => void;
}) => {
  const { t } = useTranslation();
  const clientPreviewId = useAtomValue(clientPreviewIdAtom);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const openButtonRef = useRef<HTMLButtonElement>(null);
  useOutsideClick(wrapperRef, () => setIsMenuVisible(false), openButtonRef);

  const customLogo = useAtomValue(customLogoAtom);
  const previewLogo = useAtomValue(previewLogoAtom);

  const currentClient = useAtomValue(userClientAtom);

  return (
    <>
      <div className={classnames(styles.header, {
        [styles.active]: isMenuVisible,
      })}
      >
        <div className={styles.header__content}>
          <Link
            to='/m'
            className={styles.header__logo}
          >
            {clientPreviewId && previewLogo ? (
              <img
                alt=''
                src={previewLogo?.logo.preview}
              />
            ) : customLogo?.logoUrl ? (
              <img
                alt=''
                src={customLogo.logoUrl}
              />
            ) : (
              <svg>
                <use
                  xlinkHref={`${LogoSvg}#logoSVG`}
                  href={`${LogoSvg}#logoSVG`}
                />
              </svg>
            )}
          </Link>
          <div className={styles.header__text}>
            <h5
              className={styles.name}
              title={currentClient?.name?.toUpperCase() ?? 'ZEEMLESS'}
            >
              {currentClient?.name?.toUpperCase() ?? 'ZEEMLESS'}
            </h5>
            {/* TODO: display user role */}
            <p>Global admin</p>
          </div>
        </div>
        <button
          type='button'
          onClick={() => setIsMenuVisible(!isMenuVisible)}
          ref={openButtonRef}
        >
          <AngleDownSVG className={classnames(styles.header__arrow, {
            [styles.rotate]: isMenuVisible,
          })}
          />
        </button>
        {isMenuVisible && (
          <div
            className={styles.menu}
            ref={wrapperRef}
          >
            <h5>{t('Menu')}</h5>
            <Link
              to='/'
              onClick={() => setIsMenuVisible(false)}
            >
              <div className={styles.menu__item}>
                <ManageClientsSVG />
                <span>{t('Manage clients')}</span>
              </div>
            </Link>
            {/* <Link to='/m/translations'> */}
            {/*  <div className={styles.menu__item}> */}
            {/*    <ManageTranslationsSVG /> */}
            {/*    <span>{t('Manage translations')}</span> */}
            {/*  </div> */}
            {/* </Link> */}
          </div>
        )}
      </div>
      <button
        className={styles.closeMenuButton}
        type='button'
        onClick={() => closeMenu?.()}
        aria-label='Close menu'
      >
        <svg>
          <use
            xlinkHref={`${CloseSVG}#closeSVG`}
            href={`${CloseSVG}#closeSVG`}
          />
        </svg>
      </button>
    </>

  );
};

export default AdminSidebarHeader;
