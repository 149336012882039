import { useTranslation } from 'react-i18next';
import { FormikValues, useFormik } from 'formik';
import React, { useEffect } from 'react';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import styles from './DeliverableForm.module.scss';
import Button from '../../../../UIKit/Button/Button.tsx';
import Input from '../../../../UIKit/Input/Input.tsx';
import { notify } from '../../../../../store/notifications.ts';
import { Deliverable } from '../../../NewProject/NewProjectDeliverables/types.ts';
import apiClient from '../../../../../apiClient.ts';

type DeliverableFormProps = {
  submit: (value: FormikValues) => void,
  deliverableId: number,
};

const EditDeliverableForm = ({ submit, deliverableId }: DeliverableFormProps) => {
  const { t } = useTranslation();
  const { projectId } = useParams();

  const {
    values,
    handleSubmit,
    isSubmitting,
    handleChange,
    handleBlur,
    touched,
    errors,
    setValues,
  }: FormikValues = useFormik({
    initialValues: {
      titleEn: '',
      titleDe: '',
    },
    validationSchema: Yup.object({
      titleEn: Yup.string()
        .trim()
        .max(100, t('Must be 100 characters at most'))
        .required(t('English title is required')),
      titleDe: Yup.string()
        .trim()
        .max(100, t('Must be 100 characters at most'))
        .required(t('German title is required')),
    }),
    onSubmit: (submitValues) => {
      submit({
        caption: {
          en: submitValues.titleEn,
          de: submitValues.titleDe,
        },
      });
    },
  });

  const fetchGate = async (id: number) => {
    try {
      const { statusCode, response } = await apiClient.get<{
        data: Deliverable,
        localized: {
          caption: {
            en: string,
            de: string
          }
        }
      }>(`projects/${projectId}/deliverables/${id}`);

      if (statusCode === 200) {
        const { data: { due_date: dueDate }, localized: { caption } } = response;
        setValues({ titleEn: caption.en, titleDe: caption.de, dueDate: dayjs(dueDate) });
      } else {
        throw new Error();
      }
    } catch (e) {
      console.error(e);
      notify();
    }
  };

  useEffect(() => {
    if (!deliverableId) return;

    fetchGate(deliverableId);
  }, [deliverableId]);

  return (
    <form
      onSubmit={handleSubmit}
      className={styles.form}
    >
      <div className={styles.form__content}>
        <div className={styles.form__inner}>
          <h5 className={styles.form__block_subtitle}>{t('Deliverable name')}</h5>
          <Input
            value={values.titleEn}
            setValue={handleChange}
            onBlur={handleBlur}
            id='titleEn'
            label={t('Enter deliverable name')}
            name='titleEn'
            error={!!(touched.titleEn && errors.titleEn)}
            errorMessage={errors.titleEn}
            className={classNames(styles.form__input_en, styles.form__input)}
          />
          <Input
            value={values.titleDe}
            setValue={handleChange}
            onBlur={handleBlur}
            id='titleDe'
            label={t('Enter deliverable name')}
            name='titleDe'
            error={!!(touched.titleDe && errors.titleDe)}
            errorMessage={errors.titleDe}
            className={classNames(styles.form__input_de, styles.form__input)}
          />
        </div>
      </div>
      <div className={styles.form__footer}>
        <Button
          type='submit'
          loading={isSubmitting}
          disabled={isSubmitting}
        >
          {t('Save changes')}
        </Button>
      </div>
    </form>
  );
};

export default EditDeliverableForm;
