import styles from './ListPlaceholder.module.scss';

import WhiteListSVG from '../../../public/media/white-list.svg';

type ListPlaceholderProps = {
  header?: React.ReactNode,
  className?: string,
  placeholderText: string,
};

const ListPlaceholder = ({
  header,
  className,
  placeholderText,
}: ListPlaceholderProps) => (
  <>
    {header && (
    <header className={className}>
      {header}
    </header>
    )}
    <section className={styles.placeholder}>
      <svg className={styles.placeholder__icon}>
        <use
          xlinkHref={`${WhiteListSVG}#whiteListSVG`}
          href={`${WhiteListSVG}#whiteListSVG`}
        />
      </svg>
      <p className={styles.placeholder__text}>
        {placeholderText}
      </p>
    </section>
  </>
);

export default ListPlaceholder;
