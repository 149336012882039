const openTutorial = async (lang: string) => {
  const versions: Record<string, string> = {
    en: 'User_Manual',
    de: 'Benutzerhandbuch',
  };
  const { default: pdf } = await import(`../../public/media/zeemless_${versions[lang]}.pdf`);
  window.open(pdf, '_blank');
};

export default openTutorial;
