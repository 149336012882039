export const DEV_MODE = process.env.REACT_APP_DEV_MODE;
export const API_URL = process.env.REACT_APP_API_URL;
export const GANTT_API_KEY = process.env.REACT_APP_DEVEXPRESS_API_KEY;

export const LS_LANG_KEY = 'i18nextLng';

export const HIGHEST_ROLE_LEVEL = 255;

export const PASSWORD_REGEXP = {
  CASE: /(\p{Ll}+.*\p{Lu})|(\p{Lu}+.*\p{Ll})/u,
  SPECIAL_CHARACTER: /\p{Z}|\p{S}|\p{P}/u,
  NUMBER: /\d/u,
  TWO_OR_MORE_SPECIAL_CHARACTERS: /([^A-Za-z0-9].*){2,}/u,
  CONSECUTIVE_LOWER_CASE: /[a-z]{2,}/u,
  CONSECUTIVE_UPPER_CASE: /[A-Z]{2,}/u,
  CONSECUTIVE_NUMBER: /\d{2,}/u,
};

export const NUMBER_FORMAT:
Record<string, { locale: string, thousandSeparator: string, decimalSeparator: string, decimalScale: number }> = {
  'X.XXX,XX': {
    locale: 'de-DE',
    thousandSeparator: '.',
    decimalSeparator: ',',
    decimalScale: 2,
  },
  'X,XXX.XX': {
    locale: 'en-US',
    thousandSeparator: ',',
    decimalSeparator: '.',
    decimalScale: 2,
  },
};

export const DEFAULT_NUMBER_FORMAT = NUMBER_FORMAT['X.XXX,XX'];

export const DEFAULT_DATE_FORMAT = 'DD.MM.YYYY';

export const MOBILE_MEDIA_QUERY = '(max-width: 767px)';
export const MOBILE_SMALL_MEDIA_QUERY = '(max-width: 413px)';
