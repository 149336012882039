import { useFormik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLoaderData } from 'react-router-dom';
import { mutate } from 'swr';
import * as Yup from 'yup';

import { CustomerQuestion } from '../../../../actions';
import apiClient, { RequestType } from '../../../../apiClient.ts';

import Button from '../../../UIKit/Button/Button';
import CheckboxItem from '../../../UIKit/CheckboxItem/CheckboxItem';
import Input from '../../../UIKit/Input/Input';
import RadioGroup from '../../../UIKit/RadioGroup/RadioGroup';
import { CustomerResource } from '../types';

import styles from './AddCustomerForm.module.scss';

type ModifiedCheckbox = {
  id: number, checked: boolean,
};

interface FormValues {
  name: string;
  answers: {
    [key: string]: number[] | ModifiedCheckbox[]
  }
}

type CheckboxGroupProps = {
  title: string,
  children: React.ReactElement
};

const AddCustomerSection = ({ title, children }: CheckboxGroupProps) => (
  <section className={styles.customerSection}>
    <h6 className={styles.customerSection__title}>{title}</h6>
    {children}
  </section>
);

type AddCustomerProps = {
  closeModal: () => void,
  customer?: CustomerResource,
  submitType?: RequestType,
  clientId?: string,
  addCustomerCallback?: (customer: CustomerResource) => void
};

const AddCustomerForm = ({
  closeModal, customer, submitType = RequestType.POST, clientId, addCustomerCallback,
}: AddCustomerProps) => {
  const { questions } = useLoaderData() as { questions: CustomerQuestion[] };
  const { t } = useTranslation();

  const addCustomerForm = async (client: string, data: FormValues) => {
    try {
      const { statusCode, response } = submitType === RequestType.POST ? await apiClient.post<{
        data: CustomerResource
      }>(`clients/${client}/customers`, {
        body: JSON.stringify(data),
      }) : await apiClient
        .put<{ data: CustomerResource }>(`clients/${client}/customers/${(customer as CustomerResource).id}`, {
        body: JSON.stringify(data),
      });
      if ((submitType === RequestType.POST && statusCode === 201) || (submitType === RequestType.PUT && statusCode === 200)) {
        await mutate(
          (key: any[]) => key.includes('clients/customers'),
          undefined,
          { revalidate: true },
        );
        closeModal();
        addCustomerCallback?.(response.data);
        return response;
      }
    } catch (e) {
      console.error(e);
    }
  };

  const initialRadioValues = questions
    .filter(question => question.max === 1)
    .reduce((result: {
      [key: string]: number[]
    }, question) => {
      const [firstAnswer] = question.answers;
      result[question.id] = customer?.answers?.[question.id] || [firstAnswer.id];
      return result;
    }, {});

  const initialCheckboxValues = questions
    .filter(question => question.max !== 1)
    .reduce((result: { [key: string]: { id: number; checked: boolean; }[] }, question) => {
      result[question.id] = Array.from(question.answers, (answer) => ({
        id: answer.id,
        checked: customer?.answers?.[question.id]?.includes(answer.id) || false,
      }));
      return result;
    }, {});

  const formInitialValues: FormValues = {
    name: customer?.name || '',
    answers: { ...initialRadioValues, ...initialCheckboxValues },
  } as FormValues;
  const {
    handleSubmit,
    values,
    handleChange,
    setFieldValue,
    isSubmitting,
    touched,
    errors,
  } = useFormik<FormValues>({
    initialValues: formInitialValues,
    validationSchema: Yup.object({
      name: Yup.string().trim()
        .required(t('Customer name is required')).max(100, t('Must be 100 characters at most')),
    }),
    onSubmit: async (submitValues) => {
      const data: {
        name: string;
        answers: {
          [key: string]: number[] | ModifiedCheckbox[]
        }
      } = {
        ...submitValues,
        answers: {
          ...submitValues.answers,
        },
      };
      Object.keys(data.answers).forEach(key => {
        if (Array.isArray(data.answers[key])) {
          if (data.answers[key].some(item => typeof item === 'object')) {
            data.answers[key] = data.answers[key]
              .filter(item => (item as ModifiedCheckbox).checked)
              .map(item => (item as ModifiedCheckbox).id);
          }
        }
      });
      clientId && await addCustomerForm(clientId, data as FormValues);
    },
  });

  return (

    <form
      className={styles.content}
      onSubmit={handleSubmit}
    >
      <div className={styles.sections}>
        <Input
          value={values.name}
          setValue={handleChange}
          id='name'
          label={t('Customer name')}
          className={styles.nameInput}
          error={!!(touched.name && errors.name)}
          errorMessage={errors.name}
        />
        {
          questions.map(question => (
            <AddCustomerSection
              title={question.caption}
              key={question.id}
            >
              {
                question.max === 1 ? (
                  <RadioGroup
                    key={question.id}
                    value={values.answers[question.id][0] as number}
                    setValue={(value) => setFieldValue(
                      `answers.${question.id}`,
                      [+value],
                    )}
                    options={question.answers}
                    groupId={question.caption}
                    className={styles.checkboxGroup}
                  />
                ) : (
                  <div className={styles.checkboxGroup}>
                    {question.answers.map(questionAnswer => (
                      <CheckboxItem
                        key={questionAnswer.id}
                        value={(values.answers[question.id]
                          .find(answer => (answer as ModifiedCheckbox).id === questionAnswer.id) as ModifiedCheckbox)
                          .checked}
                        onChange={(e) => {
                          const updatedCheckboxArray = values.answers[question.id].map((item) => {
                            if ((item as ModifiedCheckbox).id === questionAnswer.id) {
                              return { ...(item as ModifiedCheckbox), checked: e.target.checked };
                            }
                            return item;
                          });

                          setFieldValue(`answers.${question.id}`, updatedCheckboxArray);
                        }}
                        label={questionAnswer.caption}
                      />
                    ))}
                  </div>
                )
              }
            </AddCustomerSection>
          ))
        }
      </div>
      <footer className={styles.footer}>
        <Button
          type='submit'
          className={styles.footer__button}
          loading={isSubmitting}
        >
          {customer ? t('Save changes') : t('Create customer')}
        </Button>
      </footer>
    </form>
  );
};

export default AddCustomerForm;
