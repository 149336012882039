import { LoaderFunctionArgs, redirect } from 'react-router-dom';
import { getCustomerQuestions } from './customers.loader.ts';
import apiClient from '../apiClient.ts';
import { ProjectResource } from '../components/pages/Projects/types.ts';
import { checkUserPermission } from './check-permissions.loader.ts';
import { Permissions } from '../components/pages/Login/user.props.ts';

export const getProjectDetails = async (clientId: string, projectId: string) => {
  try {
    const { response: { data } } = await apiClient
      .get<{ data: ProjectResource }>(`clients/${clientId}/projects/${projectId}?with=customer,template,answers,jiraIntegration`);
    return data;
  } catch (e) {
    console.error(e);
  }
};

export const newProjectLoader = async ({ params }: LoaderFunctionArgs<Record<string, string>>) => {
  const hasPermission = await checkUserPermission(Permissions.PROJECT_VIEW, (params?.clientId ?? params?.id));
  if (!hasPermission) {
    return redirect('/access-denied');
  } else {
    const customerQuestions = await getCustomerQuestions();
    let project = null;

    if (params.clientId && params.projectId) {
      project = await getProjectDetails(params.clientId, params.projectId);
    }

    return { questions: customerQuestions, project };
  }
};
