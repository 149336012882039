import { useAtomValue } from 'jotai';

import { Permissions } from './components/pages/Login/user.props.ts';
import { AuthStorageKey, clientPermissionsAtom, globalPermissionsAtom } from './store/auth.ts';

const getPermissions = (storageKey: AuthStorageKey): string[] => {
  try {
    const data = localStorage.getItem(storageKey);
    return data ? JSON.parse(data) : [];
  } catch (e) {
    console.error(e);
    return [];
  }
};

export const hasGlobalPermission = (permission: Permissions): boolean => getPermissions(AuthStorageKey.GLOBAL_PERMISSIONS)
  .includes(permission);

export const usePermissions = () => {
  const clientPermissions = useAtomValue(clientPermissionsAtom);
  const globalPermissions = useAtomValue(globalPermissionsAtom);

  return {
    hasGlobalPermission: (permission: Permissions) => globalPermissions.includes(permission),
    hasClientPermission: (permission: Permissions) => clientPermissions.includes(permission),
    hasPermission: (permission: Permissions) => clientPermissions.includes(permission) || globalPermissions.includes(permission),
  };
};
