import React from 'react';
import { useTranslation } from 'react-i18next';
import CloseSVG from '../../public/media/close.svg';

import styles from './Banner.module.scss';

const Banner = ({
  text,
  actionText,
  action,
  closeBanner,
}: { text: string, actionText: string, action: () => void, closeBanner: () => void }) => {
  const { t } = useTranslation();

  return (
    <section className={styles.banner}>
      <p>
        {text}
        {' '}
        <button
          type='button'
          className={styles.actionButton}
          onClick={action}
        >
          {actionText}
        </button>
      </p>
      <button
        aria-label={t('Hide future release banner')}
        type='button'
        className={styles.closeButton}
        onClick={closeBanner}
      >
        <svg>
          <use
            xlinkHref={`${CloseSVG}#closeSVG`}
            href={`${CloseSVG}#closeSVG`}
          />
        </svg>
      </button>
    </section>
  );
};

export default Banner;
