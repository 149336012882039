import { SelectChangeEvent, Tooltip } from '@mui/material';
import classNames from 'classnames';
import { useAtom, useSetAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { ColorPicker, IColor, useColor } from 'react-color-palette';
import 'react-color-palette/css';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { updateClientCI } from '../../actions/clientCiLoader.ts';
import { generatePalette } from '../../colorPaletteGenerator.ts';
import CopySVG from '../../public/media/copy.svg';
import CheckedSVG from '../../public/media/checked.svg';
import CrossSVG from '../../public/media/close.svg';
import InfoSVG from '../../public/media/info.svg';
import LogoSvg from '../../public/media/logo.svg';
import { userClientAtom } from '../../store/auth.ts';
import {
  clientPreviewIdAtom, customLogoAtom, FileWithPreview, previewLogoAtom, previousColorPalette,
} from '../../store/clientCI.ts';
import { NotificationStatus, notify } from '../../store/notifications.ts';
import Loader from '../Loader/Loader.tsx';
import Button, { ButtonVariants } from '../UIKit/Button/Button';
import Popover, { PopoverPlacement } from '../UIKit/Popover/Popover';
import Select from '../UIKit/Select/Select.tsx';
import styles from './ClientCI.module.scss';

const MIN_AA_CONTRAST_RATIO = 4.5;
const MIN_AAA_CONTRAST_RATIO = 7;

const calculateContrastRatio = (color1: string, color2: string) => {
  function hexToRgb(hex: string) {
    const color = hex.replace(/^#/, '');

    const bigint = parseInt(color, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    return [r, g, b];
  }

  function calculateRelativeLuminance(rgb: number[]) {
    const [r, g, b] = rgb.map(value => {
      const color = value / 255;
      return color <= 0.03928 ? color / 12.92 : ((color + 0.055) / 1.055) ** 2.4;
    });
    return 0.2126 * r + 0.7152 * g + 0.0722 * b;
  }

  function calculateRatio(l1: number, l2: number) {
    const [lBrighter, lDarker] = l1 > l2 ? [l1, l2] : [l2, l1];
    return (lBrighter + 0.05) / (lDarker + 0.05);
  }

  const rgb1 = hexToRgb(color1);
  const rgb2 = hexToRgb(color2);

  const luminance1 = calculateRelativeLuminance(rgb1);
  const luminance2 = calculateRelativeLuminance(rgb2);

  return calculateRatio(luminance1, luminance2);
};

type ClientCIProps = {
  setIsCIDrawerOpen: (value: boolean) => void
};

const FileUploadErrors: { [key: string]: string } = {
  'file-too-large': 'Maximum file size is 1024kb.',
  'too-many-files': 'Please upload only one file.',
  'file-invalid-type': 'Accepted formats: png, jpg and svg.',
};

enum ColorType {
  HEX = 'hex',
  RGB = 'rgb',
  HSV = 'hsv',
}
const ClientCI = ({ setIsCIDrawerOpen }: ClientCIProps) => {
  const { t } = useTranslation();
  const { id: clientId } = useParams();
  const [previewLogo, setPreviewLogo] = useAtom(previewLogoAtom);
  const [uploadedLogo, setUploadedLogo] = useState<null | FileWithPreview>(previewLogo?.logo || null);
  const currentPrimaryColor = getComputedStyle(document.documentElement).getPropertyValue('--primary-500');
  const [color, setColor] = useColor(currentPrimaryColor || '#006788');
  const [contrastRatio, setContrastRatio] = useState<number | null>(null);
  const [customLogo, setCustomLogo] = useAtom(customLogoAtom);
  const [fileUploadError, setFileUploadError] = useState<string | null>(null);

  useEffect(() => {
    const contrast = Number(calculateContrastRatio(color.hex, '#FFFFFF').toFixed(2));
    setContrastRatio(contrast);
  }, [color]);
  const changeColor = (col: IColor) => {
    setColor(col);
  };

  const setPrevColorPalette = useSetAtom(previousColorPalette);
  const [clientPreviewId, setClientPreviewId] = useAtom(clientPreviewIdAtom);
  const setCurrentClient = useSetAtom(userClientAtom);

  const updatePalette = () => {
    const primaryColorPalette = generatePalette(color.hex, 'primary');
    Object.entries(primaryColorPalette).forEach(([key, value]) => {
      const oldValue = getComputedStyle(document.documentElement).getPropertyValue(key);
      !clientPreviewId && setPrevColorPalette((prev: { [key: string]: string }) => ({
        ...prev,
        [key]: oldValue,
      }));
      document.documentElement.style.setProperty(key, value);
    });

    setCurrentClient(prev => ({ ...prev, primary_accent: color.hex }));
  };
  const startPreview = () => {
    updatePalette();
    uploadedLogo && setPreviewLogo({ logo: uploadedLogo, id: Number(clientId) });
    setIsCIDrawerOpen(false);
    clientId && setClientPreviewId(+clientId);
  };

  const onSave = async () => {
    const { statusCode, errorMessage } = await updateClientCI({
      id: +(clientId as string),
      color: color.hex,
      logo: uploadedLogo ?? undefined,
    });
    if (statusCode === 200) {
      updatePalette();
      setIsCIDrawerOpen(false);
      uploadedLogo && setCustomLogo({ logoUrl: uploadedLogo.preview, id: Number(clientId) });
      setUploadedLogo(null);
      setClientPreviewId(null);
      notify({ status: NotificationStatus.SUCCESS, text: { title: t('Success!'), body: t('Your changes have been saved.') } });
    } else {
      notify({ text: { body: t(errorMessage) } });
    }
  };

  const [showLogoUploader, setShowLogoUploader] = useState(false);

  const {
    getRootProps, getInputProps, isDragAccept,
    isDragReject,
  } = useDropzone({
    maxFiles: 1,
    maxSize: 1048576, // 1024 kb
    multiple: false,
    accept: {
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': ['.png'],
      'image/svg+xml': ['.svg'],
    },
    onDropAccepted: acceptedFiles => {
      setUploadedLogo(Object.assign(acceptedFiles[0], {
        preview: URL.createObjectURL(acceptedFiles[0]),
      }));
      setShowLogoUploader(false);
    },
    onDropRejected: (rejectedFiles) => {
      console.info(rejectedFiles[0].errors);
      setFileUploadError(rejectedFiles[0].errors[0].code);
    },
    onFileDialogOpen: () => {
      setFileUploadError(null);
    },
    onDragEnter: () => {
      setFileUploadError(null);
    },
  });

  const previewImage = (
    <div
      className={classNames(styles.logoPreview, {
        [styles.hidden]: showLogoUploader,
      })}
    >
      <div className={styles.logoPreview__image}>
        {uploadedLogo?.preview ? (
          <img
            alt=''
            src={uploadedLogo.preview}
          />
        ) : previewLogo?.logo.preview ? (
          <img
            alt=''
            src={previewLogo.logo.preview}
          />
        ) : customLogo?.logoUrl ? (
          <img
            alt=''
            src={customLogo.logoUrl}
          />
        ) : (
          <svg className={styles.logoPreview__default}>
            <use
              xlinkHref={`${LogoSvg}#logoSVG`}
              href={`${LogoSvg}#logoSVG`}
            />
          </svg>
        )}
      </div>
      {(uploadedLogo?.name || previewLogo?.logo.name)
        && <p className={styles.logoPreview__filename}>{uploadedLogo?.name || previewLogo?.logo.name}</p>}
    </div>
  );

  const [isColorPickerPopoverOpen, setIsColorPickerPopoverOpen] = useState(false);
  const [colorType, setColorType] = useState(ColorType.HEX);

  const [isColorCopied, setIsColorCopied] = useState(false);

  const copyColor = () => {
    if (!isColorCopied) {
      const copiedValue = colorType === ColorType.HEX ? color.hex
        : colorType === ColorType.RGB ? `rgb(${Math.round(color.rgb.r)}, ${Math.round(color.rgb.g)}, ${Math.round(color.rgb.b)})`
          : `hsv(${Math.round(color.hsv.h)}, ${Math.round(color.hsv.s)}%, ${Math.round(color.hsv.v)}%)`;
      navigator.clipboard.writeText(copiedValue);
      setIsColorCopied(true);

      setTimeout(() => setIsColorCopied(false), 1000);
    }
  };

  return (
    <div className={styles.ci}>
      <div>
        <div className={styles.ci__content}>
          <section className={styles.section}>
            <header className={styles.section__header}>
              <div>
                <p className={styles.section__title}>{t('Logo')}</p>
                <p className={styles.section__text}>{t('This logo will be used in the interface')}</p>
              </div>
              <Button
                variant={ButtonVariants.SECONDARY}
                onClick={() => setShowLogoUploader(prev => !prev)}
              >
                {t(showLogoUploader ? 'Cancel' : 'Replace logo')}
              </Button>
            </header>
            {showLogoUploader && (
              <div
                className={styles.dropzone}
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                <div className={classNames(styles.dragNdrop, {
                  [styles.accepted]: isDragAccept,
                  [styles.rejected]: isDragReject,
                })}
                >
                  <p className={styles.dragNdrop__title}>{t('Upload .png, .jpg or .svg file')}</p>
                  <p className={styles.dragNdrop__subtitle}>{t('Select file')}</p>
                  <p className={classNames(styles.dragNdrop__text, {
                    [styles.error]: fileUploadError,
                  })}
                  >
                    {t(fileUploadError ? FileUploadErrors[fileUploadError] : 'No file selected yet')}
                  </p>
                </div>
              </div>
            )}
            <div>
              {previewImage}
            </div>
          </section>
          <section className={styles.section}>
            <header className={styles.section__header}>
              <div>
                <p className={styles.section__title}>{t('Accent color')}</p>
                <p className={styles.section__text}>{t('This color will be user as main one for buttons, links, chart, etc.')}</p>
              </div>
            </header>
            <Popover
              paperClassName={styles.picker}
              className={styles.picker__container}
              placement={PopoverPlacement.SELECT}
              onOpen={() => setTimeout(() => setIsColorPickerPopoverOpen(true), 250)}
              onClose={() => setIsColorPickerPopoverOpen(false)}
              triggerButton={(
                <div
                  className={styles.currentColor}
                >
                  <div
                    className={styles.currentColor__preview}
                    style={{ background: color.hex }}
                  />
                  <div className={styles.currentColor__value}>{color.hex}</div>
                </div>
              )}
            >
              <div>
                <div className={classNames(styles.colorPicker, {
                  [styles.showHex]: colorType === ColorType.HEX,
                  [styles.showRgb]: colorType === ColorType.RGB,
                  [styles.showHsv]: colorType === ColorType.HSV,
                })}
                >
                  {isColorPickerPopoverOpen ? (
                    <ColorPicker
                      height={180}
                      color={color}
                      onChange={changeColor}
                      hideAlpha
                    />
                  ) : (
                    <div
                      className={styles.colorPicker__preloader}
                      style={{ height: 313 }}
                    >
                      <Loader />
                    </div>
                  )}
                  <div
                    className={styles.colorTypeSelect}
                  >
                    <Select
                      tileClassName={styles.colorTypeSelect__tile}
                      label={t('Select')}
                      options={[
                        { caption: 'HEX', value: ColorType.HEX },
                        { caption: 'RGB', value: ColorType.RGB },
                        { caption: 'HSV', value: ColorType.HSV },
                      ]}
                      value={colorType}
                      setValue={(e) => {
                        setColorType((e as SelectChangeEvent).target.value as ColorType);
                      }}
                      labelId='Change color type'
                      name='Change color type'
                      type='tile'
                      className={styles.colorTypeSelect__select}
                    />
                  </div>
                  <Button
                    className={styles.copyButton}
                    variant={ButtonVariants.SECONDARY}
                    onClick={copyColor}
                  >
                    {isColorCopied ? (
                      <svg>
                        <use
                          xlinkHref={`${CheckedSVG}#checkedSVG`}
                          href={`${CheckedSVG}#checkedSVG`}
                        />
                      </svg>
                    ) : (
                      <svg>
                        <use
                          xlinkHref={`${CopySVG}#copySVG`}
                          href={`${CopySVG}#copySVG`}
                        />
                      </svg>
                    )}
                  </Button>
                </div>
                <div className={styles.picker__content}>
                  <div className={styles.contrast}>
                    <div className={styles.contrast__box}>
                      <div className={styles.contrast__ratio}>
                        <div
                          className={styles.contrast__preview}
                          style={{ background: color.hex }}
                        >
                          A
                        </div>
                        <div>
                          <p className={styles.contrast__ratio__title}>{t('Contrast ratio')}</p>
                          <p className={styles.contrast__ratio__value}>{contrastRatio}</p>
                        </div>
                      </div>
                      <div className={styles.contrast__indicators}>
                        <div className={classNames(styles.contrast__indicator, {
                          [styles.failed]: contrastRatio && contrastRatio < MIN_AA_CONTRAST_RATIO,
                          [styles.passed]: contrastRatio && contrastRatio >= MIN_AA_CONTRAST_RATIO,
                        })}
                        >
                          {contrastRatio && contrastRatio >= MIN_AA_CONTRAST_RATIO ? (
                            <svg>
                              <use
                                xlinkHref={`${CheckedSVG}#checkedSVG`}
                                href={`${CheckedSVG}#checkedSVG`}
                              />
                            </svg>
                          ) : (
                            <svg>
                              <use
                                xlinkHref={`${CrossSVG}#closeSVG`}
                                href={`${CrossSVG}#closeSVG`}
                              />
                            </svg>
                          )}
                          AA
                        </div>
                        <div className={classNames(styles.contrast__indicator, {
                          [styles.passed]: contrastRatio && contrastRatio >= MIN_AAA_CONTRAST_RATIO,
                        })}
                        >
                          {contrastRatio && contrastRatio >= MIN_AAA_CONTRAST_RATIO && (
                            <svg>
                              <use
                                xlinkHref={`${CheckedSVG}#checkedSVG`}
                                href={`${CheckedSVG}#checkedSVG`}
                              />
                            </svg>
                          )}
                          AAA
                        </div>
                      </div>
                    </div>
                  </div>
                  <footer className={styles.picker__footer}>
                    <Tooltip
                      arrow
                      title={t(`If you want make sure colors you’ve picked will be usable and won’t cause
               any interface issues try keeping the contrast ratio higher than ${MIN_AA_CONTRAST_RATIO}:1 or “AA” indicator checked.`)}
                      placement='bottom-start'
                    >
                      <svg className={styles.infoIcon}>
                        <use
                          xlinkHref={`${InfoSVG}#infoSVG`}
                          href={`${InfoSVG}#infoSVG`}
                        />
                      </svg>
                    </Tooltip>
                    <p>{t('Keep contrast ratio higher than “AA”!')}</p>
                  </footer>
                </div>
              </div>
            </Popover>
          </section>
        </div>
      </div>
      <footer className={styles.ci__footer}>
        <Button
          className={styles.footerButton}
          variant={ButtonVariants.SECONDARY}
          onClick={startPreview}
        >
          {t('Preview')}
        </Button>
        <Button
          onClick={onSave}
          className={styles.footerButton}
        >
          {t('Confirm')}
        </Button>
      </footer>
    </div>
  );
};

export default ClientCI;
