import {
  FunctionComponent, useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { FormikValues, useFormik } from 'formik';
import * as Yup from 'yup';
import apiClient from '../../../apiClient';
import { NotificationStatus, notify } from '../../../store/notifications';
import { Permissions } from '../Login/user.props';
import { usePermissions } from '../../../usePermission';

import Input from '../../UIKit/Input/Input';
import Button from '../../UIKit/Button/Button';
import JiraSVG from '../../../public/media/jira.svg';

import styles from './ToolIntegration.module.scss';

interface IInstance {
  id: number,
  type: {
    value: number,
    caption: string,
    icon: string,
  },
  host: string,
  username: string,
  access_token: string
}

interface IJiraInstance {
  url: string,
  email: string,
  token: string
}

enum JiraInstanceFieldsEnum {
  url = 'url',
  email = 'email',
  token = 'token',
}

interface IField {
  id: string,
  label: string,
  name: JiraInstanceFieldsEnum,
}

const emptyJiraInstance: IJiraInstance = {
  url: '',
  email: '',
  token: '',
};

const jiraFields: IField[] = [
  {
    id: 'jira_url',
    label: 'URL',
    name: JiraInstanceFieldsEnum.url,
  },
  {
    id: 'jira_email',
    label: 'Email',
    name: JiraInstanceFieldsEnum.email,
  },
  {
    id: 'jira_token',
    label: 'Access token',
    name: JiraInstanceFieldsEnum.token,
  },
];

const errorTitleMessage = 'An error occurred';

const ToolIntegration: FunctionComponent = () => {
  const { t } = useTranslation();
  const { hasPermission } = usePermissions();

  const { clientId, id: selectedClientId } = useParams();
  const id = clientId ?? selectedClientId;

  const formSubmitHandler = async (values: IJiraInstance): Promise<void> => {
    const body = {
      type: 1,
      host: values.url,
      username: values.email,
      access_token: values.token,
    };

    apiClient.post<{ message: string, data?: IInstance }>(`clients/${id}/tool-integration`, {
      body: JSON.stringify(body),
    })
      .then(({ response, statusCode }) => {
        if (statusCode === 200 || statusCode === 201) {
          notify({ status: NotificationStatus.SUCCESS, text: { title: t('Success!'), body: t('Your changes have been saved.') } });
        } else {
          const { message } = response;

          if (message) {
            notify({ text: { title: t(errorTitleMessage), body: message } });
          } else {
            notify({ text: { title: t(errorTitleMessage) } });
          }
        }
      })
      .catch((error) => {
        notify({ text: { title: t(errorTitleMessage) } });
        console.error(error);
      });
  };

  const {
    setValues,
    handleSubmit,
    values,
    handleChange,
    handleBlur,
    touched,
    errors,
    isSubmitting,
  }: FormikValues = useFormik({
    initialValues: emptyJiraInstance,
    validationSchema: Yup.object({
      url: Yup.string().trim().required(),
      email: Yup.string().trim().required(),
      token: Yup.string().trim().required(),

    }),
    onSubmit: formSubmitHandler,
  });

  useEffect(() => {
    apiClient.get<{ data: IInstance[] }>(`clients/${id}/tool-integration`)
      .then(({ response }) => {
        setValues({
          url: response.data[0].host ?? '',
          email: response.data[0].username ?? '',
          token: response.data[0].access_token ?? '',
        });
      })
      .catch((error) => {
        notify({ text: { title: t(errorTitleMessage) } });
        console.error(error);
      });
  }, []);

  return (
    <article className={styles.integration}>
      <form
        onSubmit={handleSubmit}
        className={styles.jira}
      >
        <h5 className={styles.jira__title}>
          <svg className={styles.jira__title__icon}>
            <use
              xlinkHref={`${JiraSVG}#jiraSVG`}
              href={`${JiraSVG}#jiraSVG`}
            />
          </svg>
          {t('Access to Jira instance')}
        </h5>

        <section className={styles.jira__fields}>
          {jiraFields.map(field => (
            <Input
              key={field.id}
              id={field.id}
              name={field.name}
              value={values[field.name]}
              setValue={handleChange}
              onBlur={handleBlur}
              label={t(field.label)}
              error={Boolean(touched[field.name] && errors[field.name])}
              errorMessage={errors[field.name]}
            />
          ))}
        </section>
        {hasPermission(Permissions.INTEGRATION_SAVE) && (
          <Button
            type='submit'
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            {t('Save changes')}
          </Button>
        )}
      </form>
    </article>
  );
};

export default ToolIntegration;
