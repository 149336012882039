import * as Yup from 'yup';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useAtomValue } from 'jotai';

import classNames from 'classnames';
import Button, { ButtonVariants } from '../../../UIKit/Button/Button';
import DatePicker from '../../../UIKit/DatePicker/DatePicker';
import Input from '../../../UIKit/Input/Input';
import Select from '../../../UIKit/Select/Select';
import ListPlaceholder from '../../../UIKit/ListPlaceholder/ListPlaceholder';

import { ConstraintResource, ConstraintStatus } from '../types';
import { DEFAULT_DATE_FORMAT } from '../../../../constants';
import { Deliverable } from '../../NewProject/NewProjectDeliverables/types';
import { userAtom } from '../../../../store/auth';

import DeleteSVG from '../../../../public/media/delete-icon.svg';
import styles from './Constraints.module.scss';

type ConstraintFormProps = {
  submitForm: (values: Record<string, any>) => void,
  constraint: ConstraintResource | null,
  submitButtonText: string,
  deliverables: Deliverable[],
  onDelete: () => void,
  isGateFinished: boolean
};

const statusOptions = [
  { caption: 'Open', value: ConstraintStatus.OPEN },
  { caption: 'In progress', value: ConstraintStatus.IN_PROGRESS },
  { caption: 'Done', value: ConstraintStatus.DONE },
];

const DATE_FORMAT = 'YYYY-MM-DD';

const ConstraintForm = ({
  submitForm, constraint, submitButtonText, deliverables, onDelete, isGateFinished,
}: ConstraintFormProps) => {
  const { t } = useTranslation();
  const userData = useAtomValue(userAtom);

  const {
    handleSubmit, values, handleChange, handleBlur, touched, errors, setFieldValue, resetForm, isSubmitting,
  } = useFormik({
    initialValues: {
      deliverable_id: constraint?.deliverable?.id ? String(constraint?.deliverable?.id) : '',
      caption: constraint?.caption ?? '',
      impact: constraint?.impact ?? '',
      action: constraint?.action ?? '',
      responsibility: constraint?.responsibility ?? '',
      due_date: constraint?.due_date ?? null,
      status: constraint?.status.value.toString() ?? '',
    },
    validationSchema: Yup.object({
      deliverable_id: Yup.string().trim().required(t('Deliverable is required')),
      caption: Yup.string().trim().required(t('Constraint is required')).max(100, t('Must be 100 characters at most')),
      impact: Yup.string().trim().required(t('Impact is required')).max(100, t('Must be 100 characters at most')),
      action: Yup.string().trim().required(t('Mitigation actions are required')).max(100, t('Must be 100 characters at most')),
      responsibility: Yup.string().trim().required(t('Responsibility is required')).max(100, t('Must be 100 characters at most')),
      status: Yup.number().required(t('Status is required')),
      due_date: Yup.date().nullable().transform((value) => {
        if (dayjs(value).isValid()) {
          return value;
        }
      })
        .min(dayjs('01/01/2000').toDate(), t('Start date can not be set earlier than 01.01.2000'))
        .max(dayjs('01/01/2500').toDate(), t('The end date cannot be later than 01.01.2500'))
        .when(['type'], (type, schema) => (+type === 2 ? schema.required(t('Due date is required')) : schema))
        .required(t('Due date is required')),
    }),
    onSubmit: submitForm,
  });

  if (!deliverables.length && !constraint) {
    return <ListPlaceholder placeholderText={t('Add the deliverable to the quality gate first to proceed efficiently.')} />;
  }

  return (
    <form
      className={styles.constraintFrom}
      onSubmit={handleSubmit}
    >
      <Select
        label={t('Deliverable')}
        options={deliverables.map(({ caption, id }) => ({ caption, value: `${id}` }))}
        value={values.deliverable_id}
        setValue={handleChange}
        onBlur={handleBlur}
        labelId='deliverable_id'
        name='deliverable_id'
        error={!!(touched.deliverable_id && errors.deliverable_id)}
        errorMessage={errors.deliverable_id}
        className={styles.constraintFrom__select}
      />
      <Input
        value={values.caption}
        setValue={handleChange}
        onBlur={handleBlur}
        id='caption'
        label={t('Constraint')}
        name='caption'
        error={!!(touched.caption && errors.caption)}
        errorMessage={errors.caption}
      />
      <Input
        value={values.impact}
        setValue={handleChange}
        onBlur={handleBlur}
        id='impact'
        label={t('Impact')}
        name='impact'
        error={!!(touched.impact && errors.impact)}
        errorMessage={errors.impact}
      />
      <Input
        value={values.action}
        setValue={handleChange}
        onBlur={handleBlur}
        id='action'
        label={t('Mitigation actions')}
        name='action'
        error={!!(touched.action && errors.action)}
        errorMessage={errors.action}
      />
      <Select
        label={t('Status')}
        options={statusOptions.map(status => ({ caption: t(status.caption), value: status.value.toString() }))}
        value={values.status}
        setValue={handleChange}
        onBlur={handleBlur}
        labelId='status'
        name='status'
        error={!!(touched.status && errors.status)}
        errorMessage={errors.status}
      />
      <fieldset className={styles.inputGroup}>
        <Input
          value={values.responsibility}
          setValue={handleChange}
          onBlur={handleBlur}
          id='responsibility'
          label={t('Responsibility')}
          name='responsibility'
          error={!!(touched.responsibility && errors.responsibility)}
          errorMessage={errors.responsibility}
        />
        <DatePicker
          label={t('Select due date')}
          format={userData?.user.dateFormat ?? DEFAULT_DATE_FORMAT}
          value={values.due_date ? dayjs(values.due_date) : null}
          setValue={date => setFieldValue('due_date', dayjs(date).format(DATE_FORMAT))}
          error={!!(touched.due_date && errors.due_date)}
          errorMessage={errors.due_date}
        />
      </fieldset>
      <div className={classNames(styles.constraintFrom__footer, {
        [styles.constraintFrom__footer_simple]: !constraint,
        [styles.constraintFrom__footer_gateFinished]: isGateFinished,
      })}
      >
        <Button
          className={styles.constraintFrom__button}
          type='submit'
          loading={isSubmitting}
          disabled={isSubmitting}
        >
          {submitButtonText}
        </Button>
        {constraint && (
          <>
            <Button
              variant={ButtonVariants.SECONDARY}
              className={styles.constraintFrom__button}
              onClick={() => resetForm()}
              type='reset'
            >
              {t('Reset changes')}
            </Button>
            {!isGateFinished && (
            <Button
              variant={ButtonVariants.SECONDARY}
              onClick={onDelete}
              className={styles.constraintFrom__button_remove}
            >
              <svg>
                <use
                  xlinkHref={`${DeleteSVG}#deleteSVG`}
                  href={`${DeleteSVG}#deleteSVG`}
                />
              </svg>
            </Button>
            )}
          </>
        )}
      </div>
    </form>
  );
};

export default ConstraintForm;
