import React, { useEffect } from 'react';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import DisableTwoFactorAuthForm from '../UserForm/DisableTwoFactorAuthForm/DisableTwoFactorAuthForm';
import TwoFactorAuthForm from '../UserForm/TwoFactorAuthForm/TwoFactorAuthForm';
import RecoveryCode from '../UserForm/RecoveryCode/RecoveryCode';

import { twoFARecoveryCode } from '../../store/auth';

type TwoFactorAuthProps = {
  is2FAEnable: boolean;
  setDrawerTitle: React.Dispatch<React.SetStateAction<string>>;
  closeDrawer: () => void;
};

const TwoFactorAuth = ({ is2FAEnable, setDrawerTitle, closeDrawer }: TwoFactorAuthProps) => {
  const { t } = useTranslation();

  const [recoveryCode, setRecoveryCode] = useAtom(twoFARecoveryCode);

  useEffect(() => {
    setDrawerTitle(
      recoveryCode.length
        ? t('Two-factor authentication recovery codes')
        : is2FAEnable
          ? t('Disable two-factor authentication')
          : t('Register a one-time password authentication'),
    );
  }, [is2FAEnable, recoveryCode.length]);

  useEffect(() => () => {
    setRecoveryCode([]);
  }, []);

  if (recoveryCode.length) {
    return <RecoveryCode />;
  }

  if (is2FAEnable) {
    return <DisableTwoFactorAuthForm {...{ closeDrawer }} />;
  }

  return <TwoFactorAuthForm />;
};

export default TwoFactorAuth;
