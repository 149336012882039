import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { TFunction } from 'i18next';
import VotingToggle from './VotingToggle';
import styles from './Voting.module.scss';
import PopoverOptions from '../../../UIKit/PopoverOptions/PopoverOptions';
import { PopoverPlacement } from '../../../UIKit/Popover/Popover';
import DeleteSVG from '../../../../public/media/delete-icon.svg';
import EditSVG from '../../../../public/media/edit-icon.svg';
import { ParticipantResource, ParticipantVote } from '../types';
import Loader from '../../../Loader/Loader';

import { EnumToStringValues } from '../../NewProject/NewProjectSummary/types';

const COLUMN_NAMES = ['Role', 'Name', 'Deputy', 'Voting'];

const contextMenuOptions = (t: TFunction<'translation', undefined>, onEdit: () => void, onDelete: () => void) => [
  {
    id: 0,
    title: (
      <div className={styles.action__option}>
        <svg className='tableRowActionIcon'>
          <use
            xlinkHref={`${EditSVG}#editSVG`}
            href={`${EditSVG}#editSVG`}
          />
        </svg>
        <p>{t('Edit participant')}</p>
      </div>
    ),
    handler: onEdit,
  },
  {
    id: 1,
    title: (
      <div className={styles.action__option}>
        <svg className='tableRowActionIcon'>
          <use
            xlinkHref={`${DeleteSVG}#deleteSVG`}
            href={`${DeleteSVG}#deleteSVG`}
          />
        </svg>
        <p>{t('Delete')}</p>
      </div>
    ),
    handler: onDelete,
  },
];

type VotingListProps = {
  onEditParticipantClick: (participant: ParticipantResource) => void;
  onDeleteParticipantClick: (participant: ParticipantResource) => void;
  projectGateVotes: ParticipantResource[] | null;
  isLoading: boolean;
  onVote: (participant: ParticipantResource, vote: EnumToStringValues<typeof ParticipantVote>) => void;
  isGateActive: boolean;
  hasExecutePermission?: boolean;
  isEditModeActive: boolean;
};

const VotingList = ({
  onEditParticipantClick,
  onDeleteParticipantClick,
  projectGateVotes,
  isLoading,
  onVote,
  isGateActive,
  hasExecutePermission,
  isEditModeActive,
}: VotingListProps) => {
  const { t } = useTranslation();

  return (
    <section className={styles.list}>
      <div className={classNames(styles.list__body)}>
        {isLoading && !!projectGateVotes ? (
          <Loader className={styles.list__loader} />
        ) : (
          <>
            <header className={classNames(styles.list__header, styles.list__row)}>
              {COLUMN_NAMES.map((name) => (
                <p key={name}>{t(name)}</p>
              ))}
            </header>
            {projectGateVotes?.map((participant) => (
              <div
                key={participant.id}
                className={styles.list__row}
              >
                <p className={styles.role}>{participant.role}</p>
                <p className={styles.list__row__name}>{participant.name}</p>
                <p className={styles.list__row__deputy}>{participant.deputy}</p>
                {hasExecutePermission && (
                  isGateActive || isEditModeActive
                ) ? (
                  <VotingToggle
                    t={t}
                    setValue={(vote) => onVote(participant, vote as unknown as EnumToStringValues<typeof ParticipantVote>)}
                    value={String(participant.vote.value)}
                  />
                  ) : (
                    <div
                      className={classNames(styles.vote, {
                        [styles.vote_notVoted]: participant.vote.value === ParticipantVote.NOT_VOTED,
                        [styles.vote_passed]: participant.vote.value === ParticipantVote.PASS,
                        [styles.vote_constraint]: participant.vote.value === ParticipantVote.PASS_WITH_CONSTRAINTS,
                        [styles.vote_fail]: participant.vote.value === ParticipantVote.FAIL,
                      })}
                    >
                      <p>{t(participant.vote.caption)}</p>
                    </div>
                  )}
                {isGateActive && hasExecutePermission && (
                  <PopoverOptions
                    className={styles.list__actionBtn__wrapper}
                    buttonClassName={styles.list__actionBtn}
                    options={contextMenuOptions(
                      t,
                      () => onEditParticipantClick(participant),
                      () => onDeleteParticipantClick(participant),
                    )}
                    placement={PopoverPlacement.LEFT}
                  />
                )}
              </div>
            ))}
          </>
        )}
      </div>
    </section>
  );
};

export default VotingList;
