import { useAtom, useSetAtom } from 'jotai';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { mutate } from 'swr';
import apiClient from '../../../../apiClient.ts';
import { ReactComponent as DeleteClientSvg } from '../../../../public/media/delete-client.svg';
import DotsSVG from '../../../../public/media/dots.svg';
import { userClientAtom } from '../../../../store/auth.ts';
import { customLogoAtom } from '../../../../store/clientCI.ts';
import { clientsAtom } from '../../../../store/clients.ts';
import { updateCI } from '../../../../updateCI.ts';
import { truncateText } from '../../../../utils';
import { ClientProps } from '../../../AdminSidebar/client.props.ts';
import ClientCI from '../../../ClientCI/ClientCI';
import ClientForm from '../../../ClientForm/ClientForm';
import Button, { ButtonVariants } from '../../../UIKit/Button/Button';
import Drawer from '../../../UIKit/Drawer/Drawer';
import Modal from '../../../UIKit/Modal/Modal';
import Popover, { PopoverPlacement } from '../../../UIKit/Popover/Popover';
import styles from './ClientsHeaderDropdown.module.scss';

// This one can not be removed
const DEFAULT_CLIENT_ID = 1;
const ClientsHeaderDropdown = ({ activeClient, isGlobalAdmin }: { activeClient: ClientProps, isGlobalAdmin: boolean }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [allClients, setAllClients] = useAtom(clientsAtom);
  const setCurrentClient = useSetAtom(userClientAtom);
  const setCustomLogo = useSetAtom(customLogoAtom);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isCIDrawerOpen, setIsCIDrawerOpen] = useState(false);

  const [closePopover, setClosePopover] = useState<() => void>(() => {
  });

  const deleteClient = async (id: number) => {
    try {
      const { statusCode } = await apiClient.delete(`clients/${id}`);

      if (statusCode === 200) {
        const updatedClients = allClients.filter(client => client.id !== id);

        setAllClients(updatedClients);
        setIsModalVisible(false);
        setCurrentClient(updatedClients[0]);
        updateCI(updatedClients[0], setCustomLogo);
        return navigate('/');
      }
    } catch (e) {
      console.error(e);
    }
  };

  const editClient = async (id: number, body: Record<string, any>) => {
    try {
      const { statusCode } = await apiClient.put(`clients/${id}`, {
        body: JSON.stringify(body),
      });

      if (statusCode === 200) {
        const updatedClients = allClients.map(client => {
          if (client.id === id) {
            client.name = body.name;
          }

          return client;
        });

        await mutate(
          (key: any[]) => key.includes(`clients/${activeClient.id}`),
          undefined,
          { revalidate: true },
        );

        setCurrentClient(prev => ({ ...prev, name: body.name }));

        setAllClients(updatedClients);
        setIsDrawerOpen(false);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const closeCallback = (func: () => void) => {
    setClosePopover(() => func);
  };

  return (
    <div>
      <Popover
        paperClassName={styles.settings}
        placement={PopoverPlacement.CONTEXT_MENU}
        triggerButton={(
          <div className={styles.trigger}>
            <svg>
              <use
                xlinkHref={`${DotsSVG}#dotsSVG`}
                href={`${DotsSVG}#dotsSVG`}
              />
            </svg>
          </div>
        )}
        closeCallback={closeCallback}
      >
        <div className={styles.menu}>
          <button
            type='button'
            onClick={() => {
              setIsDrawerOpen(true);
              if (typeof closePopover === 'function') {
                closePopover();
              }
            }}
            className={styles.menu__item}
          >
            {t('Edit client name')}
          </button>
          {(isGlobalAdmin && (activeClient.id !== DEFAULT_CLIENT_ID)) && (
            <button
              type='button'
              onClick={() => {
                setIsModalVisible(true);
                if (typeof closePopover === 'function') {
                  closePopover();
                }
              }}
              className={styles.menu__item}
            >
              {t('Delete client')}
            </button>
          )}
          <button
            type='button'
            onClick={() => {
              setIsCIDrawerOpen(true);
              if (typeof closePopover === 'function') {
                closePopover();
              }
            }}
            className={styles.menu__item}
          >
            {t('Edit client CI')}
          </button>
        </div>
      </Popover>
      {isModalVisible && (
        <Modal
          closeModal={() => setIsModalVisible(false)}
        >
          <div className={styles.modal}>
            <DeleteClientSvg />
            <h4>{`${t('Are you sure you want to delete the client')} ${truncateText(24, activeClient.name)}?`}</h4>
            <div className={styles.modal__buttons}>
              <Button
                variant={ButtonVariants.SECONDARY}
                onClick={() => setIsModalVisible(false)}
              >
                Cancel
              </Button>
              <Button onClick={() => deleteClient(activeClient.id)}>{t('Yes, delete')}</Button>
            </div>
          </div>
        </Modal>
      )}
      <Drawer
        isOpen={isDrawerOpen}
        setIsOpen={setIsDrawerOpen}
        title={t('Edit client name')}
        className={styles.drawer}
      >
        <ClientForm
          submitForm={(values) => editClient(activeClient.id, values)}
          submitButtonText={t('Save changes')}
          client={activeClient}
        />
      </Drawer>
      <Drawer
        isOpen={isCIDrawerOpen}
        setIsOpen={setIsCIDrawerOpen}
        title={t('Configure the look and feel')}
        className={styles.cIdrawer}
      >
        <ClientCI setIsCIDrawerOpen={setIsCIDrawerOpen} />
      </Drawer>
    </div>
  );
};

export default ClientsHeaderDropdown;
