import classNames from 'classnames';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { updateClientCI } from '../../actions/clientCiLoader.ts';
import AngleRightSVG from '../../public/media/angle_right.svg';
import EyeSVG from '../../public/media/eye.svg';

import {
  clientPreviewIdAtom, customLogoAtom, previewLogoAtom, previousColorPalette,
} from '../../store/clientCI.ts';
import { NotificationStatus, notify } from '../../store/notifications.ts';
import Button, { ButtonVariants } from '../UIKit/Button/Button.tsx';
import styles from './ClientCIConfirm.module.scss';

const ClientCiConfirm = () => {
  const { t } = useTranslation();

  const prevColorPalette = useAtomValue(previousColorPalette);
  const [clientPreviewId, setClientPreviewId] = useAtom(clientPreviewIdAtom);
  const setNewLogo = useSetAtom(previewLogoAtom);
  const previewLogo = useAtomValue(previewLogoAtom);
  const setCustomLogo = useSetAtom(customLogoAtom);

  const [isHidden, setIsHidden] = useState(false);

  const exitPreview = () => {
    Object.entries(prevColorPalette).forEach(([key, value]) => {
      document.documentElement.style.setProperty(key, value);
    });
    setClientPreviewId(null);
    setNewLogo(null);
  };

  const onSave = async () => {
    const newColor = document.documentElement.style.getPropertyValue('--primary-500');
    if (clientPreviewId !== null) {
      const { statusCode, errorMessage } = await updateClientCI({
        id: clientPreviewId,
        color: newColor,
        logo: previewLogo?.logo ?? undefined,
      });
      if (statusCode === 200) {
        setClientPreviewId(null);
        previewLogo?.logo && setCustomLogo({ logoUrl: previewLogo?.logo.preview, id: Number(clientPreviewId) });
        notify({ status: NotificationStatus.SUCCESS, text: { title: t('Success!'), body: t('Your changes have been saved.') } });
      } else {
        notify({ text: { title: t('An error occurred'), body: t(errorMessage) } });
      }
    }
  };

  return (
    <div className={classNames(styles.preview, {
      [styles.hidden]: isHidden,
    })}
    >
      <button
        type='button'
        className={classNames(styles.showPreviewButton, {
          [styles.disabled]: !isHidden,
        })}
        onClick={() => setIsHidden(false)}
      >
        <svg className={styles.eyeSVG}>
          <use
            xlinkHref={`${EyeSVG}#eyeSVG`}
            href={`${EyeSVG}#eyeSVG`}
          />
        </svg>
      </button>
      <div className={styles.preview__content}>
        <p className={styles.preview__text}>{t('CI changes preview mode')}</p>
        <Button onClick={onSave}>{t('Save')}</Button>
        <Button
          variant={ButtonVariants.SECONDARY}
          onClick={exitPreview}
        >
          {t('Exit')}
        </Button>
        <Button
          variant={ButtonVariants.SECONDARY}
          className={styles.hidePreviewButton}
          onClick={() => setIsHidden(true)}
        >
          <svg className={styles.angleSVG}>
            <use
              xlinkHref={`${AngleRightSVG}#angleRightSVG`}
              href={`${AngleRightSVG}#angleRightSVG`}
            />
          </svg>
        </Button>
      </div>
    </div>
  );
};

export default ClientCiConfirm;
