import {
  FunctionComponent, useCallback, useEffect, useState,
} from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import PopoverOptions from '../../../UIKit/PopoverOptions/PopoverOptions';
import { PopoverPlacement } from '../../../UIKit/Popover/Popover';
import { Permissions } from '../../Login/user.props.ts';
import { GENERAL_FEATURE_FLAG_KEY, isUserHasFeatureAccess } from '../../../../actions/main.loader.ts';

import AngleDownSVG from '../../../../public/media/angle-down.svg';
import UsersSVG from '../../../../public/media/users.svg';
import CustomersSVG from '../../../../public/media/customers.svg';
import TemplatesSVG from '../../../../public/media/project_templates.svg';
import ProjectsSVG from '../../../../public/media/projects.svg';
import DownloadSVG from '../../../../public/media/download.svg';

import { arrayAt } from '../../../../utils/arrayAt.ts';

import styles from './MobileTabDropdown.module.scss';

export type TabRoutes = {
  [key: string]: {
    name: string,
    icon: {
      path: string,
      id: string,
    },
    permission?: Permissions,
  }
};

export const tabRoutes: TabRoutes = {
  users: {
    name: 'Users',
    icon: {
      path: UsersSVG,
      id: 'usersSVG',
    },
  },
  customers: {
    name: 'Customers',
    icon: {
      path: CustomersSVG,
      id: 'customersSVG',
    },
  },
  templates: {
    name: 'Project templates',
    icon: {
      path: TemplatesSVG,
      id: 'templatesSVG',
    },
    permission: Permissions.TEMPLATE_VIEW,
  },
  projects: {
    name: 'Projects',
    icon: {
      path: ProjectsSVG,
      id: 'projectsSVG',
    },
  },
  ...(isUserHasFeatureAccess(GENERAL_FEATURE_FLAG_KEY) ? {
    tool_integration: {
      name: 'Tool integration',
      icon: {
        path: DownloadSVG,
        id: 'downloadSVG',
      },
    },
  } : {}),
};

interface MobileTabDropdownProps {
  routes: string[],
  clientId?: string
}

const MobileTabDropdown: FunctionComponent<MobileTabDropdownProps> = ({ routes, clientId }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const [selectedTab, setSelectedTab] = useState<string>();

  useEffect(() => {
    setSelectedTab(arrayAt(location.pathname.split('/'), -1));
  }, [location]);

  const navigateCallback = useCallback((route: string) => () => {
    navigate(`${clientId}/${route}`);
    setSelectedTab(route);
  }, [clientId]);

  const renderOptionTitle = (route: string) => (
    <p className={classNames(styles.popoverOption, {
      [styles.activeOption]: selectedTab === route,
    })}
    >
      <svg className={styles.tab__icon}>
        <use
          xlinkHref={`${tabRoutes[route].icon.path}#${tabRoutes[route].icon.id}`}
          href={`${tabRoutes[route].icon.path}#${tabRoutes[route].icon.id}`}
        />
      </svg>
      <span className={styles.popoverOption__text}>{t(tabRoutes[route].name)}</span>
    </p>
  );

  const renderOptions = () => routes.map((route, index) => ({
    id: index,
    title: renderOptionTitle(route),
    handler: navigateCallback(route),
  }));

  const renderPopoverButton = () => (selectedTab && tabRoutes[selectedTab] ? (
    <div className={styles.popoverButton__content}>
      <svg className={styles.tab__icon}>
        <use
          xlinkHref={`${tabRoutes[selectedTab].icon.path}#${tabRoutes[selectedTab].icon.id}`}
          href={`${tabRoutes[selectedTab].icon.path}#${tabRoutes[selectedTab].icon.id}`}
        />
      </svg>
      {t(tabRoutes[selectedTab].name)}
      <svg className={styles.downloadButton__arrow}>
        <use
          xlinkHref={`${AngleDownSVG}#angleDownSVG`}
          href={`${AngleDownSVG}#angleDownSVG`}
        />
      </svg>
    </div>
  ) : null);

  return (
    <section className={styles.mobile__tabsSelect__wrapper}>
      <PopoverOptions
        customButton={renderPopoverButton()}
        options={renderOptions()}
        paperClassName={styles.clients__popoverPaper}
        placement={PopoverPlacement.CONTEXT_MENU_LEFT}
      />
    </section>
  );
};

export default MobileTabDropdown;
