import classNames from 'classnames';
import React, { useState } from 'react';
import DotsSVG from '../../../public/media/dots.svg';
import Popover, { PopoverPlacement } from '../Popover/Popover';
import styles from './PopoverOptions.module.scss';

type PopoverOptionsProps = {
  customButton?: React.ReactNode,
  options: IPopoverOptions[],
  buttonClassName?: string,
  className?: string,
  paperClassName?: string,
  placement?: PopoverPlacement,
  onClose?: () => void,
};

export interface IPopoverOptions {
  id: number,
  title: string | JSX.Element,
  handler: () => void,
  preventClose?: boolean,
  hidden?: boolean,
}
const PopoverOptions = ({
  customButton, options, buttonClassName, className, paperClassName, placement = PopoverPlacement.CONTEXT_MENU, onClose,
}: PopoverOptionsProps) => {
  const [closePopover, setClosePopover] = useState<() => void>(() => {
  });

  const closeCallback = (func: () => void) => {
    setClosePopover(() => func);
  };

  return (
    <Popover
      paperClassName={classNames(styles.popoverPaper, paperClassName)}
      className={className}
      placement={placement || PopoverPlacement.CONTEXT_MENU}
      triggerButton={customButton || (
        <div className={classNames(styles.button, buttonClassName)}>
          <svg>
            <use
              xlinkHref={`${DotsSVG}#dotsSVG`}
              href={`${DotsSVG}#dotsSVG`}
            />
          </svg>
        </div>
      )}
      closeCallback={closeCallback}
      onClose={onClose}
    >
      {options.map(option => (
        !option.hidden && (
          <button
            key={option.id}
            type='button'
            onClick={() => {
              option.handler();
              !option.preventClose && closePopover();
            }}
            className={styles.option}
          >
            {option.title}
          </button>
        )
      ))}
    </Popover>
  );
};

export default PopoverOptions;
