import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import { useParams, useRouteLoaderData } from 'react-router-dom';
import React, { useRef, useState } from 'react';
import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  rectIntersection,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { DragEndEvent, DragOverEvent, DragStartEvent } from '@dnd-kit/core/dist/types';
import styles from './QualityGates.module.scss';
import apiClient from '../../../../../apiClient.ts';
import QualityList from './QualityList/QualityList.tsx';
import { GateResource } from '../../../QualityGate/types';
import GateDeliverable from './GateDeliverable/GateDeliverable.tsx';
import { Deliverable } from '../../NewProjectDeliverables/types.ts';
import Loader from '../../../../Loader/Loader.tsx';
import PlusSVG from '../../../../../public/media/plus.svg';
import { notify } from '../../../../../store/notifications.ts';
import Drawer from '../../../../UIKit/Drawer/Drawer.tsx';
import QualityGateForm from '../../../QualityGate/QualityGateForm/QualityGateForm.tsx';
import { ProjectPermissions } from '../../../Login/user.props.ts';
import EmptyPage from '../../../../../public/media/summary/quality-gates/empty-illustration.svg';
import { ProjectResource } from '../../../Projects/types.ts';

type DraggingElement = {
  deliverable: Deliverable,
  gateId: number,
  gateDueDate: string,
  sortable: {
    index: number
  }
};

const QualityGates = () => {
  const { projectId } = useParams();
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const { t: validationT } = useTranslation('validation');
  const initProjectData = (useRouteLoaderData('project') as { project: ProjectResource })?.project;

  const [gates, setGates] = useState<GateResource[] | null>(null);
  const [permissions, setPermissions] = useState<string[]>([]);
  const previousState = useRef<GateResource[]>([]);

  const [draggingElement, setDraggingElement] = useState<DraggingElement | null>(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const { isLoading, mutate } = useSWR(
    [`projects/${projectId}/quality-gates?with=deliverables.links_count,deliverables.task&page=all`, i18n.language, projectId],
    ([url]) => apiClient
      .get<{ data: GateResource[], permissions: string[] }>(url).then(({ response }) => response),
    {
      revalidateOnFocus: false,
      keepPreviousData: false,
      revalidateOnMount: true,
      onSuccess: (response) => {
        setGates(response.data ?? []);
        setPermissions(response.permissions ?? []);
      },
    },
  );

  const updateDeliverable = async (gateId: number, deliverableId: number, newIndex: number) => {
    const { statusCode } = await apiClient.put(`projects/${projectId}/deliverables/${deliverableId}`, {
      body: JSON.stringify({ quality_gate: gateId, order: newIndex }),
    });

    if (statusCode !== 200) throw new Error();
  };

  const handlerDragStart = (event: DragStartEvent) => {
    const active: any = event.active.data.current;

    if (!active) return;

    setDraggingElement(active);
    previousState.current = gates?.map((gate) => ({
      ...gate,
      deliverables: gate.deliverables.map((deliverable) => deliverable),
    })) ?? [];
  };

  const handleDragOver = (event: DragOverEvent) => {
    const over = event.over?.data.current!;
    const active = event.active?.data.current!;

    if (over !== active) {
      const overGate = gates?.find(({ id }) => over?.gateId === id);
      const activeGate = gates?.find(({ id }) => active?.gateId === id);

      if (overGate && activeGate) {
        activeGate.deliverables = activeGate.deliverables.filter(({ id }) => id !== active.deliverable.id);

        if ('sortable' in over) {
          overGate.deliverables.splice(over.sortable.index, 0, active.deliverable);
        } else {
          overGate.deliverables.push(active.deliverable);
        }
      }
    }

    if (gates?.length) {
      setGates([...gates]);
    }
  };

  const handleDragEnd = async (event: DragEndEvent) => {
    const active = event.active?.data.current!;
    const over = event.over?.data.current!;
    const activeGate = gates?.find(({ id }) => active?.gateId === id);

    if (!activeGate || !over) return;

    let newIndex: null | number = null;

    for (let i = 0; i < activeGate.deliverables.length; i += 1) {
      if (activeGate.deliverables[i].id === active.deliverable.id) {
        newIndex = i;
        break;
      }
    }

    if (newIndex == null || (draggingElement?.sortable.index === newIndex && draggingElement.gateId === over.gateId)) return;

    try {
      await updateDeliverable(active.gateId, active.deliverable.id, newIndex);
    } catch (e) {
      console.error(e);
      notify();
      setGates(previousState.current);
    } finally {
      setDraggingElement(null);
    }
  };

  const addNewQualityGate = async (body: Record<string, any>) => {
    try {
      const { statusCode, ...response } = await apiClient.post<{ message?: 'string' }>(`projects/${projectId}/quality-gates`, {
        body: JSON.stringify(body),
      });

      if (statusCode === 201) {
        await mutate();
      } else {
        throw new Error(response.response?.message && validationT(response.response?.message));
      }
    } catch (e) {
      console.error(e);
      notify({ text: (e.message ? { body: e.message } : {}) });
    } finally {
      setIsDrawerOpen(false);
    }
  };

  return (
    <>
      <DndContext
        sensors={sensors}
        collisionDetection={rectIntersection}
        onDragStart={handlerDragStart}
        onDragOver={handleDragOver}
        onDragEnd={handleDragEnd}
        autoScroll={{ acceleration: 1 }}
      >
        <div className={styles.board}>
          {(isLoading || !gates) ? (
            <div className={styles.loader}>
              <Loader size={44} />
            </div>
          ) : (
            gates?.length ? gates.map(gate => (
              <QualityList
                gate={gate}
                key={gate.id}
                mutate={mutate}
                permissions={permissions}
              />
            )) : (
              <div className={styles.empty}>
                <svg>
                  <use
                    xlinkHref={`${EmptyPage}#emptyIllustration`}
                    href={`${EmptyPage}#emptyIllustration`}
                  />
                </svg>
                <p>{t('Quality gates board is empty')}</p>
              </div>
            )
          )}
          {permissions?.includes(ProjectPermissions.CREATE) && (
            <button
              type='button'
              className={styles.board__add}
              onClick={() => setIsDrawerOpen(true)}
            >
              <svg>
                <use
                  xlinkHref={`${PlusSVG}#plusSVG`}
                  href={`${PlusSVG}#plusSVG`}
                />
              </svg>
            </button>
          )}
        </div>
        <DragOverlay>
          {draggingElement ? (
            <GateDeliverable
              deliverable={draggingElement.deliverable}
              gateDueDate={draggingElement.gateDueDate}
              gateId={draggingElement.gateId}
              draggingElement
            />
          ) : null}
        </DragOverlay>
      </DndContext>
      <Drawer
        isOpen={isDrawerOpen}
        setIsOpen={setIsDrawerOpen}
        title={t('Add quality gate')}
      >
        <QualityGateForm
          submit={addNewQualityGate}
          projectTimeline={{ from: initProjectData?.begin, to: initProjectData?.end }}
        />
      </Drawer>
    </>
  );
};

export default QualityGates;
