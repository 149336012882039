import { atom, createStore } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

export type Locales = {
  locale: string,
  caption: string
}[];

export type TranslationKeys = { [ns: string]: Record<string, string> };

const missingKeysStore = createStore();
const namespacesAtom = atom<TranslationKeys>({});
missingKeysStore.set(namespacesAtom, {});

const languagesAtom = atomWithStorage<null | Locales>('langs', null);

export { languagesAtom, missingKeysStore, namespacesAtom };
