import { useSetAtom, useAtom, useAtomValue } from 'jotai';
import { Link, useParams } from 'react-router-dom';
import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { userClientAtom } from '../../store/auth.ts';
import { clientPreviewIdAtom, customLogoAtom, previewLogoAtom } from '../../store/clientCI.ts';
import { updateCI } from '../../updateCI.ts';
import AdminSidebarHeader from './AdminSidebarHeader/AdminSidebarHeader';
import styles from './AdminSidebar.module.scss';
import AdminSidebarFooter from './AdminSidebarFooter/AdminSidebarFooter.tsx';
import { ClientProps } from './client.props.ts';
import { clientsAtom } from '../../store/clients.ts';

const AdminSidebar = ({
  clients,
  isMobile,
  toggleWithLeftMenu,
  closeMenu,
}: { clients: ClientProps[]; isMobile?: boolean; toggleWithLeftMenu?: () => void, closeMenu?: () => void }) => {
  const [isMenuVisible, setIsMenuVisible] = useState<boolean>(false);
  const { t } = useTranslation();
  const [allClients, setAllClients] = useAtom(clientsAtom);
  const { id: clientId } = useParams();
  const clientPreviewId = useAtomValue(clientPreviewIdAtom);
  const previewLogo = useAtomValue(previewLogoAtom);
  const [customLogo, setCustomLogo] = useAtom(customLogoAtom);
  const setCurrentClient = useSetAtom(userClientAtom);

  useEffect(() => {
    if (!clients.length) return;
    allClients.length === 0 && setAllClients(clients);
  }, [clients]);

  const toggleMobileMenu = (value = !isMenuVisible) => {
    setIsMenuVisible(value);
    toggleWithLeftMenu?.();
  };

  return (
    <aside className={classnames(styles.sidebar, {
      [styles.mobile]: isMobile,
    })}
    >
      <div>
        <AdminSidebarHeader
          setIsMenuVisible={(value) => toggleMobileMenu(value)}
          isMenuVisible={isMenuVisible}
          closeMenu={closeMenu}
        />
        <h6 className={styles.sidebar__subtitle}>{t('Manage clients')}</h6>
        <div className={styles.sidebar__list}>
          {allClients.length && allClients.map(({
            id, name, logo, primary_accent,
          }) => (
            <Link
              key={id}
              to={`/m/client/${id}/users`}
              onClick={() => {
                setCurrentClient({
                  id,
                  name,
                  logo,
                  primary_accent,
                });
                updateCI({
                  id,
                  name,
                  logo,
                  primary_accent,
                }, setCustomLogo);
                closeMenu?.();
              }}
              className={classnames(styles.client, {
                [styles.active]: clientId && id === +clientId,
              })}
            >
              <div className={styles.avatar}>
                {clientPreviewId && previewLogo && id === previewLogo.id ? (
                  <img
                    alt=''
                    src={previewLogo?.logo.preview}
                  />
                ) : customLogo?.logoUrl && id === customLogo.id ? (
                  <img
                    alt=''
                    src={customLogo.logoUrl}
                  />
                ) : logo ? (
                  <img
                    alt=''
                    src={logo}
                  />
                ) : <span>{name[0].toUpperCase()}</span>}
              </div>
              <span className={styles.client__name}>{name}</span>
            </Link>
          ))}
        </div>
      </div>
      <AdminSidebarFooter />
    </aside>
  );
};

export default AdminSidebar;
