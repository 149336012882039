import { useTranslation } from 'react-i18next';

import Modal from '../UIKit/Modal/Modal';
import Button, { ButtonVariants } from '../UIKit/Button/Button';

import DeleteSVG from '../../public/media/delete-user.svg';

import styles from './EntityBaseModal.module.scss';

type EntityBaseModalProps = {
  onClose: () => void;
  onConfirmClick: () => void;
  heading: string;
  confirmButtonText: string;
  children?: React.ReactNode;
  cancelButtonText?: string;
};

const EntityBaseModal = ({
  onClose, onConfirmClick, heading, confirmButtonText, children, cancelButtonText,
}: EntityBaseModalProps) => {
  const { t } = useTranslation();
  const cancelText = cancelButtonText || t('Cancel');
  return (
    <Modal
      closeModal={onClose}
      className={styles.modal}
    >
      <div className={styles.modal__content}>
        <img
          src={DeleteSVG}
          alt=''
        />
        <h4>{heading}</h4>
        {children}
        <div className={styles.modal__btns}>
          <Button
            onClick={onClose}
            variant={ButtonVariants.SECONDARY}
          >
            {cancelText}
          </Button>
          <Button onClick={onConfirmClick}>{confirmButtonText}</Button>
        </div>
      </div>
    </Modal>
  );
};

export default EntityBaseModal;
