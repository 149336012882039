import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import styles from './DeliverableLinks.module.scss';
import DocumentSVG from '../../../../../public/media/summary/quality-gates/document.svg';
import DotsSVG from '../../../../../public/media/dots.svg';
import AttachSVG from '../../../../../public/media/attach.svg';
import { ProjectPermissions } from '../../../Login/user.props.ts';
import Drawer from '../../../../UIKit/Drawer/Drawer.tsx';
import DeliverableLinkForm from '../DeliverableLinkForm/DeliverableLinkForm.tsx';
import apiClient from '../../../../../apiClient.ts';
import { notify } from '../../../../../store/notifications.ts';
import { Deliverable, DeliverableLink } from '../../../NewProject/NewProjectDeliverables/types.ts';

type DeliverableLinksProps = {
  links: DeliverableLink[]
  permissions: string[],
  deliverable: Pick<Deliverable, 'id' | 'caption'>,
  updateLinks: (links: DeliverableLink[]) => void
};

type LinkAction = {
  link: DeliverableLink | null,
  isOpen: boolean
};

const DeliverableLinks = ({
  links, permissions, deliverable, updateLinks,
}: DeliverableLinksProps) => {
  const { t } = useTranslation();
  const { projectId } = useParams();

  const [linkAction, setLinkAction] = useState<LinkAction>({ link: null, isOpen: false });
  const hasUpdatePermission = permissions.includes(ProjectPermissions.UPDATE);

  const createLink = async (values: Record<string, any>) => {
    try {
      const { statusCode, response } = await apiClient.post<{
        data: DeliverableLink
      }>(`projects/${projectId}/deliverables/${deliverable.id}/links`, {
        body: JSON.stringify(values),
      });

      if (statusCode !== 201) throw new Error();
      updateLinks([...links, response.data]);
    } catch (e) {
      console.error(e);
      notify();
    } finally {
      setLinkAction({ link: null, isOpen: false });
    }
  };

  const editLink = async (values: Record<string, any>, id: number) => {
    try {
      const { statusCode, response } = await apiClient.put<{
        data: DeliverableLink
      }>(`projects/${projectId}/deliverables/${deliverable.id}/links/${id}`, {
        body: JSON.stringify(values),
      });

      if (statusCode !== 200) throw new Error();
      updateLinks(links.map(link => {
        if (link.id === response.data.id) {
          return response.data;
        }

        return link;
      }));
    } catch (e) {
      console.error(e);
      notify();
    } finally {
      setLinkAction({ link: null, isOpen: false });
    }
  };

  const deleteLink = async (id: number) => {
    try {
      const { statusCode } = await apiClient.delete(`projects/${projectId}/deliverables/${deliverable.id}/links/${id}`);

      if (statusCode !== 204) throw new Error();
      updateLinks(links.filter(link => link.id !== id));
    } catch (e) {
      console.error(e);
      notify();
    } finally {
      setLinkAction({ link: null, isOpen: false });
    }
  };

  return (
    <>
      <div className={styles.links}>
        {links.length ? links.map(link => (
          <div
            className={styles.links__item}
            key={link.id}
          >
            <Link
              to={link.link}
              target='_blank'
            >
              <svg>
                <use
                  xlinkHref={`${DocumentSVG}#documentSVG`}
                  href={`${DocumentSVG}#documentSVG`}
                />
              </svg>
              <span>{link.caption}</span>
            </Link>
            {hasUpdatePermission && (
              <button
                type='button'
                className={styles.links__item_edit}
                onClick={() => setLinkAction({ link, isOpen: true })}
              >
                <svg>
                  <use
                    xlinkHref={`${DotsSVG}#dotsSVG`}
                    href={`${DotsSVG}#dotsSVG`}
                  />
                </svg>
              </button>
            )}
          </div>
        )) : null}
        {hasUpdatePermission && (
          <button
            type='button'
            className={styles.links__add}
            onClick={() => setLinkAction({ link: null, isOpen: true })}
          >
            <svg>
              <use
                xlinkHref={`${AttachSVG}#attachSVG`}
                href={`${AttachSVG}#attachSVG`}
              />
            </svg>
            {t('Attach new link')}
          </button>
        )}
      </div>
      <Drawer
        isOpen={linkAction.isOpen}
        setIsOpen={() => setLinkAction({ link: null, isOpen: false })}
        title={linkAction.link ? t('Edit attached link') : t('Attach new link')}
        subTitle={linkAction.link ? deliverable.caption : ''}
      >
        <DeliverableLinkForm
          createLink={createLink}
          editLink={editLink}
          deleteLink={deleteLink}
          activeLink={linkAction.link}
        />
      </Drawer>
    </>
  );
};

export default DeliverableLinks;
