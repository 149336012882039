import { useAtom } from 'jotai';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import apiClient from '../../../apiClient.ts';
import { userAtom } from '../../../store/auth.ts';
import { NotificationStatus, notify } from '../../../store/notifications.ts';
import { hasGlobalPermission } from '../../../usePermission.ts';
import { getFormattedServerErrors } from '../../../utils';
import Loader from '../../Loader/Loader.tsx';
import Button, { ButtonVariants } from '../../UIKit/Button/Button.tsx';
import UserForm from '../../UserForm/UserForm.tsx';
import { Permissions } from '../Login/user.props.ts';
import { ClientMemberResource, UserResource } from '../Users/UsersList/types.ts';
import styles from './AccountSettings.module.scss';

type Account = ClientMemberResource;

const AccountSettings = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [userData, setUserData] = useAtom(userAtom);
  const [errors, setErrors] = useState<Record<string, string>>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const isAdmin = hasGlobalPermission(Permissions.ADMIN);

  const { data: user, isLoading, mutate } = useSWR(['user', i18n.language, userData?.user.id], ([url]) => apiClient
    .get<{ data: Account }>(url).then(({ response }) => response.data), {
    keepPreviousData: false,
    revalidateIfStale: false,
    revalidateOnMount: true,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const editProfile = async (body: Record<string, any>) => {
    try {
      setIsSubmitting(true);
      const { statusCode, response } = await apiClient.put<{
        data: UserResource,
        errors?: Record<string, string[]>
      }>('user', {
        body: JSON.stringify(body),
      });

      if (statusCode === 200) {
        setUserData({ user: response.data, token: userData!.token });
        await mutate();
        notify(
          {
            status: NotificationStatus.SUCCESS,
            text: {
              title: t('Success!', { lng: response.data.locale || i18n.language }),
              body: t('Your changes have been saved.', { lng: response.data.locale || i18n.language }),
            },
          },
        );
        return isAdmin && navigate('/');
      } else if (response.errors) {
        setErrors(getFormattedServerErrors(response.errors));
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={styles.wrap}>
      <div className={styles.accountSettings}>
        <div className={styles.accountSettings__header}>
          <h3>{t('Profile information')}</h3>
          {!userData?.user?.passEmpty && (
          <Button
            type='button'
            variant={ButtonVariants.SECONDARY}
            link='/new-password'
            className={styles.accountSettings__newPasswordButton}
          >
            {t('Set new password')}
          </Button>
          )}
        </div>
        {isLoading ? (
          <div className={styles.loader}>
            <Loader size={32} />
          </div>
        ) : (
          <UserForm
            submitButtonText={t('Continue')}
            submitForm={editProfile}
            user={user}
            editProfile
            outerErrors={errors}
            userHasPassword={!userData?.user?.passEmpty}
            isSubmitting={isSubmitting}
            accountSettingsPage
          />
        )}
      </div>
    </div>
  );
};

export default AccountSettings;
