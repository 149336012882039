import { useNavigate } from 'react-router-dom';
import styles from './SubHeader.module.scss';
import Button, { ButtonIconPosition, ButtonVariants } from '../UIKit/Button/Button';
import { ReactComponent as AngleDownSVG } from '../../public/media/angle-down.svg';

const SubHeader = ({ title, navigateTo, children }: { title: string, navigateTo?: string, children?: React.ReactNode, }) => {
  const navigate = useNavigate();

  return (
    <div className={styles.subHeader}>
      <Button
        type='button'
        variant={ButtonVariants.SECONDARY}
        className={styles.subHeader__back}
        onClick={() => !navigateTo && navigate(-1)}
        icon={(
          <AngleDownSVG />
        )}
        iconSize={{ width: 20, height: 20 }}
        link={navigateTo}
        iconPosition={ButtonIconPosition.CENTER}
      />
      <h2 className={styles.subHeader__title}>{title}</h2>
      {children}
    </div>
  );
};

export default SubHeader;
