import React, { useEffect, useState } from 'react';
import useSWR from 'swr';
import dayjs from 'dayjs';
import classNames from 'classnames';
import {
  getGridDateOperators,
  getGridNumericOperators,
  getGridStringOperators,
  GridActionsCellItem,
  GridColDef,
  GridSortItem,
} from '@mui/x-data-grid';
import { useParams, useSearchParams } from 'react-router-dom';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';

import ClippedCell from '../../../UIKit/Table/ClippedCell.tsx';
import EntityBaseModal from '../../../EntityBaseModal/EntityBaseModal.tsx';
import Table from '../../../UIKit/Table/Table';
import TemplateForm from '../TemplateForm/TemplateForm.tsx';
import ListItemTooltip from '../../Customers/CustomersList/ListItemTooltip.tsx';
import DateRangeFilter from '../../../UIKit/Table/Filters/DateRangeFilter.tsx';
import OptionsListFilter from '../../../UIKit/Table/Filters/OptionsListFilter.tsx';
import SearchFilter from '../../../UIKit/Table/Filters/SearchFilter.tsx';

import Drawer from '../../../UIKit/Drawer/Drawer.tsx';
import ErrorSVG from '../../../../public/media/error.svg';
import StatusLabel from '../../../UIKit/StatusLabel/StatusLabel.tsx';
import EditSVG from '../../../../public/media/edit-icon.svg';
import ArchiveSVG from '../../../../public/media/archive-icon.svg';

import apiClient from '../../../../apiClient';
import {
  ExpandedFile, GetTemplatesResponse, InitialTemplateFormValuesType, TemplateResource,
} from '../types';
import { ListResponse } from '../../Users/UsersList/types.ts';
import { tableFiltersOptions, tablesSortConfig } from '../../../../store/table.ts';
import { Status } from '../../../UIKit/StatusLabel/types.ts';

import styles from './TemplatesList.module.scss';
import Loader from '../../../Loader/Loader.tsx';
import ListPlaceholder from '../../../UIKit/ListPlaceholder/ListPlaceholder.tsx';

export enum TemplateStatus {
  Unprocessed = 0,
  Error = 1,
  Active = 3,
  Inactive = 4,
  Processing = 5,
}

const editableStatuses = [TemplateStatus.Active, TemplateStatus.Inactive];

const TEMPLATE_STATUS_FILTER = 'template/status';

const getColumns = (
  t: TFunction<string, undefined>,
  onDeleteClick: (template: { id: number; caption: string }) => void,
  onEditClick: (templateId: number) => void,
  toggleStatus: (templateId: number, row: TemplateResource) => void,
  dateFormat: string,
): GridColDef[] => [
  {
    headerName: t('Template name'),
    field: 'caption',
    flex: 1,
    minWidth: 80,
    sortable: true,
    renderCell: (params) => <ClippedCell value={params.value} />,
    filterOperators: getGridStringOperators().map((operator) => ({
      ...operator,
      InputComponent: SearchFilter,
    })),
  },
  {
    headerName: t('Creation date'),
    field: 'created_at',
    flex: 1,
    minWidth: 80,
    sortable: true,
    renderCell: (params) => <p>{dayjs(params.value).format(dateFormat)}</p>,
    filterOperators: getGridDateOperators().map((operator) => ({
      ...operator,
      InputComponent: DateRangeFilter,
    })),
  },
  {
    headerName: t('Status'),
    field: 'status',
    flex: 1,
    minWidth: 80,
    sortable: true,
    renderCell: (params) => (
      <>
        <StatusLabel
          status={params.row.status}
          className={styles.statusLabel}
          size='large'
        />
        {!!params.row.errors.length && (
        <ListItemTooltip
          format='list'
          hideSpoiler
          options={params.row.errors}
          title={t('Template errors')}
          icon={(
            <svg className={classNames('tableRowActionIcon', styles.errorTooltip__label)}>
              <use
                xlinkHref={`${ErrorSVG}#errorSVG`}
                href={`${ErrorSVG}#errorSVG`}
              />
            </svg>
          )}
        />
        )}
      </>
    ),
    filterOperators: getGridNumericOperators().map((operator) => ({
      ...operator,
      InputComponent: (props) => (
        <OptionsListFilter
          {...props}
          filterName={TEMPLATE_STATUS_FILTER}
        />
      ),
    })),
  },
  {
    headerName: t('Created by'),
    field: 'creator',
    flex: 1,
    minWidth: 80,
    sortable: true,
    filterOperators: getGridStringOperators().map((operator) => ({
      ...operator,
      InputComponent: SearchFilter,
    })),
  },
  {
    headerName: '',
    field: 'actions',
    flex: 0,
    minWidth: 32,
    maxWidth: 32,
    sortable: false,
    type: 'actions',
    getActions: (params) => [
      <GridActionsCellItem
        icon={(
          <svg className='tableRowActionIcon'>
            <use
              xlinkHref={`${EditSVG}#editSVG`}
              href={`${EditSVG}#editSVG`}
            />
          </svg>
        )}
        onClick={() => onEditClick(params.row.id)}
        label={t('Edit')}
        showInMenu
      />,
      // hidded by clietn request
      // <GridActionsCellItem
      //   icon={<>i</>}
      //   onClick={() => onDeleteClick({ id: params.row.id, caption: params.row.caption })}
      //   label={t('Delete')}
      //   showInMenu
      // />,
      ...(editableStatuses.includes(params.row.status.value)
        ? [
          <GridActionsCellItem
            icon={(
              <svg className='tableRowActionIcon'>
                <use
                  xlinkHref={params.row.status.value === TemplateStatus.Active ? `${ArchiveSVG}#archiveSVG` : `${ArchiveSVG}#archiveSVG`}
                  href={params.row.status.value === TemplateStatus.Active ? `${ArchiveSVG}#archiveSVG` : `${ArchiveSVG}#archiveSVG`}
                />
              </svg>
              )}
            onClick={() => toggleStatus(params.row.id, params.row as TemplateResource)}
            label={params.row.status.value === TemplateStatus.Active ? t('Set to inactive') : t('Set to active')}
            showInMenu
          />,
        ]
        : []),
    ],
  },
];

const createRows = (rows: Omit<TemplateResource, 'file'>[]) => rows.map((template) => ({
  id: template.id,
  caption: template.caption,
  created_at: template.created_at,
  status: template.status,
  creator: template.creator,
  errors: template.errors ?? [],
}));

const deleteTemplate = async (clientId: string, templateId: number) => {
  try {
    await apiClient.delete(`clients/${clientId}/templates/${templateId}`);
  } catch (e) {
    console.error(e);
  }
};

const DELETE_TEMPLATE_MODAL_DEFAULT_VALUE = {
  isVisible: false,
  selectedTemplate: null,
};

const EDIT_TEMPLATE_DRAWER_DEFAULT_VALUE = {
  isVisible: false,
  selectedTemplateId: null,
  hasErrors: false,
};

type TemplatesListProps = {
  openCreateTemplateForm: () => void;
  dateFormat: string;
};

const TemplatesList = ({ openCreateTemplateForm, dateFormat }: TemplatesListProps) => {
  const queryParams = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t, i18n } = useTranslation();

  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [deleteTemplateModal, setDeleteTemplateModal] = useState<{
    isVisible: boolean;
    selectedTemplate: { id: number; caption: string } | null;
  }>(DELETE_TEMPLATE_MODAL_DEFAULT_VALUE);
  const [editTemplateDrawer, setEditTemplateDrawer] = useState<{
    isVisible: boolean;
    selectedTemplateId: number | null;
    hasErrors?: boolean;
  }>(EDIT_TEMPLATE_DRAWER_DEFAULT_VALUE);
  const [selectedTemplate, setSelectedTemplate] = useState<(InitialTemplateFormValuesType & { id: number }) | null>(null);

  const [sortSettings, setSortingSettings] = useAtom(tablesSortConfig);

  const [filterSettings, setFiltertSettings] = useState<Record<string, any> | null>(null);
  const [filtersOptions, setFiltersOption] = useAtom(tableFiltersOptions);

  const {
    data, isLoading, isValidating, mutate,
  } = useSWR(
    ['clients/templates', page, queryParams.id, i18n.language, sortSettings, filterSettings],
    async () => {
      const params = new URLSearchParams({
        page: String(page),
        ...(sortSettings?.templates ? { direction: sortSettings?.templates.sort!, order_by: sortSettings?.templates.field } : {}),
        filters: JSON.stringify(filterSettings),
      }).toString();
      const { response } = await apiClient
        .get<ListResponse<Omit<TemplateResource, 'file'>>>(`clients/${queryParams.id}/templates?${params}`);
      setTotalPages(response.meta.last_page);
      response.meta.last_page < page && setPage(response.meta.last_page);
      return createRows(response.data);
    },
    {
      keepPreviousData: true,
      revalidateIfStale: true,
      revalidateOnReconnect: false,
      revalidateOnFocus: false,
    },
  );

  useSWR([`clients/${queryParams.id}/templateStatuses`, i18n.language], async ([url]) => {
    const { response } = await apiClient
      .get<{ data: Status[] }>(url);
    return response?.data;
  }, {
    onSuccess: (statuses) => {
      setFiltersOption({ ...filtersOptions, [TEMPLATE_STATUS_FILTER]: statuses });
    },
  });

  const onDeleteUserClick = (template: { id: number; caption: string }) => {
    setDeleteTemplateModal({ isVisible: true, selectedTemplate: template });
  };

  const removeTemplateHandler = async (clientId: string, templateId: number | undefined) => {
    if (typeof templateId === 'number') {
      await deleteTemplate(clientId, templateId);
      mutate();
      setDeleteTemplateModal(DELETE_TEMPLATE_MODAL_DEFAULT_VALUE);
    }
  };

  const onEditClickHandler = (templateId: number | null) => {
    setEditTemplateDrawer({ isVisible: true, selectedTemplateId: templateId });
  };

  const getTemplateDetails = async (id: number) => {
    try {
      const { statusCode, response } = await apiClient
        .get<GetTemplatesResponse>(`clients/${queryParams.id}/templates/${id}?locale=all`);

      if (statusCode === 200) {
        const formatedResponse: InitialTemplateFormValuesType & { id: number } = {
          id: response.data.id,
          titleEn: response.localized.caption.en,
          titleDe: response.localized.caption.de,
          file: {
            ...response.data.file,
            name: response.data.file.filename,
            created_at: response.data?.created_at,
          } as unknown as ExpandedFile,
        };
        setSelectedTemplate(formatedResponse);
        setEditTemplateDrawer((prev) => ({ ...prev, hasErrors: !!response.data?.errors }));
      }
    } catch (e) {
      console.error(e);
    }
  };

  const toggleStatus = async (templateId: number, template: TemplateResource) => {
    try {
      await apiClient.put(`clients/${queryParams.id}/templates/${templateId}`, {
        body: JSON.stringify({
          status: template.status.value === TemplateStatus.Active ? TemplateStatus.Inactive : TemplateStatus.Active,
        }),
      });
      mutate();
    } catch (error) {
      console.error(error);
    }
  };

  const closeDrawer = () => {
    setSelectedTemplate(null);
    setEditTemplateDrawer(EDIT_TEMPLATE_DRAWER_DEFAULT_VALUE);
    searchParams.delete('active');
    setSearchParams(searchParams, { replace: true });
  };

  useEffect(() => {
    editTemplateDrawer.selectedTemplateId && getTemplateDetails(editTemplateDrawer.selectedTemplateId);
  }, [editTemplateDrawer.selectedTemplateId]);

  useEffect(() => {
    const activeTemplateId = searchParams.get('active');

    if (!activeTemplateId) return;

    setEditTemplateDrawer({ isVisible: true, selectedTemplateId: +activeTemplateId });
  }, [searchParams.get('active')]);

  const onSort = (model: GridSortItem | null) => {
    setSortingSettings({ ...sortSettings, templates: model });
  };

  return (
    <>
      {typeof data === 'undefined' ? (
        <div className={styles.loader}>
          <Loader />
        </div>
      ) : data?.length === 0 && !filterSettings ? (
        <ListPlaceholder
          placeholderText={t('You do not have any templates added yet')}
        />
      ) : (
        <Table
          rows={data}
          isLoading={isLoading || isValidating}
          onPageChange={setPage}
          totalPages={totalPages}
          columns={getColumns(t, onDeleteUserClick, onEditClickHandler, toggleStatus, dateFormat)}
          page={page}
          onSortModelChange={onSort}
          onFilterModelChange={setFiltertSettings}
          initSortModel={sortSettings?.templates}
        />
      )}
      {deleteTemplateModal.isVisible && (
        <EntityBaseModal
          onClose={() => setDeleteTemplateModal(DELETE_TEMPLATE_MODAL_DEFAULT_VALUE)}
          onConfirmClick={() => removeTemplateHandler(queryParams.id!, deleteTemplateModal.selectedTemplate?.id)}
          heading={`${t('Are you sure you want to delete the template')} ${deleteTemplateModal.selectedTemplate?.caption}?`}
          confirmButtonText={t('Yes, delete')}
        />
      )}
      <Drawer
        isOpen={editTemplateDrawer.isVisible && selectedTemplate !== null}
        setIsOpen={closeDrawer}
        title={t('Edit project template')}
      >
        <TemplateForm
          dateFormat={dateFormat}
          isFileEditable={editTemplateDrawer.hasErrors}
          template={selectedTemplate}
          closeDrawer={closeDrawer}
          submitButtonText={t('Save changes')}
          onCreateNewTemplate={() => {
            closeDrawer();
            openCreateTemplateForm();
          }}
        />
      </Drawer>
    </>
  );
};

export default TemplatesList;
