import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import PopoverOptions from '../../../UIKit/PopoverOptions/PopoverOptions';
import { PopoverPlacement } from '../../../UIKit/Popover/Popover';

import GateSVG from '../../../../public/media/summary/quality-gates/gate.svg';
import VoteSVG from '../../../../public/media/summary/quality-gates/vote.svg';
import ConstraintsSVG from '../../../../public/media/summary/quality-gates/constraints.svg';
import HistorySVG from '../../../../public/media/summary/quality-gates/history.svg';
import AngleDownSVG from '../../../../public/media/angle-down.svg';

import { arrayAt } from '../../../../utils/arrayAt';

import styles from './QualityGateMobileTabs.module.scss';

export interface ITab {
  to: string,
  isEnd?: boolean,
  icon: string,
  title: string
}

export const qualityGateTabsList: ITab[] = [
  {
    to: '',
    isEnd: true,
    title: 'Q-Gate Deliverables',
    icon: `${GateSVG}#gateSVG`,
  },
  {
    to: 'voting',
    isEnd: true,
    title: 'Voting',
    icon: `${VoteSVG}#voteSVG`,
  },
  {
    to: 'constraints',
    isEnd: true,
    title: 'Constraints',
    icon: `${ConstraintsSVG}#constraintsSVG`,
  },
  {
    to: 'history',
    isEnd: true,
    title: 'Change history',
    icon: `${HistorySVG}#historySVG`,
  },
];

const QualityGateMobileTabs = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation();
  const location = useLocation();

  const [currentRoute, setCurrentRoute] = useState<string>();

  useEffect(() => {
    setCurrentRoute(arrayAt(location.pathname.split('/'), -1));
  }, [location]);

  const renderOptionTitle = (tab: ITab) => (
    <NavLink
      end
      to={tab.to}
      key={tab.to}
      className={({ isActive }) => classNames(styles.links__item, styles.popoverOption, {
        [styles.active]: isActive,
      })}
    >
      <svg className={styles.popoverOption__icon}>
        <use
          xlinkHref={tab.icon}
          href={tab.icon}
        />
      </svg>
      <span className={styles.tab__title}>{t(tab.title)}</span>
    </NavLink>
  );

  const getPopoverOptions = () => qualityGateTabsList.map((tab, index) => ({
    id: index,
    title: renderOptionTitle(tab),
    handler: () => {},
  }));

  const renderPopoverButton = () => {
    if (!currentRoute) return null;

    const selectedTab = qualityGateTabsList.find(tab => tab.to === currentRoute) ?? qualityGateTabsList.at(0);

    return (
      <div className={styles.popoverButton__content}>
        <svg className={styles.popoverOption__icon}>
          <use
            xlinkHref={selectedTab?.icon}
            href={selectedTab?.icon}
          />
        </svg>
        {t(selectedTab?.title ?? '')}
        <svg className={styles.downloadButton__arrow}>
          <use
            xlinkHref={`${AngleDownSVG}#angleDownSVG`}
            href={`${AngleDownSVG}#angleDownSVG`}
          />
        </svg>
      </div>
    );
  };

  return (
    <div className={styles.tabs}>
      <div className={styles.navigation__mobile}>
        <PopoverOptions
          customButton={renderPopoverButton()}
          options={getPopoverOptions()}
          paperClassName={styles.navigation__mobile__paper}
          placement={PopoverPlacement.CONTEXT_MENU_LEFT}
        />
      </div>
      {children}
    </div>
  );
};

export default QualityGateMobileTabs;
