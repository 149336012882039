import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useEffect } from 'react';
import Input from '../UIKit/Input/Input';
import Button from '../UIKit/Button/Button';
import { ClientProps } from '../AdminSidebar/client.props.ts';
import styles from './ClientForm.module.scss';

type ClientFormProps = {
  client?: ClientProps,
  submitForm: (values: Record<string, any>) => void,
  submitButtonText: string
};

const ClientForm = ({ client, submitForm, submitButtonText }: ClientFormProps) => {
  const { t } = useTranslation();

  const {
    handleSubmit, values, handleChange, handleBlur, touched, errors, setValues,
  } = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().trim().required(t('Name is required')).max(100, t('Must be 100 characters at most')),
    }),
    onSubmit: submitForm,
  });

  useEffect(() => {
    if (!client) return;

    setValues({ name: client?.name ?? '' });
  }, [client]);

  return (
    <form
      className={styles.form}
      onSubmit={handleSubmit}
    >
      <Input
        value={values.name}
        setValue={handleChange}
        onBlur={handleBlur}
        id='name'
        label={t('Enter name')}
        name='name'
        error={!!(touched.name && errors.name)}
        errorMessage={errors.name}
      />
      <div className={styles.form__footer}>
        <Button type='submit'>{submitButtonText}</Button>
      </div>
    </form>
  );
};

export default ClientForm;
