import classNames from 'classnames';
import {
  Column, Sorting, TreeList, TreeListTypes,
} from 'devextreme-react/tree-list';
import { useAtom } from 'jotai';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import apiClient from '../../../../../apiClient.ts';
import { activeTask } from '../manageDeliverables.atom.ts';
import styles from './ManageDeliverablesTreelist.module.scss';

type Task = {
  start_date: Date,
  finish_date: Date,
  id: number,
  name: string,
  parent_id: string,
  task_at_risk: boolean,
  duration: number,
  min_duration: number,
};

const TaskCell = ({ data }: TreeListTypes.ColumnCellTemplateData) => {
  const [currentTask, setCurrentTask] = useAtom(activeTask);

  return (
    <button
      className={classNames(styles.taskButton, {
        [styles.active]: data.data.id === currentTask?.id,
        [styles.disabled]: data.data.has_children || data.data.is_domain,
      })}
      title={data.data.name}
      type='button'
      onClick={() => {
        currentTask?.id !== data.data.id && (!data.data.has_children && !data.data.is_domain)
        && setCurrentTask({ id: data.data.id, name: data.data.name, level: data.data.level });
      }}
      id={data.data.id.toString()}
    >
      <p>
        {data.data.name}
      </p>
    </button>
  );
};

const ManageDeliverablesTreelist = () => {
  const { t, i18n } = useTranslation();
  const { projectId } = useParams();
  const [currentTask, setCurrentTask] = useAtom(activeTask);

  const {
    data: loadedTasks,
  } = useSWR([`projects/${projectId}/tasks?page=all`, i18n.language, 'schedule'], ([url]) => apiClient
    .get<{ data: Task[], max_level: number }>(url).then(({ response }) => response), {
    keepPreviousData: false,
    revalidateOnFocus: false,
  });

  const [tasks, setTasks] = useState<null | Task[]>(null);
  useEffect(() => {
    loadedTasks && setTasks(loadedTasks.data);

    const updatedTask = loadedTasks?.data.find(task => task.id === currentTask?.id);

    loadedTasks && updatedTask && setCurrentTask({
      id: currentTask!.id,
      name: updatedTask.name,
    });
  }, [loadedTasks]);

  const firstRender = useRef<boolean>(true);

  useEffect(() => {
    if (document && loadedTasks && currentTask && firstRender.current) {
      setTimeout(() => {
        document.getElementById(`${currentTask?.id}`)?.scrollIntoView({
          block: 'center',
          behavior: 'auto',
        });
        firstRender.current = false;
      }, 300);
    }
  }, [document, currentTask, loadedTasks]);

  return (
    <TreeList
      dataSource={tasks}
      autoExpandAll
      keyExpr='id'
      parentIdExpr='parent_id'
      scrolling={{
        mode: 'standard',
      }}
      className={styles.treelist}
    >
      <Sorting mode='none' />
      <Column
        dataField='name'
        caption={t('Select task')}
        cellComponent={TaskCell}
      />
    </TreeList>
  );
};

export default ManageDeliverablesTreelist;
